import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { take, tap } from "rxjs/operators";

import { Platform } from "@ionic/angular";
import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";

import { Html5qrcodescannerService } from "./html5qrcodescanner.service";

@Injectable({
  providedIn: "root",
})
export class QrcodeService {
  private web: boolean;

  constructor(
    private barcodeScanner: BarcodeScanner,
    private html5qrcodeScanner: Html5qrcodescannerService,
    platform: Platform
  ) {
    this.web = platform.is("mobileweb") || platform.is("desktop");
  }

  /**
   * Scaneia um qrcode usando uma lib pura em JS ou em um plugin nativo
   * @param options
   */
  scan(
    options: {
      useNativePlugin?: boolean;
      side?: "front" | "back";
      validation?: (v: string) => boolean;
    } = {
      useNativePlugin: !this.web,
      side: "back",
    }
  ) {
    const useNativePlugin =
      options?.useNativePlugin === false || options?.useNativePlugin === true
        ? options.useNativePlugin
        : !this.web;

    if (useNativePlugin) {
      return new Observable<string>((subscriber) => {
        this.barcodeScanner
          .scan({
            disableSuccessBeep: true,
            preferFrontCamera: options?.side == "front",
            showFlipCameraButton: true,
            showTorchButton: true,
            formats: "QR_CODE,PDF_417",
            disableAnimations: true, // iOs
          })
          .then((text) => {
            const validation = options?.validation || ((v: string) => true);

            if (validation(text.text)) {
              subscriber.next(text.text);
              subscriber.complete();
            } else subscriber.error("QR Code incorreto");
          })
          .catch((err) => subscriber.error(err));
      });
    } else {
      const scanOptions = new Object({
        side: options?.side || "back",
        validation: options.validation,
      });
      return this.html5qrcodeScanner.scan(scanOptions).pipe(take(1));
    }
  }
}
