import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { ModalController, AlertController, IonInput } from "@ionic/angular";

import { ItemCart } from "../../interfaces/itemCart.interface";
import { ProductOption } from "../../interfaces/product-option.interface";
import { OrderResponse } from "../../models/order/order-response.model";
import { CartService } from "../../services/cart.service";
import { OrderService } from "../../services/order.service";
import { QrcodeService } from "../../services/qrcode.service";
import { RestaurantService } from "../../services/restaurant.service";
import { StorageService } from "../../services/storage.service";
import { AuthService } from "../../shared/auth.service";
import { Utils } from "../../shared/util";
import { environment } from "../../../environments/environment";
import { UserInformationPage } from "./user-information/user-information.page";
import { ItemPage } from "../item/item.page";
import { OrdersPage } from "../home/orders/orders.page";
import { DialogScannerPageComponent } from "../dialog-scanner-page/dialog-scanner-page.component";

@Component({
  selector: "app-cart-new",
  templateUrl: "./cart-new.page.html",
  styleUrls: ["./cart-new.page.scss"],
})
export class CartNewPage implements OnInit {
  items: ItemCart[] = [];
  // items$: BehaviorSubject<ItemCart[]> = new BehaviorSubject([]) // TODO mudar para observable
  orders$: Observable<OrderResponse[]>;
  comandaId$: Observable<string>;
  @Input("readOnly") readOnly: boolean;
  @Input("order") order: OrderResponse;

  constructor(
    private auth: AuthService,
    public cartService: CartService,
    protected modalController: ModalController,
    private alertController: AlertController,
    private orderService: OrderService,
    private qrCodeScanner: QrcodeService,
    public restaurantService: RestaurantService,
    private router: Router,
    private storage: StorageService,
    public utils: Utils
  ) {}

  ngOnInit() {
    if (!this.readOnly) {
      this.orders$ = this.orderService.getOrders();
      this.comandaId$ = this.orderService.getComandaId();
      this.items = this.cartService.getAllItems();
    } else {
      this.items = this.order.pedidoItens.map(
        (item) =>
          new ItemCart({
            cartItemOpcoes: item.pedidoItemOpcoes.map(
              (option) =>
                new ProductOption({
                  nome: option.nome,
                  produtosOpcaoId: option.pedidoItemOpcaoId,
                  produtosOpcaoTipoId: option.tipo,
                  quantidade: option.quantidade,
                  valor: option.valor,
                })
            ),
            meiomeios: item.pedidoMeioMeios,
            number: item.quantidade,
            produto: item.produto,
            observacao: item.observacao,
            produtoId: item.produtoId,
          })
      );
    }
  }

  /**
   * Fecha o modal atual (caso esteja Page esteja aberta como modal, será ela)
   */
  closeCart() {
    this.modalController.dismiss(null, null, environment.modals.cartNewId);
  }

  /**
   * Mostra os detalhes do item
   * @param item
   */
  showDetails(item: ItemCart) {
    this.modalController
      .create({
        component: ItemPage,
        componentProps: {
          itemCart: item,
          openCartFn: this.closeCart,
        },
        cssClass: "modal-fullscreen",
      })
      .then((modal) => modal.present());
  }

  /**
   * Retira o item do carrinho
   * @param item
   */
  throwFromCart(item: ItemCart) {
    this.cartService.removeItem(item.id);
    this.items = this.cartService.getAllItems();
  }

  /**
   * Chama um alert para que o pedido seja enviado via api ou whatsapp
   */
  sendOrder() {
    const mode = this.storage.get(environment.storage.appMode);
    // Caso seja pelo whats
    if (mode == environment.appModes.zap) {
      this.modalController
        .create({
          component: UserInformationPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) =>
          modal.present().then(() =>
            modal.onDidDismiss().then((result) => {
              if (result.data.success) {
                this.closeCart();
              }
            })
          )
        );
      // Caso seja pela API
    } else if (mode == environment.appModes.table) {
      this.doOrder();
    } else if (mode == environment.appModes.onlinePayment) {
      this.modalController
        .create({
          component: UserInformationPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) =>
          modal.present().then(() =>
            modal.onDidDismiss().then((result) => {
              if (result.data.success) {
                this.closeCart();
              }
            })
          )
        );
    } else if (mode == environment.appModes.comanda) {
      this.alertController
        .create({
          header: "Qual a mesa?",
          inputs: [
            {
              id: environment.table_number_input_id,
              cssClass: "center-align",
            },
          ],
          buttons: [
            {
              text: "Confirmar",
              handler: () => this.confirmTableAndSendOrder(),
            },
          ],
        })
        .then((alert) => alert.present());
    }
  }

  /**
   * Pede a identificação da mesa pro usuário e finaliza o pedido
   */
  confirmTableAndSendOrder() {
    const tableInput = document.getElementById(
      environment.table_number_input_id
    ) as any as IonInput;
    if (tableInput.value) {
      this.modalController
        .create({
          component: DialogScannerPageComponent,
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            if (dismiss.data.confirm) {
              const subScan: Subscription = this.qrCodeScanner
                .scan({
                  useNativePlugin: false,
                  validation: (text: string) => text.length == 36,
                })
                .subscribe({
                  next: (comandaId) =>
                    comandaId?.length !== 36 ||
                    this.doOrder(comandaId, tableInput.value as string),
                  complete: () => subScan.unsubscribe(),
                });
            }
          });
          modal.present();
        });
    } else {
      this.alertController
        .create({
          message: "Digite em qual mesa está alocado",
        })
        .then((alert) => alert.present());
    }
  }

  /**
   * Retira todos os itens do carrinho
   */
  cleanOrder() {
    this.cartService.clear();
    this.closeCart();
  }

  /**
   * Realiza o pedido
   * @param comandaId
   */
  doOrder(comandaId?: string, tableNumber?: string) {
    this.utils.setStateLoading(true); // Carregando
    const mode = this.storage.get<string>(environment.storage.appMode);
    this.cartService.sendOrder({ comandaId, tableNumber }).subscribe({
      next: (r) => {
        this.utils.setStateLoading(false);
        if (r) {
          this.alertController
            .create({
              header: "Prontinho!",
              message: "Pedido encaminhado! Agora é só aguardar",
              buttons: [
                { text: "OK", handler: () => this.modalController.dismiss() },
              ],
            })
            .then((alert) => alert.present());
        } else {
          if (
            mode == environment.appModes.table ||
            mode == environment.appModes.comanda
          ) {
            this.alertController
              .create({
                header: "Prontinho!",
                message: "Pedido encaminhado! Agora é só aguardar",
                buttons: [
                  { text: "OK", handler: () => this.modalController.dismiss() },
                ],
              })
              .then((alert) => alert.present());
          } else {
            this.alertController
              .create({
                header: "Ops!",
                message: "Algum erro ocorreu",
                buttons: [
                  {
                    text: "Fechar",
                    handler: () => this.modalController.dismiss(),
                  },
                ],
              })
              .then((alert) => alert.present());
          }
        }
        // TODO fazer o bagulho de pedidos da comanda
      },
      error: (err) => {
        this.alertController
          .create({
            header: "Ops!",
            message: err?.error || err?.mesage || err,
            buttons: [{ text: "Fechar" }],
          })
          .then((alert) => alert.present());
        this.utils.setStateLoading(false);
      },
    });
  }

  /**
   * Abre os pedidos realizados
   */
  callOpenOrdersPage() {
    this.modalController
      .create({
        component: OrdersPage,
      })
      .then((modal) => modal.present());
  }
}
