import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { UserGestor } from "../models/user-gestor.model";
import { RestaurantService } from "./restaurant.service";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

export const httpOptions: { headers: HttpHeaders } = {
  headers: new HttpHeaders({
    "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    Accept: "application/json, text/plain",
    "cache-control": "no-cache",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT",
  }),
};

@Injectable({
  providedIn: "root",
})
export class UsergestorService {
  private user$: BehaviorSubject<UserGestor> = new BehaviorSubject<UserGestor>(null);

  constructor(private http: HttpClient, private restaurantService: RestaurantService) {}

  setUser(data: UserGestor): void {
    this.user$.next(data);
  }

  getUser(): UserGestor {
    return this.user$.getValue();
  }

  registerUser(user: UserGestor): Observable<any> {
    user.restauranteid = this.restaurantService.getRestaurant().restauranteId;
    return this.http.post<{ errors: boolean; message: string }>(`${environment.SERVERHOST}/login/zimmergestorregistro`, user).pipe(
      tap(
        (data) => {
          if (!data?.errors) {
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  authenticateUser(user: UserGestor): Observable<any> {
    user.restauranteid = this.restaurantService.getRestaurant().restauranteId;
    return this.http.post<{ errors: boolean; message: string }>(`${environment.SERVERHOST}/login/zimmergestorlogin`, user).pipe(
      tap(
        (data) => {
          if (!data?.errors) {
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  modifyUser(user: UserGestor): Observable<any> {
    user.restauranteid = this.restaurantService.getRestaurant().restauranteId;
    user.userid = this.getUser().userid;
    return this.http.post<{ errors: boolean; message: string }>(`${environment.SERVERHOST}/login/zimmergestoreditar`, user).pipe(
      tap(
        (data) => {
          if (!data?.errors) {
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  modifyPassword(user: UserGestor): Observable<any> {
    user.restauranteid = this.restaurantService.getRestaurant().restauranteId;
    return this.http.get<{ errors: boolean; message: string }>(`${environment.SERVERHOST}/login/recuperarsenha/${user.fone}/${user.email}/${user.restauranteid}`).pipe(
      tap(
        (data) => {
          if (!data?.errors) {
          }
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getAddressDataUsingCEP(cep: string): Observable<any> {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`);
  }
}
