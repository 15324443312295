import { UserInfoRequestGestorPage } from "./../v2/finishing-pages/user-info-request-gestor/user-info-request-gestor.page";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { of, Observable, Subject, forkJoin, throwError, from, BehaviorSubject } from "rxjs";
import { tap, map, mergeAll, mergeMap, take } from "rxjs/operators";

import { AlertController, IonInput, iosTransitionAnimation, ModalController, Platform } from "@ionic/angular";

import { AuthService } from "../shared/auth.service";
import { Card } from "../models/payment/card.model";
import { Cart } from "../models/cart/cart.model";
import { environment } from "../../environments/environment";
import { ItemCart } from "../interfaces/itemCart.interface";
import { Payment } from "../models/payment/payment.model";
import { PaymentService, PaymentResponse, ConsumoResponse } from "./payment.service";
import { ResponseApi } from "../shared/base.service";
import { ResponsePayment } from "../interfaces/response-payment.interface";
import { RestaurantService } from "./restaurant.service";
import { SendOrderApi } from "../interfaces/order.interface";
import { StorageService } from "./storage.service";
import { User } from "../models/user.model";
import { Utils } from "../shared/util";
import { WhatsMsgService } from "../shared/whats-msg.service";
import { Localizacao } from "../models/address/localizacao.model";
import { UserZimmer } from "../models/user-zimmer.model";
import { Order } from "../models/order/order.model";
import { CartNewPage } from "../pages/cart-new/cart-new.page";
import { OrderService } from "./order.service";
import { Restaurant } from "../models/restaurant.model";
import { UserInfoRequestZapPage } from "../v2/finishing-pages/user-info-request-zap/user-info-request-zap.page";
import { UserInfoRequestCardPage } from "../v2/finishing-pages/user-info-request-card/user-info-request-card.page";
import { ComandaScanPage } from "../v2/finishing-pages/comanda-scan/comanda-scan.page";
import { UserInfoDrivePage } from "../v2/finishing-pages/user-info-drive/user-info-drive.page";
import { TablePagePage } from "../v2/finishing-pages/table-page/table-page.page";
import { UserInfoRequestConsumoPage } from "../v2/finishing-pages/user-info-request-consumo/user-info-request-consumo.page";
import { cardConsumo } from "../models/payment/consumo.model";
import { ToastaddService } from "./toastadd.service";
import { Key } from "protractor";
import { OrderGestor } from "../models/order/order-gestor";

interface ViaCEPResponse {
  cep: string;
}

@Injectable({
  providedIn: "root",
})
export class CartService {
  /**
   * Pedido atual
   */
  private cart: Cart = new Cart();
  /**
   * Pedidos realizados
   */
  private orders: Cart[] = [];
  /**
   * Ficha após pedido realizado pela rota 'pedido'
   */

  ficha$: BehaviorSubject<string | number> = new BehaviorSubject<string | number>(this.storage.getSessionData(environment.storage.ficha));

  constructor(private alertCtrl: AlertController, private auth: AuthService, private http: HttpClient, private modalCtrl: ModalController, private orderService: OrderService, private paymentService: PaymentService, private platform: Platform, private restaurantService: RestaurantService, private storage: StorageService, public utils: Utils, private whatsMsgBuilder: WhatsMsgService, private alerta: ToastaddService) {
    this.ficha$.subscribe({
      next: (ficha) => this.storage.setSessionData(environment.storage.ficha, ficha),
    });
  }

  /**
   * Adiciona um item ao carrinho
   * @param { ItemCart } item Item a ser adicionado
   */
  setItem(item: ItemCart): void {
    const itemExists = this.cart.items.find((i) => i.id == item.id);

    itemExists ? itemExists.number++ : this.cart.items.push(item);
  }

  /**
   * Retorna um item do carrinho
   * @param { number } itemId ID do item
   */
  getItem(itemId: number): ItemCart {
    const item = this.cart.items.find((i) => i.id == itemId);
    return item;
  }

  /**
   * Retorna a quantidade do produto em todos os itens do carrinho somados, pelo ID
   * @param { number } prodId ID do produto a ser pesquisado
   */
  getNumberOfProdInOrder(prodId: number): number {
    let number: number = 0;
    this.cart.items.forEach((item) => (number += item.produto.produtoId == prodId ? item.number : 0));
    return number;
  }

  /**
   * Remove um item do carrinho pelo ID
   * @param { number } itemId ID do item do carrinho
   */
  removeItem(itemId: number): void {
    this.cart.items = this.cart.items.filter((i) => i.id != itemId);
  }

  /**
   * Remove um da quantidade do produto do item do carrinho
   */
  removeProd(itemId: number): void {
    const item = this.cart.items.find((i) => i.id == itemId);
    if (item.cartItemOpcoes.length > 0) {
      item.cartItemOpcoes.forEach(function (i) {
        i.quantidade -= i.quantidade / item.number;
        i.quantidade = Math.floor(i.quantidade);
      });
    }
    item.number--;
    if (item.number <= 0) {
      this.cart.items = this.cart.items.filter((i) => i.id != itemId);
    }
  }

  /**
   * Adciona mais um do mesmo produto no item do carrinho
   * @param { number } itemId ID do item no carrinho
   */
  addProductToItem(itemId: number): void {
    const item = this.cart.items.find((i) => i.id == itemId);
    if (item.cartItemOpcoes.length > 0) {
      item.cartItemOpcoes.forEach(function (i) {
        i.quantidade += i.quantidade / item.number;
        i.quantidade = Math.ceil(i.quantidade);
      });
    }
    item.number++;
    console.log(this.cart);
  }

  /**
   * Edita um item no carrinho
   * @param { ItemCart } item Item com os novos valores
   */
  editItem(item: ItemCart): void {
    const i = this.cart.items.find((i) => i.id == item.id);
    Object.keys(i).forEach((key) => (i[key] = item[key]));
    i.observacao = item.observacao;
    console.log("antes de editar:", item);
  }

  /**
   * Retorna todos os itens do carrinho
   */
  getAllItems(): ItemCart[] {
    return this.cart.items;
  }

  /**
   * Retorna a quantidade total de items no carrinho
   */
  getTotalItems(): number {
    let totalItems: number = 0;
    this.cart.items.forEach((i) => (totalItems += i.number));
    return totalItems;
  }

  /**
   * Pega o valor total do carrinho
   * @param { { date, discount: number, items: ItemCart[] } } order Pedido a ser calculado o valor
   */
  getTotalValue(order?: { date; discount: number; items: ItemCart[] }): number {
    let totalValue: number = 0;
    if (!order) {
      this.cart.items.forEach((item) => (totalValue += this.calcTotalItemValue(item)));
    } else {
      order.items.forEach((item) => (totalValue += this.calcTotalItemValue(item)));
    }

    return Number(totalValue.toFixed(2));
  }

  /**
   * Pega o valor total do produto em todos os itens dentro do carrinho (sem os complementos)
   * @param { number } prodId ID do produto
   */
  getTotalValueOnlyMain(prodId: number): number {
    let totalValue: number = 0;
    this.cart.items.forEach((item) => {
      item.produto.produtoId == prodId ? (totalValue += (item.produto.valorPromocao || item.produto.valorVenda || item.produto.valorRegular || 0) * item.number) : 0;
    });
    return totalValue;
  }

  /**
   * Pega o valor total dos complementos de todos os produtos de um item
   * @param { number } itemId ID do item no carrinho
   * @deprecated
   */
  getTotalValueOnlyComplements(itemId: number) {
    let totalValue: number = 0;
    throw "Under maintenance";
    return totalValue;
  }

  /**
   * Calcula o valor total de um item passado
   * @param { ItemCart } item Item a ser calculado o valor total
   * @deprecated
   */
  getTotalValueByItem(item: ItemCart): number {
    let totalValue: number = 0;
    item.cartItemOpcoes.forEach((option) => {
      totalValue += option.valor;
    });
    totalValue += item.produto.valorVenda;
    totalValue *= item.number;
    return Number(totalValue.toFixed(2));
  }

  /**
   * Obtém o endereço com base no CEP
   */
  getAddressDataUsingCEP(cep: string): Observable<ViaCEPResponse> {
    return this.http.get<ViaCEPResponse>(`https://viacep.com.br/ws/${cep}/json/`);
  }

  /**
   * Envia o pedido no Gestor
   */

  sendOrderGestor(
    form: any,
    entrega: boolean = false,
    options?: {
      nsu?: string | number;
      userZimmer?: UserZimmer;
      comandaId?: string;
      tableNumber?: string;
      phone?: string;
    }
  ): Observable<boolean | string> {
    const success$: Subject<boolean | string> = new Subject<boolean | string>();

    console.log(form, "form");

    // Verificar se o restaurante está aberto
    this.restaurantService.isOpen().subscribe({
      next: async (isOpen) => {
        if (isOpen) {
          /*
           * Quando usa-se var1 = var2, apenas é passado a referência do objeto na memória.
           * Qualquer alteração feita afeta o outro objeto. Por isso a necessidade de instanciar um objeto novo ou passar os valores por meio de for's
           */
          const mode = this.storage.get<string>(environment.storage.appMode);
          // Cria uma nova instância do carrinho
          const cart = new Cart(this.cart);
          cart.date = new Date();

          //  API
          // Prepara o JSON para a API do Xandão
          const orderObj = new Object() as SendOrderApi;
          orderObj.adress = form.endereco;
          orderObj.cartTotal = {
            discount_total: 0,
            shipping_total: this.getTotalValue(),
            subtotal: this.getTotalValue(),
            taxTotal: form.frete,
            total: this.getTotalValue(),
            total_tax: 0,
          };

          orderObj.cartItens =
            cart.items.map((item) => {
              let maxHalf: any = {};

              if (item.meiomeios.length > 0) {
                maxHalf = item.meiomeios.reduce(function (prev, current) {
                  return prev.valorVenda > current.valorVenda ? prev : current;
                });
              }

              return {
                cartItemOpcoes: item.cartItemOpcoes,
                cartMeioMeios: item.meiomeios,
                observacao: item.observacao,
                produto: item.produto,
                quantidade: item.number,
                souvinir: [],
                valorentrega: 0,
                valorprodutos: item.produto.valorPromocao || item.produto.valorVenda || item.produto.valorRegular || maxHalf?.valorRegular || 0,
                valortotal: this.calcTotalItemValue(item),
              };
            }) || [];
          orderObj.usuarioGuid = environment.USER_GUID;
          orderObj.isEntrega = entrega;
          orderObj.restaurante = this.restaurantService.getRestaurant();
          orderObj.troco = form.troco;
          orderObj.isPagamentoOnline = !!options?.nsu;
          orderObj.referencia = options?.tableNumber || this.auth.getTable() + "";
          orderObj.comandaId = options?.comandaId;
          orderObj.paymentNsu = options?.nsu;
          orderObj.userZimmer = options?.userZimmer;
          if (mode == environment.appModes.table) orderObj.orderType = "TABLE";
          else if (mode == environment.appModes.onlinePayment || mode == environment.appModes.comanda) orderObj.orderType = null;

          orderObj.formaPagamento =
            form.pagamento === 1 && form.troco === 0
              ? "Dinheiro"
              : form.pagamento === 1 && form.troco !== 0
              ? "Dinheiro troco para " +
                form.troco.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              : "Cartão (Máquininha)";

          orderObj.troco = form.troco;

          console.log(orderObj, "OBJETO PEDIDO GESTOR");

          let userDados: OrderGestor = {
            nome: form.userName,
            fone: form.userFone,
            logradouro: orderObj.adress.logradouro,
            numero: orderObj.adress.numero,
            uf: orderObj.adress.uf,
            bairro: orderObj.adress.bairro,
            cep: orderObj.adress.cep,
            localidade: orderObj.adress.localidade,
            complemento: orderObj.adress.complemento,
            restauranteId: this.restaurantService.getRestaurant().restauranteId,
          };
          environment.pedidoURL += `${this.restaurantService.getRestaurant().restauranteId}/`

          await this.http.post<OrderGestor>(`${environment.SERVERHOST}/Users/cadastrarPorTelefone/`, userDados).subscribe({
            next: (res) => {
              if (res) {
                this.getPhoneDataGestor(userDados.fone).subscribe(
                  (dadosUsuario: any) => {
                    environment.pedidoURL += `${dadosUsuario.userID}`
                    orderObj.usuarioGuid = dadosUsuario.userID;

                    this.http.post<Order>(`${environment.SERVERHOST}/carrinho`, orderObj).subscribe({
                      next: (res) => {
                        cart.orderObj = orderObj;
                        const totenId = res.totenId;

                        // Manda para pedidos os pedidos realizados
                        this.orders.unshift(cart);
                        // this.storage.save('orders', this.orders)
                        this.cart.items = [];
                        this.storage.setSessionData("senha", totenId);
                        success$.next(totenId || "");
                        this.orderService.setComandaId(orderObj.comandaId);
                      },
                      error: (error) => {
                        console.log("error res", error);
                        success$.next(false);
                      },
                    });
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              }
            },
            error: (error) => {
              console.log(error);
            },
          });
        } else success$.error("O estabelecimento não está atendendo no momento");
      },
    });

    return success$.asObservable();
  }

  /**
   * Obtém os dados do cliente pelo telefone
   */
  getPhoneDataGestor(phone?: string): Observable<boolean | string> {
    const success$: Subject<boolean | string> = new Subject<boolean | string>();

    // Verificar se o restaurante está aberto
    this.restaurantService.isOpen().subscribe({
      next: (isOpen) => {
        if (isOpen) {
          if (phone) {
            this.http.get<OrderGestor>(`${environment.SERVERHOST}/Users/obterPorTelefone/` + phone + "/" + this.restaurantService.getRestaurant().restauranteId).subscribe({
              next: (res: any) => {
                success$.next(res);
              },
              error: (error) => {
                success$.error("Ocorreu um erro ao obter dados telefonicos");
              },
            });
          }
        } else success$.error("O estabelecimento não está atendendo no momento");
      },
    });

    return success$.asObservable();
  }

  /**
   * Salva os dados do cliente
   */
  setPhoneDataGestor(phone?: string, userData?: OrderGestor): Observable<boolean | string> {
    const success$: Subject<boolean | string> = new Subject<boolean | string>();

    // Verificar se o restaurante está aberto
    this.restaurantService.isOpen().subscribe({
      next: (isOpen) => {
        if (isOpen) {
          if (phone) {
            this.http.post<OrderGestor>(`${environment.SERVERHOST}/Users/cadastrarPorTelefone/` + phone, userData).subscribe({
              next: (res: any) => {
                success$.next(res);
              },
              error: (error) => {
                success$.error("Ocorreu um erro ao salvar dados");
              },
            });
          }
        } else success$.error("O estabelecimento não está atendendo no momento");
      },
    });

    return success$.asObservable();
  }

  /**
   * Envia o pedido via whatsapp
   * @param { number } number Número de telefone do usuário final
   * @param { string } msg Mensagem que deve ser enviada
   */
  sendOrderWhats(
    options: {
      aquisition: number;
      endereco: Localizacao;
      number: number;
      pagamento: number;
      troco: number;
      userFone: number;
      userName: string;
      observation: string;
      tableNumber?: number | string;
      // paymentMethod: string
    },
    msg?: string
  ): boolean {
    try {
      // Se nenhuma mensagem foi passada então ele monta o pedido
      if (!msg) {
        msg = this.whatsMsgBuilder.buildMsg({
          aquisition: options.aquisition ? (options.aquisition == 1 ? "Retirada" : "Entrega") : "",
          endereco: options.endereco,
          items: this.getAllItems(),
          mode: this.storage.get("type") == "zap" ? "user" : "table",
          pagamento: options.pagamento,
          restaurantName: this.restaurantService.getRestaurant().nome,
          troco: options.troco,
          userFone: options.userFone,
          userName: options.userName,
          pedidoTotal: this.getTotalValue(),
          observation: options.observation,
          totalValue: this.getTotalValue(),
          tableNumber: options.tableNumber,
          // deliveryFee: this.restaurantService.getRestaurant().
        });
      }
      // Manda pra api do whatsapp
      const url: string = `https://api.whatsapp.com/send?phone=55${options.number || this.restaurantService.getRestaurant()?.fonecomercial || this.storage.get("phoneNumber")}&text=${msg}`;
      window.open(url);

      this.cart.items = [];
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  }

  /**
   * Prepara o objeto e chama a função de pagamento do paymentService
   * @param { Card } card
   * @param { User } user
   */
  doOrderWithOnlinePayment(
    card: Card,
    extra
  ): Observable<{
    success: boolean;
    message?: string;
    pedido?: string | boolean;
  }> {
    const restaurante = this.restaurantService.getRestaurant();
    const payment: Payment = {
      restauranteId: restaurante.restauranteId,
      user: new User(),
      method: "CREDIT_CARD",
      total: this.getTotalValue(),
      entrega: 0,
      titular: card.titular,
      cpf: card.cpf,
      cartao: card,
    };
    // console.log(JSON.stringify(payment))
    // return
    const payment$ = this.paymentService.pay(payment).pipe(
      map((response: ResponsePayment) => {
        if (response.errors) {
          return of({ success: false, message: response.message });
        } else {
          return this.sendOrder({
            nsu: Number(response.nsu),
            userZimmer: extra,
          }).pipe(
            map((result) => ({
              success: typeof result === "string",
              pedido: result,
            }))
          );
        }
      })
    );

    return payment$.pipe(mergeAll(2));
  }

  /**
   * Cria uma instância da classe que deve ser enviada para a API para salvar o pedido no banco
   */
  sendOrder(options?: { nsu?: string | number; userZimmer?: UserZimmer; comandaId?: string; tableNumber?: string }): Observable<boolean | string> {
    const success$: Subject<boolean | string> = new Subject<boolean | string>();

    // Verificar se o restaurante está aberto
    this.restaurantService.isOpen().subscribe({
      next: (isOpen) => {
        if (isOpen) {
          /*
           * Quando usa-se var1 = var2, apenas é passado a referência do objeto na memória.
           * Qualquer alteração feita afeta o outro objeto. Por isso a necessidade de instanciar um objeto novo ou passar os valores por meio de for's
           */
          const mode = this.storage.get<string>(environment.storage.appMode);
          // Cria uma nova instância do carrinho
          const cart = new Cart(this.cart);
          cart.date = new Date();

          //  API
          // Prepara o JSON para a API do Xandão
          const orderObj = new Object() as SendOrderApi;
          orderObj.cartTotal = {
            discount_total: 0,
            shipping_total: this.getTotalValue(),
            subtotal: this.getTotalValue(),
            taxTotal: 0,
            total: this.getTotalValue(),
            total_tax: 0,
          };
          orderObj.cartItens =
            cart.items.map((item) => ({
              cartItemOpcoes: item.cartItemOpcoes,
              cartMeioMeios: item.meiomeios,
              observacao: item.observacao,
              produto: item.produto,
              quantidade: item.number,
              souvinir: [],
              valorentrega: 0,
              valorprodutos: item.produto.valorPromocao || item.produto.valorVenda || item.produto.valorRegular || 0,
              valortotal: this.calcTotalItemValue(item),
            })) || [];
          orderObj.usuarioGuid = environment.USER_GUID;
          orderObj.isEntrega = false;
          orderObj.restaurante = this.restaurantService.getRestaurant();
          orderObj.troco = 0;
          orderObj.isPagamentoOnline = !!options?.nsu;
          orderObj.referencia = options?.tableNumber || this.auth.getTable() + "";
          orderObj.comandaId = options?.comandaId;
          orderObj.paymentNsu = options?.nsu;
          orderObj.userZimmer = options?.userZimmer;
          if (mode == environment.appModes.table) orderObj.orderType = "TABLE";
          else if (mode == environment.appModes.onlinePayment || mode == environment.appModes.comanda) orderObj.orderType = "TOTEN";

          console.log(orderObj, "OBJETO PEDIDO ZIMMER");
          this.http.post<Order>(`${environment.SERVERHOST}/carrinho`, orderObj).subscribe({
            next: (res) => {
              cart.orderObj = orderObj;
              const totenId = res.totenId;

              // Manda para pedidos os pedidos realizados
              this.orders.unshift(cart);
              // this.storage.save('orders', this.orders)
              this.cart.items = [];
              this.storage.setSessionData("senha", totenId);
              success$.next(totenId || "");
              this.orderService.setComandaId(orderObj.comandaId);
            },
            error: (error) => {
              console.log("error res", error);
              success$.next(false);
            },
          });
        } else success$.error("O estabelecimento não está atendendo no momento");
      },
    });

    return success$.asObservable();
  }

  /**
   * Envio de pedido pra versão V2
   */
  sendOrderV2(): Observable<boolean | string> {
    return this.restaurantService.isOpen().pipe(
      // Pegando informações: restaurante, número da mesa e modo de operação
      mergeMap((isOpen) =>
        forkJoin({
          // Se o restaurante não estiver ativo, ele retornará nulo nesse campo
          rest: (isOpen ? this.restaurantService.getRest() : of<Restaurant>(null)).pipe(take(1)),
          table: this.auth.getTableV2().pipe(take(1)),
          mode: this.storage.getV2<string>(environment.storage.v2.mode).pipe(take(1)),
        }).pipe(take(1))
      ),
      mergeMap((data) => {
        // Caso o restaurante esteja nulo, ele não está ativo, logo, não deverá ser feito o pedido
        if (!data?.rest) return throwError("Restaurante não está operando");

        // Operações conforme o modo de operação
        let order: SendOrderApi;
        switch (data.mode) {
          case environment.appModes.table:
            order = this.prepareOrderObj({
              mode: data.mode,
              rest: data.rest,
              table: data.table,
            });
            return this.makeOrder(order).pipe(
              map((res) => {
                this.cart.items = [];
                return !!res;
              })
            );

          case environment.appModes.zap:
            return this.askZapInformation().pipe(
              tap((success) => {
                if (success) this.cart.items = [];
              })
            );

          case environment.appModes.gestor:
            return this.askGestorInformation().pipe(
              tap((success) => {
                if (success) this.cart.items = [];
              })
            );
          // consumo ///////////////////////////////////////////////////////////////////////////////////////////////
          case environment.appModes.consumo:
            return this.askPaymentInformationConsumo().pipe(
              mergeMap((extra) =>
                forkJoin({
                  extra: of(extra),
                  paymentResult: extra.method == 1 ? this.makePayment(extra.card) : this.makeConsumo(extra.cardConsumo),
                })
              ),
              mergeMap((result) => {
                order = this.prepareOrderObj({
                  mode: data.mode,
                  rest: data.rest,
                  nsu: result.paymentResult?.nsu,
                  user: {
                    nome: result.extra.userName,
                    fone: result.extra.phone,
                    cpf: result.extra.cpf,
                  },
                  payment: result.paymentResult?.nsu ? "Crédito" : "Consumo",
                });

                return this.makeOrder(order).pipe(
                  map((response) => {
                    this.cart.items = [];
                    this.ficha$.next(response.totenId);
                    return response.totenId;
                  })
                );
              })
            );
          // pgto online ///////////////////////////////////////////////////////////////////////////////////////////////
          case environment.appModes.onlinePayment:
            return this.askPaymentInformation().pipe(
              mergeMap((extra) =>
                forkJoin({
                  extra: of(extra),
                  paymentResult: extra.method == 1 ? this.makePayment(extra.card) : of<PaymentResponse>(null).pipe(take(1)),
                })
              ),
              mergeMap((result) => {
                console.log(result, "result pgtoonline");

                order = this.prepareOrderObj({
                  mode: data.mode,
                  rest: data.rest,
                  nsu: result.paymentResult?.nsu,
                  user: {
                    nome: result.extra.userName,
                    fone: result.extra.phone,
                    cpf: result.extra.cpf,
                  },
                  payment: result.paymentResult?.nsu ? "Crédito" : "Débito ou dinheiro",
                });
                console.log(order, "order pgtoonline");

                return this.makeOrder(order).pipe(
                  map((response) => {
                    console.log(response, "response pgotlinoie");

                    this.cart.items = [];
                    this.ficha$.next(response.totenId);
                    return response.totenId;
                  })
                );
              })
            );

          case environment.appModes.comanda:
            return forkJoin({
              comandaId: this.askComanda(),
              tableNumber: this.askTableNumber1(),
            }).pipe(
              mergeMap((forkData) => {
                order = this.prepareOrderObj({
                  comandaId: forkData.comandaId,
                  mode: data.mode,
                  rest: data.rest,
                  table: forkData.tableNumber as string,
                });
                return this.makeOrder(order);
              }),
              map((res) => {
                this.cart.items = [];
                return !!res;
              })
            );

          case environment.appModes.drive:
            return this.askDriveThruInfo().pipe(
              mergeMap((extra) =>
                forkJoin({
                  driveInfo: of(extra),
                  paymentResult: extra.method == 1 ? this.makePayment(extra.card) : of<PaymentResponse>(null).pipe(take(1)),
                })
              ),
              mergeMap((result) => {
                order = this.prepareOrderObj({
                  mode: data.mode,
                  rest: data.rest,
                  nsu: result.paymentResult?.nsu,
                  table: result.driveInfo.licensePlate,
                  user: {
                    nome: result.driveInfo.userName,
                    fone: result.driveInfo.phone,
                    cpf: result.driveInfo.cpf,
                  },
                  payment: result.paymentResult?.nsu ? "Crédito" : "Débito ou dinheiro",
                });
                return this.makeOrder(order).pipe(
                  map((response) => {
                    this.cart.items = [];
                    return !!response;
                  })
                );
              })
            );

          case environment.appModes.menu:
            return throwError("Rota não permitida para finalização de pedido");

          default:
            return throwError("Finalização de pedido não encontrada");
        }
      })
    );
  }

  /**
   * Verifica se o restaurante monitora as fichas
   */
  public testMonitorID(): Observable<any> {
    let restId: number;

    this.restaurantService.getRest().subscribe((rest) => {
      restId = rest.restauranteId;
    });

    return this.http.get<any>(`${environment.SERVERHOST}/restaurantemonitora/obterVerificaComandaPorId/` + restId);
  }

  /**
   * Testa o código da comanda
   */
  public testCardID(card: number): Observable<any> {
    return this.http.get<any>(`${environment.SERVERHOST}/comanda/obtercomandaporguid/` + card);
  }

  /**
   * Envia o pedido para a api
   */
  private makeOrder(order: SendOrderApi): Observable<Order> {
    return this.http.post<Order>(`${environment.SERVERHOST}/carrinho`, order);
  }

  /**
   * Faz o pagamento de um pedido
   */
  private makePayment(card: Card): Observable<PaymentResponse> {
    console.log(card, "cardmakepaument");

    const rest$: Observable<Restaurant> = this.restaurantService.getRest();
    return rest$.pipe(
      take(1),
      mergeMap((rest) => {
        const payment: Payment = {
          restauranteId: rest.restauranteId,
          user: new User(),
          method: "CREDIT_CARD",
          total: this.getTotalValue(),
          entrega: 0,
          titular: card.titular,
          cpf: card.cpf,
          cartao: card,
        };
        console.log(payment, "paymentmakepaymente");

        return this.paymentService.pay(payment);
      }),
      mergeMap((response) => (response.errors ? throwError(response.message) : of(response)))
    );
  }
  /**
   * Faz o pagamento com consumo
   */
  private makeConsumo(card: cardConsumo): Observable<ConsumoResponse> {
    const rest$: Observable<Restaurant> = this.restaurantService.getRest();

    return rest$.pipe(
      take(1),
      mergeMap((rest) => {
        const payment: Payment = {
          restauranteId: rest.restauranteId,
          numeroCartao: card.numero,
          saldo: this.getTotalValue(),
          tipoMov: 2,
        };

        return this.paymentService.payConsumo(payment); ///////// criar pagamento de consumo
      }),
      mergeMap((response) => (response.aproved ? of(response) : throwError(response.mensage)))
    );
  }

  /**
   * Monta um objeto com as informações necessárias pra mandar para a API
   */
  private prepareOrderObj(extras?: { nsu?: number | string; userZimmer?: UserZimmer; comandaId?: string; table?: string; mode?: string; rest?: Restaurant; user?: User; payment?: string }): SendOrderApi {
    const order: SendOrderApi = new Object() as any;
    const cart = new Cart(this.cart);
    order.cartTotal = {
      discount_total: 0,
      shipping_total: this.getTotalValue(),
      subtotal: this.getTotalValue(),
      taxTotal: 0,
      total: this.getTotalValue(),
      total_tax: 0,
    };
    order.cartItens =
      cart.items.map((item) => ({
        cartItemOpcoes: item.cartItemOpcoes,
        cartMeioMeios: item.meiomeios,
        observacao: item.observacao,
        produto: item.produto,
        quantidade: item.number,
        souvinir: [],
        valorentrega: 0,
        valorprodutos: item.produto.valorPromocao || item.produto.valorVenda || item.produto.valorRegular || 0,
        valortotal: this.calcTotalItemValue(item),
      })) || [];
    order.usuarioGuid = environment.USER_GUID;
    order.isEntrega = false;
    order.paymentNsu = extras?.nsu;
    order.isPagamentoOnline = !!extras?.nsu;
    order.restaurante = extras.rest;
    order.troco = 0;
    order.userZimmer = {
      nome: extras.user?.nome,
      fone: extras.user?.fone,
      email: extras.user?.email,
    };
    order.formaPagamento = extras.payment;
    order.comandaId = extras?.comandaId;
    order.referencia = extras.table; //params?.tableNumber  || (this.auth.getTable() + '')
    if (extras.mode == environment.appModes.table) order.orderType = "TABLE";
    else if (extras.mode == environment.appModes.onlinePayment || extras.mode == environment.appModes.comanda) order.orderType = "TOTEN";
    else if (extras.mode == environment.appModes.drive) order.orderType = "DRIVE";

    return order;
  }

  /**
   * Instancia um Modal para que as informações necessárias para o envio via WhatsApp sejam requisitadas ao usuário
   * O envio da mensagem é feito pelo próprio componente pois os navegadores bloqueiam popups programáticos. Ou seja, nada de abrir
   * novas guias fora da main thread
   */
  private askZapInformation(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.modalCtrl
        .create({
          component: UserInfoRequestZapPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            switch (dismiss.role) {
              case "confirm":
                subscriber.next(true);
                subscriber.complete();
                break;
              case "cancel":
                subscriber.error("Não foi possível realizar pedido");
                break;
              default:
                this.alertCtrl
                  .create({
                    message: "Ops, algo deu errado",
                  })
                  .then((alert) => alert.present());
                subscriber.error("Não foi possível realizar pedido");
                break;
            }
          });
          modal.present();
        });
    });
  }

  private askGestorInformation(): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.modalCtrl
        .create({
          component: UserInfoRequestGestorPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            switch (dismiss.role) {
              case "confirm":
                subscriber.next(true);
                subscriber.complete();
                break;
              case "cancel":
                subscriber.error();
                break;
              default:
                this.alertCtrl
                  .create({
                    message: "Ops, algo deu errado",
                  })
                  .then((alert) => alert.present());
                subscriber.error("Não foi possível realizar pedido");
                break;
            }
          });
          modal.present();
        });
    });
  }

  /**
   * Pede as informações necessárias para a realização de pedido na rota de Pagamento online
   * @returns
   */
  private askPaymentInformation(): Observable<PaymentInfoRequestResponse> {
    return new Observable((subscriber) => {
      this.modalCtrl
        .create({
          component: UserInfoRequestCardPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            switch (dismiss.role) {
              case "confirm":
                subscriber.next(dismiss.data);
                break;
              case "cancel":
                subscriber.error();
                break;
              default:
                this.alertCtrl
                  .create({
                    message: "Ops, algo deu errado",
                  })
                  .then((alert) => alert.present());
                subscriber.error();
            }
          });
          modal.present();
        });
    });
  }

  /**
   * Pede as informações necessárias para a realização de pedido na rota de Consuomo
   * @returns
   */
  private askPaymentInformationConsumo(): Observable<ConsumoInfoRequestResponse> {
    return new Observable((subscriber) => {
      this.modalCtrl
        .create({
          component: UserInfoRequestConsumoPage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            switch (dismiss.role) {
              case "confirm":
                subscriber.next(dismiss.data);
                break;
              case "cancel":
                subscriber.error();
                break;
              default:
                this.alertCtrl
                  .create({
                    message: "Ops, algo deu errado",
                  })
                  .then((alert) => alert.present());
                subscriber.error();
            }
          });
          modal.present();
        });
    });
  }

  private askDriveThruInfo(): Observable<DriveThruInfoRequestResponse> {
    return new Observable((subscriber) => {
      this.modalCtrl
        .create({
          component: UserInfoDrivePage,
          cssClass: "modal-fullscreen",
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            if (dismiss.role == "confirm") {
              subscriber.next(dismiss.data);
              subscriber.complete();
            } else subscriber.error();
          });
          modal.present();
        });
    });
  }

  /**
   * Pede uma comanda
   */
  private askComanda(): Observable<string> {
    return new Observable((subscriber) => {
      this.modalCtrl
        .create({
          component: ComandaScanPage,
          cssClass: "modal-fullscreen",
          id: environment.modals.comandaScannerId,
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            if (dismiss.role == "confirm") {
              subscriber.next(dismiss.data);
              subscriber.complete();
            } else subscriber.error();
          });
          modal.present();
        });
    });
  }

  private askTableNumber1(): Observable<number | string> {
    return new Observable((subscriber) => {
      this.modalCtrl
        .create({
          component: TablePagePage,
          cssClass: "modal-fullscreen",
          id: environment.table_number_input_id,
        })
        .then((modal) => {
          modal.onDidDismiss().then((dismiss) => {
            if (dismiss.role == "confirm") {
              subscriber.next(dismiss.data);
              subscriber.complete();
            } else subscriber.error();
          });
          modal.present();
        });
    });
  }
  /**
   * Pergunta o número da mesa
   */
  private askTableNumber(): Observable<number | string> {
    return new Observable((subscriber) => {
      this.alertCtrl
        .create({
          message: "Digite corretamente o número da mesa para receber o seu pedido.",
          animated: true,
          header: "Digite o número da MESA:",
          mode: "ios",
          inputs: [
            {
              id: environment.table_number_input_id,
              type: "number",
              cssClass: "ion-text-center",
            },
          ],
          buttons: [
            {
              text: "Cancelar",
              cssClass: "alert_font_cancel",
              handler: () => {
                try {
                  this.modalCtrl.dismiss();
                } catch (e) {}
                subscriber.error();
              },
            },
            {
              text: "Confirmar",
              handler: () => {
                const tableNumberInput: IonInput = document.getElementById(environment.table_number_input_id) as any;
                if (((tableNumberInput.value || "") as string).length > 0) {
                  subscriber.next(tableNumberInput.value);
                  subscriber.complete();
                } else return false;
              },
            },
          ],
          backdropDismiss: false,
        })
        .then((alert) => alert.present());
    });
  }

  /**
   * Retorna o carrinho
   */
  getCart(): Cart {
    return this.cart;
  }

  /**
   * Pega os pedidos já realizados
   */
  getOrders(): Cart[] {
    return this.orders;
  }

  /**
   * Limpa os pedidos atuais e já realizados
   * @deprecated
   */
  logout(): void {
    this.orders = [];
    this.cart.items = [];
    this.cart.discount = 0;
  }

  /**
   * Busca o pedido pelo ID do usuário - obsoleto
   * @param { string|number } userId ID do cliente final
   */
  getOrderByUserId(userId: string | number): Observable<ResponseApi<any>> {
    return this.http.get<ResponseApi<any>>(`${environment.SERVERHOST}/pedido/meus/${userId}/999/1`);
  }

  /**
   * Busca o pedido pelo ID do restaurante e da mesa
   * Não está funcionando ainda, então está buscando os pedidos salvo no localStorage
   * @deprecated
   */
  getOrdersByRestauranteAndTable(): Cart[] | Observable<ResponseApi<any>> {
    return this.storage.get("orders") || [];
    // Não funcionando ainda
    return this.http.get<ResponseApi<any>>(`${environment.SERVERHOST}/pedido/meus/${this.restaurantService.getRestaurant().restauranteId}/999/1`);
  }

  /**
   * Retorna o tipo de configuração de reconhecimento('qrcode', 'credentials' ou 'menu') salvo no storage
   */
  getMode(): string {
    return this.storage.get(environment.routeParams.appMode);
    //return "qrcode"
  }

  /**
   * Limpa os itens do carrinho
   */
  clear(): void {
    this.cart.items = [];
  }

  /**
   * Mostra o carrinho em um modal
   */
  showCart(items): void {
    this.modalCtrl
      .create({
        component: CartNewPage,
        componentProps: {
          cart: {
            items,
            discount: 0,
          },
        },
        id: environment.modals.cartNewId,
        cssClass: "modal-fullscreen",
      })
      .then((modal) => modal.present());
  }

  /**
   * Retorna a ficha..?
   * @deprecated
   */
  getFicha(): string | number {
    return this.storage.get("ficha");
  }

  getFichaV2(): Observable<string | number> {
    return this.ficha$.asObservable();
  }

  setFichaV2(fichaNumber: number): void {
    this.ficha$.next(fichaNumber);
  }
  /**
   * @returns Valor total do pedido
   */
  calcTotalItemValue(item: ItemCart): number {
    let total: number = 0;
    item.cartItemOpcoes.forEach((option) => (total += option.valor * option.quantidade));
    if (item.produto.numberMeiomeio > 1 && !item.produto.valorVenda && item.meiomeios.length > 0) {
      const mostExpensive = item.meiomeios.reduce((lastHalf, half) => ((lastHalf.valorPromocao || lastHalf.valorVenda) > (half.valorPromocao || half.valorVenda) ? lastHalf : half));
      total += mostExpensive.valorVendaComplemento || mostExpensive.valorPromocao || mostExpensive.valorVenda;
    }
    let preco = item.produto.valorPromocao || item.produto.valorVenda || item.produto.valorRegular || 0;
    total += item.number * preco;
    return Number(total.toFixed(2));
  }

  /**
   * @returns ID do pedido realizado (salvo no sessionStorage)
   */
  getPedidoIdInSession(): number | string {
    return this.storage.getSessionData("senha");
  }

  /**
   * Procura o primeiro item com determinado ID de produto
   */
  getItemByProdId(prodId: number): ItemCart {
    return this.cart.items.find((item) => item.produto.produtoId == prodId);
  }

  /**
   * Retorna a quantidade de item + 1
   * Usado principalmente para gerar um id para um novo item
   */
  generateNextId(): number {
    return (this.cart.items.length > 0 ? this.cart.items[this.cart.items.length - 1].id : 0) + 1;
    // return this.getAllItems().length + 1
  }
}

interface PaymentInfoRequestResponse {
  userName: string;
  phone: string;
  cpf: string;
  method: number;
  card: Card;
}

interface ConsumoInfoRequestResponse {
  userName: string;
  phone: string;
  cpf: string;
  method: number;
  card: Card;
  cardConsumo: cardConsumo;
}

interface DriveThruInfoRequestResponse {
  userName: string;
  licensePlate: string;
  phone?: string;
  cpf?: string;
  method: number;
  card?: Card;
}
