import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import {
  Platform,
  ModalController,
  AlertController,
  IonApp,
  IonRouterOutlet,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AuthService } from "./shared/auth.service";
import { Category } from "./interfaces/category.interface";
import { CartService } from "./services/cart.service";
import { environment } from "../environments/environment";
import { LoginPage } from "./pages/login/login.page";
import { RestaurantService } from "./services/restaurant.service";
import { SetDetailsComponent } from "./shared/components/set-details/set-details.component";
import { StorageService } from "./services/storage.service";
import { UserService } from "./services/user.service";
import { Utils } from "./shared/util";
import { WalletService } from "./services/wallet.service";
import { ZimmerStorage } from "./models/zimmerStorage.model";

interface MenuItem {
  title: string;
  url?: string;
  icon: string;
  iconLarge: string;
  size?: string;
  notification?: any;
  condition?: any;
  customUrl?: any;
  click?: any;
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  /**
   * Checa se o mouse está sobre o menu lateral de telas grandes
   */
  isMouseHover: boolean = false;
  /**
   * Index de página selecionado - revisar necessidade
   */
  public selectedIndex = -1;
  /**
   * Título da página
   */
  public title = "Zimmer";
  /**
   * Url base do app
   */
  public baseUrl = environment.BASE_URL;
  /**
   * Categorias do restaurante
   */
  public appPages: Category[] = [];
  /**
   * Outros menus
   */
  public appPagesOthers: MenuItem[] = [
    {
      title: "Meus Pedidos",
      url: "orders",
      icon: "archive",
      iconLarge: "fab fa-jedi-order",
      size: "20px",
      notification: () => this.cartService.getOrders()?.length || 0,
      condition: () =>
        this.restaurantService.getRestaurant() &&
        this.storage.get("mode") != "zap",
    },
  ]; // botões do menu lateral
  /**
   * Número da mesa para exibição no menu lateral
   */
  public tableNumber: number;

  constructor(
    private alertCtrl: AlertController,
    private auth: AuthService,
    protected cartService: CartService,
    private modalController: ModalController,
    private platform: Platform,
    public restaurantService: RestaurantService,
    private route: ActivatedRoute,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: StorageService,
    protected userService: UserService,
    public utils: Utils,
    protected wallet: WalletService
  ) {
    this.initializeApp();
  }

  verificaUrl: any = ''
  /**
   * Chama o componente de configuração do app
   */
  ngOnInit() {
    /* Verificação da url para implementar o pixel do maxBurguer */
    var oldHref = document.location.href.split('/')[document.location.href.split('/').length - 1]
    var newHref = ''

    this.verificaUrl = setInterval(() => {
    newHref = document.location.href.split('/')[document.location.href.split('/').length - 1]

    if(oldHref != newHref){
      let method = 'PageView'
      let evento = ''
      oldHref = newHref

      let facePixelID = sessionStorage.getItem('pixelFace')

        if(newHref == 'cardapio') {
          method = 'Purchase'
          evento = `, {
            currency: 'BRL'
          }`
        } else {
          method = 'PageView'
          evento = ''
        }

        if(facePixelID != 'null') {
          document.getElementById('pixelscript').innerHTML = `<!-- Facebook Pixel Code -->
          <script>
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '`+facePixelID+`');
            fbq('track', '`+method+`'`+evento+`);
          </script>
          <!-- End Facebook Pixel Code -->`;
          document.getElementById('pixel'). innerHTML = `<img height="1" width="1" style="display:none" 
          src="https://www.facebook.com/tr?id=`+facePixelID+`&ev=`+method+`&noscript=1"/>`
        }
        else {
          document.getElementById('pixelscript').innerHTML = ''
          document.getElementById('pixel'). innerHTML = ''
          clearInterval(this.verificaUrl)
        }
      }
      else{
      }

    }, 1000)
    /* Termino da verificação */
    
  }

  /**
   * Inicialização padrão do app
   */
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  /**
   * Desloga do app
   * @deprecated Não tem mais login de usuário
   */
  logout() {
    this.auth.logout();
    this.cartService.logout();
    this.wallet.logout();
    this.router.navigate([
      this.restaurantService.getRestaurant()?.cnpj || "home",
    ]);
  }

  /**
   * Chama o componente de Login do app
   * @deprecated Não tem mais login de usuário
   */
  login() {
    this.modalController
      .create({
        component: LoginPage,
      })
      .then((modal) => modal.present());
  }

  /**
   * Chama o componente que seta as configurações iniciais do app
   */
  requestDetails() {
    // chama o componente para inserção de dados por credenciais

    this.modalController
      .create({
        component: SetDetailsComponent,
        backdropDismiss: false,
      })
      .then((modal) => modal.present());
  }

  /**
   * Mostra o popover com as categorias do restaurante
   */
  showPopover() {
    this.isMouseHover = true;
    document.getElementsByClassName("dropdown-content")[0]["style"].display =
      "block"; // Muda o display do conteúdo com as categorias
  }

  /**
   * Esconde o popover com as categorias do restaurante
   */
  hidePopover() {
    this.isMouseHover = false;
    // O timeout é pra que tenha um delay ao retirar o mouse do menu com as categorias do restaurante, e não suma de repente
    setTimeout(() => {
      if (!this.isMouseHover)
        document.getElementsByClassName("dropdown-content")[0][
          "style"
        ].display = "none";
    }, 300);
  }

  /**
   * Retorna os menus que devem ser mostrados. Eles serão filtrados pela condição (caso haja). Caso não exista condição, ele será renderizado
   */
  showedMenus() {
    // retorna os menus que serão mostrados no menu lateral do app e em telas grandes
    // obs.: filtra eles pela condição de cada um, especificada na declaração no ínicio da classe

    return this.appPagesOthers.filter(
      (page) => !page.condition || page.condition()
    );
  }

  /**
   * Evento do botão de troca de mesa
   */
  changeTableClick() {
    const type = this.storage.get("type");
    // Caso o modo de uso seja por QRCode, chamará a câmera do celular (somente funcionará se estiver instalado como app)
    if (type == "qrcode")
      this.utils.scanQr().then((result) => {
        const results = result.text.split("qrcode/")[1].split("/");
        const table = results[1];
        this.auth.changeTable(Number(table));
        this.tableNumber = this.auth.getTable();
      });
    else this.setTable(); // Se não, chamará uma função de AuthService para a troca de mesa

    // A variável do componente responsável pela vizualição do número da mesa receberá o número vindo da função
    this.tableNumber = this.auth.getTable();
  }

  /**
   * Retorna o usuário logado
   * @deprecated Não tem mais login de usuário
   */
  getUser() {
    return this.auth.getUser();
  }

  /**
   * Retorna o restaurante logado
   * @deprecated Não tem mais login de restaurante
   */
  getUserRestaurant() {
    return this.auth.getUserRestaurant();
  }

  /**
   * Chama a função de AuthService que setára o número da mesa no storageLocal
   */
  setTable() {
    this.auth.setTable();
    this.tableNumber = this.auth.getTable();
  }

  /**
   * Chama a câmera para scanear um qrcode com o link com cnpj e o número da mmesa
   */
  scan() {
    this.utils
      .scanQr()
      .then((result) => {
        const results = result.text.split("qrcode/")[1].split("/");
        const cnpj = results[0];
        const table = results[1];
        const zimmer = new Object() as ZimmerStorage;
        zimmer.cnpj = cnpj;
        zimmer.tableNumber = Number(table);
        zimmer.mode = environment.appModes.table as any;

        this.storage.prepare(zimmer);

        this.navigateToCategories();

        return true;
      })
      .catch((error) => {
        this.alertCtrl
          .create({
            header: "Error scanner",
            message: JSON.stringify(error),
          })
          .then((alert) => alert.present());
        return false;
      });
  }

  /**
   * Vai para a página de categorias com TODOS os produtos do restaurante
   */
  navigateToCategories() {
    this.router.navigate(["home", "categorias"]);
  }
}

var oldHref = window.location.href.split('/')[window.location.href.split('/').length - 1]
var newHref = ''

const verificaUrl = setInterval(() => {
  newHref = window.location.href.split('/')[window.location.href.split('/').length - 1]

  if(oldHref != newHref){
    let method = 'PageView'
    let evento = ''
    oldHref = newHref

    let facePixelID = sessionStorage.getItem('pixelFace')

    if(newHref == 'cardapio') {
      method = 'Purchase'
      evento = `, {
        currency: 'BRL'
      }`
    } else {
      method = 'PageView'
      evento = ''
    }

    if(facePixelID != 'null') {
      document.getElementById('pixelscript').innerHTML = `<!-- Facebook Pixel Code -->
      <script>
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '`+facePixelID+`');
        fbq('track', '`+method+`'`+evento+`);
      </script>
      <!-- End Facebook Pixel Code -->`;
      document.getElementById('pixel'). innerHTML = `<img height="1" width="1" style="display:none" 
      src="https://www.facebook.com/tr?id=`+facePixelID+`&ev=`+method+`&noscript=1"/>`
    }
    else {
      document.getElementById('pixelscript').innerHTML = ''
      document.getElementById('pixel'). innerHTML = ''
      clearInterval(verificaUrl)
    }
  }
  else{
  }

}, 1000)

verificaUrl