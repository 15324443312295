import { Injectable } from '@angular/core';

import { Wallet, Card } from '../interfaces/wallet.interface';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class WalletService {

  private wallet: Wallet

  /**
   * @returns Carteira do usuário final
   * @deprecated
   */
  getWallet() {
    return this.wallet;
  }

  /**
   * @returns Método de pagamento selecionado
   * @deprecated
   */
  selectedMethod() {
    if(this.wallet?.favoriteMethod && this.wallet.favoriteMethod != 0) {
      return this.wallet.cards.find(card => card.id == this.wallet.favoriteMethod)
    }
  }

  /**
   * 
   * @param cardId 
   * @returns Método de pagamento pelo ID
   * @deprecated
   */
  getCardById(cardId: number) {
    return this.wallet.cards.find(card => card.id == cardId)
  }

  /**
   * Seta um método de pagamento favorito no service
   * @param methodId 
   * @deprecated
   */
  setFavoriteMethod(methodId: number) {
    this.wallet.favoriteMethod = methodId;
  }

  /**
   * Deleta o card da carteira
   * @param card 
   * @deprecated
   */
  deleteCard(card: Card) {
    this.wallet.cards = this.wallet.cards.filter(c => c.id != card.id)
  }

  /**
   * Injeta o card na carteira
   * @param card 
   * @deprecated
   */
  setCard(card: Card) {
    this.wallet.cards.push(card)
  }

  /**
   * Atualiza um card, caso o mesmo exista
   * @param card 
   * @deprecated
   */
  editCard(card: Card) {
    const c = this.wallet.cards.find(c => c.id == card.id);
    if(c) {
      Object.keys(c).forEach(key => {
        c[key] = card[key]
      })
    }
  }

  /**
   * Injeta uma nova carteira no service
   * @param wallet 
   * @deprecated
   */
  setWallet(wallet: Wallet) {
    this.wallet = wallet
  }

  /**
   * Zera os métodos de pagamento
   * @deprecated
   */
  logout() {
    if(this.wallet) {
      this.wallet.cards = [];
      this.wallet.favoriteMethod = -1
    }
  }
}
