import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { ModalController, AlertController } from '@ionic/angular';

import { CartService } from '../../services/cart.service';
import { ItemCart } from '../../interfaces/itemCart.interface';
import { Product } from '../../models/produto.model';
import { ProductOptionType, ProductOption } from '../../interfaces/product-option.interface';
import { ProductService } from '../../services/product.service';
import { RestaurantService } from '../../services/restaurant.service';
import { Utils } from '../../shared/util';
import { Half } from '../../models/half.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.page.html',
  styleUrls: ['./item.page.scss'],
})
export class ItemPage implements OnInit {

  /**
   * Função para abrir o carrinho
   */
  @Input() openCartFn: any // TODO revisar necessidade
  /**
   * Item do carrinho passado. eg: usado quando o produto já está no carrinho
   */
  @Input() itemCart: ItemCart // TODO Revisar possibilidade de usar valor default = new ItemCart()
  /**
   * Produto do item do carrinho
   */
  @Input() prod?: Product
  /**
   * Diz se o item é editável. Usado depois que o pedido foi finalizado
   */
  @Input() readOnly: boolean

  item: ItemCart = new ItemCart() // 
  /**
   * Diz se o item é novo ou está sendo editado
   */
  mode: 'new'|'edit' = 'new' // TODO revisar necessidade de variável
  /**
   * Observação do item
   */
  obs: string // TODO Revisar possibilidade de usar diretamente no item e não em uma variável

  /**
   * Contém um observable com as opções do produto
   */
  options$: Observable<ProductOptionType[]> = new Observable<ProductOptionType[]>()
  /**
   * Inscrição de $options
   */
  sub_options: Subscription
  /**
   * Observable de meio a meios. Só serão requisitados caso o numberMeioMeio do produto seja > 1
   */
  meios$: Observable<Half[]>

  constructor(
    private alertCtrl: AlertController,
    public cart: CartService,
    protected modalController: ModalController,
    private productService: ProductService,
    public restaurantService: RestaurantService,
    public utils: Utils
  ) { }

  ngOnInit() {
    // Caso nenhum Item seja passado na criação do modal/page ele irá criar um
    this.options$ = this.productService.getOptions(this.prod?.produtoId || this.itemCart.produto.produtoId)
    this.meios$ = this.productService.getHalfs(this.prod?.produtoId || this.itemCart.produto.produtoId)

    if(!this.itemCart) {
      this.item = new ItemCart()
      this.item.id = this.cart.getAllItems().length + 1
      this.item.produto = this.prod
      this.item.number = 1
      // this.item.complementos = []
      this.item.observacao = ''
      this.obs = ''
    } else {
      const keys = Object.keys(this.itemCart)
      keys.forEach(key => this.item[key] = this.itemCart[key])
      this.mode = 'edit'
      this.obs = this.itemCart.observacao
    }
  }

  /**
   * Fecha o modal
   */
  closeItemDetails() {
    this.modalController.dismiss()
  }

  /**
   * Tira o produto do carrinho
   * @deprecated
   * @param product 
   */
  throwFromCart(product: Product) {
    this.cart.removeItem(product.produtoId)
    this.utils.animate('shaking removing', 'cart-icon', 'class', 900)
  }

  /**
   * Adiciona o item no carrinho
   * @param item 
   */
  addToCart(item: ItemCart) {
    this.item.observacao = this.obs
    const sub_options = this.options$.subscribe(options => {
      let error: boolean // Se existir algum erro nas validações, deve ser passado true para essa variável

      // Checagem de opcionais
      for(const option of options) {
        const qtd = this.getQtdSelected(option.produtosOpcaoTipoId)

        if(option.obrigatorio && option.quantidade > qtd) {
          this.alertCtrl.create({
            header: 'Ops! Faltou algo',
            message: `A categoria ${option.nome} deve ter ${option.quantidade} escolhidos`
          }).then(alert => alert.present())
          error = true
          break
        } else if(option.qtdeMax < qtd) {
          this.alertCtrl.create({
            header: 'Ops!',
            message: `A categoria ${option.nome} deve ter no máximo ${option.qtdeMax} escolhidos`
          }).then(alert => alert.present())
          error = true
          break
        }
      }
      sub_options.unsubscribe()

      // Checagem de meio a meios
      if(this.item.produto.numberMeiomeio > 1)
        if(this.item.meiomeios.length < 2) {
          this.alertCtrl.create({
            header: 'Ops! Faltou algo',
            message: `Faltam ${this.item.produto.numberMeiomeio - this.item.meiomeios.length} sabores para serem escolhidos`
          }).then(alert => alert.present())
          error = true
        }

      // Caso não haja erros, adicionará o item no carrinho
      if(!error) {
        if(this.mode == 'new') {
          this.cart.setItem(item)
          this.utils.animate('shaking adding', 'cart-icon', 'class', 900)
        } else if(this.mode == 'edit') {
          this.cart.editItem(item)
        }
        this.closeItemDetails()
      }
    })
  }

  /**
   * Cara, por algum motivo eu passo a função de abrir o carrinho, mas não lembro porque
   * @deprecated
   */
  openCartTrigger() {
    this.openCartFn()
  }

  /**
   * Ignora isso aqui kkkl
   * @ignore
   */
  itsTimeToEat() {
    this.utils.showToast({
      message: 'É hora do rango',
      color: 'dark'
    })
  }

  /**
   * Troca as quantidades de opções
   * @obs A lógica tá bem chatinha de entender, então cuidado na hora de mexer
   * @param selectedOption 
   * @param optionType 
   */
  toggleOption(selectedOption: ProductOption, optionType: ProductOptionType) {
    /**
     * Index da primeira opção encontrada com o ID do tipo de opção que ela pertence // TODO Verificar necessidade
     */
    const productOptionIndex = this.item.cartItemOpcoes.findIndex(option => option.produtosOpcaoTipoId == optionType.produtosOpcaoTipoId)
    /**
     * Opção com o mesmo id da opção que está sendo selecionada
     */
    const oldOption = this.item.cartItemOpcoes.find(option => option.produtosOpcaoId == selectedOption.produtosOpcaoId)
    /**
     * Primeira opção do tipo de opção da opção que está sendo selecionada
     */
    const firstOptionFromType = this.item.cartItemOpcoes[productOptionIndex]
    /**
     * Quantidade de opções selecionadas daquele tipo de opção
     */
    const qtd = this.getQtdSelected(optionType.produtosOpcaoTipoId)

    // ? Quantidade máxima de opção é igual a 1?
    if(optionType.qtdeMax == 1)
      // SE a quantidade máxima é 1
      // ? Essa opção está no array de opções?
      if(productOptionIndex != -1)
        // SE essa opção está no array
        // ? Essa opção que está no array, tem o mesmo ID da opção que está sendo selecionada?
        if(firstOptionFromType.produtosOpcaoId == selectedOption.produtosOpcaoId)
          // SE essa opção tem o mesmo ID da opção que está sendo selecionada
          // ? Ela é obrigatória?
          if(!optionType.obrigatorio) {
            // SE NÃO é obrigatória
            // A quantidade é zerada e o item é retirado do array
            selectedOption.quantidade = 0
            this.item.cartItemOpcoes = this.item.cartItemOpcoes.filter(option => option.produtosOpcaoId != selectedOption.produtosOpcaoId)
          } else; // Se for obrigatória, nada acontece
        else {
          // SE essa opção NÃO tem o mesmo ID da opção que está sendo selecionada
          // A opção selecionada recebe a quantidade 1 e é inserida no array de opções no lugar da que estava (está substituindo ela)
          firstOptionFromType.quantidade = 0
          selectedOption.quantidade = 1
          this.item.cartItemOpcoes[productOptionIndex] = selectedOption
        }
      else {
        // SE essa opção não está no array de opções
        // Ela insere a opção nova no array com quantidade igual a 1
        selectedOption.quantidade = 1
        this.item.cartItemOpcoes.push(selectedOption)
      }
    else if(productOptionIndex == -1) {
      // SE a quantidade máxima do tipo de opção NÃO é igual a 1 
      // E(&&) ela NÃO está no array
      // Ela é adicionada
      selectedOption.quantidade = 1
      this.item.cartItemOpcoes.push(selectedOption)
    } else if(oldOption?.produtosOpcaoId == selectedOption.produtosOpcaoId)
      // SE a quantidade máxima NÃO é igual a 1
      // E(&&) ela ESTÁ no array
      // E(&&) o id dela é igual a opção que está sendo selecionada
      // O array de opções rece um novo array mas sem ela (remove a opção do array)
      this.item.cartItemOpcoes = this.item.cartItemOpcoes.filter(option => option.produtosOpcaoId != selectedOption.produtosOpcaoId)
    else if(qtd < optionType.qtdeMax) {
      // SE a quantidade máxima NÃO é igual a 1
      // E(&&) ela ESTÁ no array
      // E(&&) o id dela NÃO é igual a opção que está sendo selecionada
      // Inserido é a opção dentro do array com quantidade 1
      selectedOption.quantidade = 1
      this.item.cartItemOpcoes.push(selectedOption)
    }
  }

  addHalf(half: Half) {
    this.item.meiomeios.push(half)
  }

  removeHalf(half: Half) {
    const indexToRemove: number = this.item.meiomeios.findIndex(h => h.produtoId == half.produtoId)
    this.item.meiomeios = this.item.meiomeios.filter((value, index) => index != indexToRemove)
  }

  getHalfsQuantity(halfId?: number): number {
    return !halfId ? this.item.meiomeios.length : this.item.meiomeios.filter(half => half.produtoId == halfId).length
  }

  // Adicionar e remover produtos do item
  changeNumber(operation: 'add'|'remove') {
    this.item.number ? operation == "add" ? this.item.number++ : this.item.number-- : this.item.number = 1;
  }

  // Checa se o complemento está incluído
  hasOption(optionId: string) {
    return this.item.cartItemOpcoes.findIndex(option => option.produtosOpcaoId == optionId) != -1
    // return false
  }

  /**
   * Mostra a quantidade de opções selecionadas naquela categoria de opções
   * @param optionTypeId 
   */
  getQtdSelected(optionTypeId: number) {
    let i = 0
    this.item.cartItemOpcoes.forEach(option => optionTypeId == option.produtosOpcaoTipoId && (i += (option.quantidade || 0)))
    return i
  }

  /**
   * Mostra a quantidade da opção selecionada
   */
  getOptionQtd(optionId: string|number) {
    const optionsIndex = this.item.cartItemOpcoes.findIndex(option => option.produtosOpcaoId == optionId)
    const option = this.item.cartItemOpcoes[optionsIndex]
    return option?.quantidade || 0
  }

  addOption(option: ProductOption, optionType: ProductOptionType, event?: MouseEvent) {
    if(event) event.stopPropagation()
    const qtd = this.getQtdSelected(optionType.produtosOpcaoTipoId)
    if(qtd < optionType.qtdeMax) {
      const optionIndex = this.item.cartItemOpcoes.findIndex(op => op.produtosOpcaoId == option.produtosOpcaoId)
      if(optionIndex == -1) (option.quantidade = 1) && this.item.cartItemOpcoes.push(option);
      else this.item.cartItemOpcoes[optionIndex].quantidade++
    }
  }

  removeOption(option: ProductOption, optionType: ProductOptionType, event?: MouseEvent) {
    if(event) event.stopPropagation()
    if(option.quantidade > 1) option.quantidade--
    else if(option.quantidade == 1) {
      option.quantidade--
      this.item.cartItemOpcoes = this.item.cartItemOpcoes.filter(op => option.produtosOpcaoId != op.produtosOpcaoId)
    }
  }

  getHalfQuantityInItem(half: Half) {
    return this.item.meiomeios.filter(h => h.produtoId == half.produtoId).length
  }
}
