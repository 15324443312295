import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ResponseLoginApi } from '../interfaces/reponse-login.interface';
import { RestaurantService } from './restaurant.service';
import { User } from '../models/user.model';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private restaurantService: RestaurantService,
  ) { }

  /**
   * Login de usuário final
   * @param user 
   * @param password 
   * @deprecated
   */
  login(user: string, password: string): Observable<ResponseLoginApi> {
    return this.http.post<ResponseLoginApi>(`${environment.SERVERHOST}/login`, { email: user, senha: password, restauranteId: this.restaurantService.getRestaurant().restauranteId })
      .pipe(tap(data => {
        return data
      }, error => {
        console.log('login userService error', error)
      }))
  }

  /**
   * Cadastro de usuário final
   * @param user 
   * @deprecated
   */
  create(user: User) {
    user.restauranteId = this.restaurantService.getRestaurant().restauranteId;
    return this.http.post<{ errors: boolean, message: string }>(`${environment.SERVERHOST}/login/registar`, user)
      .pipe(tap(data => {
        if(!data?.errors) {
        }
      }, error => {
      }))
  }
}
