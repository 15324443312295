import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";

import { AlertController, ModalController } from "@ionic/angular";

import { Localizacao } from "../../../models/address/localizacao.model";
import { AddAddressPage } from "../../../shared/components/v2/add-address/add-address.page";
import { environment } from "../../../../environments/environment";
import { MaskService } from "../../../services/providers/mask.service";
import { AuthService } from "../../../shared/auth.service";
import { CartService } from "../../../services/cart.service";
import { RestaurantService } from "../../../services/restaurant.service";
import { Restaurant } from "../../../models/restaurant.model";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ToastaddService } from "src/app/services/toastadd.service";

@Component({
  selector: "app-user-info-request-zap",
  templateUrl: "./user-info-request-zap.page.html",
  styleUrls: ["./user-info-request-zap.page.scss"],
})
export class UserInfoRequestZapPage implements OnInit {
  form: FormGroup;
  tableNumber: string;
  rest$: Observable<Restaurant>;
  isTablet: boolean;
  restImagem: any;
  logoZimmer: any;
  checked: boolean;
  isDisabled: boolean = true;

  constructor(
    private auth: AuthService,
    private alertCtrl: AlertController,
    private alerta: ToastaddService,
    private cart: CartService,
    private formBuilder: FormBuilder,
    public mask: MaskService,
    private modalCtrl: ModalController,
    private restService: RestaurantService,
    public config: ConfigurationService
  ) {
    this.isTablet = this.config.isTablet;
    this.logoZimmer = "assets/v2/logo_zimmer.png";
    this.restImagem =
      this.config.imagemRest.imagem != null
        ? this.config.imagemRest.imagem
        : this.logoZimmer;
  }

  ngOnInit() {
    this.rest$ = this.restService.getRest();
    const sub = this.auth.getTableV2().subscribe({
      next: (tableNumber) => {
        this.tableNumber = tableNumber;
        this.buildForm();
        // sub.unsubscribe()
      },
    });
  }

  /**
   * Monta o form
   */
  private buildForm() {
    this.form = this.formBuilder.group({
      userName: [null, [Validators.required]],
      aquisition: [this.tableNumber ? 0 : 1, [Validators.required]],
      address: this.formBuilder.group({
        cep: [null],
        bairro: [null],
        localidade: [null],
        logradouro: [null], // Somente o logradouro requerido pois o componente não vai deixar vir sem os outros campos. Assim fica mais fácil de eu trocar se endereço é requerido ou não
        numero: [null],
        uf: [null],
        complemento: [null],
      }),
      payment: [2, [Validators.required]],
      change: [0, [Validators.required]],
      observation: [null],
      checked: [false],
    });
  }

  /**
   * Altera os validadoes do campo de logradouro no form baseado no método de aquisição (ENTREGA ou RETIRADA)
   * @param aquisitionMethod
   */
  changeRequiredOfLogradouro(aquisitionMethod: number) {
    const field = this.form.get("address.logradouro");
    if (aquisitionMethod == 1) field.clearValidators();
    else field.setValidators([Validators.required]);
    field.updateValueAndValidity();
  }

  /**
   * Chama o componente de busca de endereço
   */
  callAddAddressPage() {
    this.modalCtrl
      .create({
        component: AddAddressPage,
        id: environment.addAddressModalId,
        componentProps: {
          address: this.form.value.address.logradouro
            ? this.form.value.address
            : undefined,
        },
        cssClass: "modal-fullscreen",
      })
      .then((modal) => {
        modal.onDidDismiss().then((dismiss) => {
          // Se retornar success == true na data do dismiss do modal
          if (dismiss.data.success) {
            // Seta a localização no formulário
            const address: Localizacao = dismiss.data.address;
            this.form.patchValue({
              address,
            });
          }
        });
        modal.present();
      });
  }

  /**
   * Fecha o modal com a role 'cancel' sem enviar nenhum dado
   */
  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }

  updateCheck() {
    //this.isDisabled = this.form.value.checked ? false : true
    this.form.value.change = 0;
    console.log(this.form, "form");
  }

  update() {
    this.form.value.change = 0;
    console.log(this.form, "form");
  }

  /**
   * Envia o pedido via whatsapp
   * @param rest
   */
  confirmInfo(rest: Restaurant) {
    console.log(rest, "rest");

    const success = this.cart.sendOrderWhats({
      aquisition: this.form.value.aquisition,
      endereco: this.form.value.address,
      number: rest.foneCelular,
      observation: this.form.value.observation,
      pagamento: this.form.value.payment,
      troco: this.form.value.change,
      userFone: 0,
      userName: this.form.value.userName,
      tableNumber: this.tableNumber,
    });

    if (success) this.modalCtrl.dismiss(this.form.value, "confirm");
  }

  /**
   * Pergunta se o usuário verificou os dados digitados
   */
  next(rest: Restaurant) {
    console.log(rest);
    this.alertCtrl
      .create({
        message:
          "Clique em OK se estiver tudo certo, ou clique em cancelar e revise os dados.",
        animated: true,
        header: "Atenção!",
        mode: "ios",
        buttons: [
          {
            text: "OK",
            handler: () => this.validation(rest),
            //this.confirmInfo(rest)
          },
          {
            text: "Cancelar",
            cssClass: "alert_font_cancel",
          },
        ],
      })
      .then((alert) => alert.present());
  }

  validation(rest: Restaurant) {
    console.log(this.form, "form");

    if (this.form.value.payment == 1 && this.form.value.checked) {
      if (this.form.value.change == 0) {
        this.alerta.alertaMsg("Preencha o valor do troco.", "ATENÇÃO");
        return;
      }
    }

    if (this.form.value.aquisition == 0) {
      this.alerta.alertaMsg("Escolha uma forma de entrega.", "ATENÇÃO");
      return;
    }

    if (this.form.value.aquisition == 2) {
      if (this.form.value.address.logradouro == null) {
        this.alerta.alertaMsg("Preencha o nome da rua.", "ATENÇÃO");
        return;
      }
      if (this.form.value.address.numero == null) {
        this.alerta.alertaMsg("Preencha o numero.", "ATENÇÃO");
        return;
      }
      if (this.form.value.address.bairro == null) {
        this.alerta.alertaMsg("Preencha o bairro.", "ATENÇÃO");
        return;
      }
      if (this.form.value.address.localidade == null) {
        this.alerta.alertaMsg("Preencha a cidade.", "ATENÇÃO");
        return;
      }
    }
    this.confirmInfo(rest);
  }
}
