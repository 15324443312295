import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AlertController, ModalController } from "@ionic/angular";

import { MaskService } from "../../../services/providers/mask.service";
import {
  dayNamesBr,
  dayShortNamesBr,
  monthNamesBr,
  monthShortNamesBr,
} from "../../../shared/components/v2/input-datetime/input-datetime.component";
import { Card } from "../../../models/payment/card.model";
import { ConfigurationService } from "src/app/services/configuration.service";
import { Observable } from "rxjs";
import { cardConsumo } from "src/app/models/payment/consumo.model";
import { PaymentService } from "src/app/services/payment.service";
import { ToastaddService } from "src/app/services/toastadd.service";
import { CartService } from "src/app/services/cart.service";
import { Utils } from "src/app/shared/util";

@Component({
  selector: "app-user-info-request-consumo",
  templateUrl: "./user-info-request-consumo.page.html",
  styleUrls: ["./user-info-request-consumo.page.scss"],
})
export class UserInfoRequestConsumoPage implements OnInit {
  form: FormGroup;
  dateNow: Date = new Date();
  dayNames: string[] = dayNamesBr;
  dayShortNames: string[] = dayShortNamesBr;
  monthNames: string[] = monthNamesBr;
  monthShortNames: string[] = monthShortNamesBr;
  isTablet: boolean;
  restImagem: any;
  logoZimmer: any;
  saldoCartao$: Observable<cardConsumo>;
  cardCliente: {
    cartaoid: string;
    nro: any;
    saldo: any;
  };

  constructor(
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public mask: MaskService,
    private modalCtrl: ModalController,
    private config: ConfigurationService,
    private alerta: ToastaddService,
    private payment: PaymentService,
    private cartService: CartService,
    private utils: Utils
  ) {
    // this.appmode = this.config.modoApp
    // console.log(this.appmode);
    this.isTablet = this.config.isTablet;
    this.logoZimmer = "assets/v2/logo_zimmer.png";
    this.restImagem =
      this.config.imagemRest.imagem != null
        ? this.config.imagemRest.imagem
        : this.logoZimmer;
  }

  /**
   * Monta o form
   */
  ngOnInit() {
    this.form = this.formBuilder.group({
      userName: [null, [Validators.required, Validators.minLength(3)]],
      method: [1, [Validators.required]],
      cpf: [null, [Validators.required, Validators.minLength(14)]],
      phone: [null, [Validators.required]],
      cardConsumo: [null, [Validators.required]],
      card: this.formBuilder.group({
        cardNumber: [null, [Validators.required, Validators.minLength(16)]],
        cvv: [null, [Validators.required, Validators.minLength(3)]],
        dateExp: [null, [Validators.required, Validators.minLength(5)]],
      }),
    });
    console.log(this.form, "form");
  }

  /**
   * Altera os validadores do form para que o botão de enviar pedido seja liberado
   */
  toggleValidators(method: number): void {
    console.log(this.form, "form");
    // Pega os campos
    const cardNumberField = this.form.get("card.cardNumber");
    const cvvField = this.form.get("card.cvv");
    const dateExpField = this.form.get("card.dateExp");
    const cpfField = this.form.get("cpf");
    const consumoField = this.form.get("cardConsumo");

    // Checa o método de pagamento
    if (method == 3) {
      // Limpa validadores
      cardNumberField.clearValidators();
      cvvField.clearValidators();
      dateExpField.clearValidators();

      cardNumberField.updateValueAndValidity();
      cvvField.updateValueAndValidity();
      dateExpField.updateValueAndValidity();

      // Adiciona os validadores
      consumoField.setValidators([Validators.required]);
      cpfField.setValidators([Validators.required, Validators.minLength(14)]);

      consumoField.updateValueAndValidity();
      cpfField.updateValueAndValidity();
    } else {
      // Limpa validadores
      consumoField.clearValidators();
      consumoField.updateValueAndValidity();
      // Adiciona os validadores
      cardNumberField.setValidators([
        Validators.required,
        Validators.minLength(16),
      ]);
      cvvField.setValidators([Validators.required, Validators.minLength(3)]);
      dateExpField.setValidators([
        Validators.required,
        Validators.minLength(5),
      ]);
      cpfField.setValidators([Validators.required, Validators.minLength(14)]);

      cardNumberField.updateValueAndValidity();
      cvvField.updateValueAndValidity();
      dateExpField.updateValueAndValidity();
      cpfField.updateValueAndValidity();
    }

    // this.form.updateValueAndValidity()

    // Atualiza os valores dos campos e faz a validação deles
    // if(method == 1){
    //   cardNumberField.updateValueAndValidity()
    //   cvvField.updateValueAndValidity()
    //   dateExpField.updateValueAndValidity()
    //   cpfField.updateValueAndValidity()
    // }else{
    //   consumoField.updateValueAndValidity()
    //   cpfField.updateValueAndValidity()
    // }
  }

  /**
   * Fecha o modal sem enviar nada e com a role 'cancel'
   */
  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }

  /**
   * Fecha o modal e retorna os dados digitados
   */
  confirmData(method: number) {
    if (method == 1) {
      const formattedData = {
        userName: this.form.value.userName,
        phone: (this.form.value.phone + "").replace(/[^0-9]/gm, ""),
        cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
        method: this.form.value.method,
        card: new Card({
          cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
          cvv: this.form.value.card?.cvv,
          monthExp: this.form.value.card?.dateExp?.substr(5, 2),
          yearExp: this.form.value.card?.dateExp?.substr(2, 2),
          titular: this.form.value.userName,
          num: this.form.value.card?.cardNumber?.replace(/[^0-9]/gm, ""),
        }),
      };
      this.modalCtrl.dismiss(formattedData, "confirm");
    } else {
      const restid = this.config.restauranteId;
      const nroCard = this.form.value.cardConsumo;
      const totalCart = this.cartService.getTotalValue();

      this.saldoCartao$ = this.payment.getSaldoConsumo(restid, nroCard);
      this.saldoCartao$.subscribe((data) => {
        if (data != null) {
          this.cardCliente = {
            cartaoid: data.cartaoConsumoId,
            nro: data.numero,
            saldo: data.saldoAtual,
          };
          if (totalCart > this.cardCliente.saldo) {
            let saldo = this.utils.convert(
              this.cardCliente.saldo,
              "numberToPriceBr"
            );
            this.alerta.alertaMsg(
              "Saldo Atual: R$ " + saldo,
              "Saldo insuficiente"
            );
            return;
          }
          const formattedData = {
            userName: this.form.value.userName,
            phone: (this.form.value.phone + "").replace(/[^0-9]/gm, ""),
            cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
            method: this.form.value.method,
            cardConsumo: new cardConsumo({
              cartaoConsumoId: this.cardCliente.cartaoid,
              numero: this.cardCliente.nro,
              saldoAtual: this.cardCliente.saldo,
            }),
          };
          this.modalCtrl.dismiss(formattedData, "confirm");
        } else {
          this.alerta.alertaMsg(
            "Cartão inexistente ou não cadastrado",
            "ATENÇÃO!"
          );
        }
      });
    }
  }

  /**
   * Pergunta se o usuário verificou os dados digitados
   */
  next(method: number) {
    this.alertCtrl
      .create({
        message:
          "Clique em OK se estiver tudo certo, ou clique em cancelar e revise os dados.",
        animated: true,
        header: "Atenção!",
        mode: "ios",
        buttons: [
          {
            text: "OK",
            handler: () => this.confirmData(method),
          },
          {
            text: "Cancelar",
            cssClass: "alert_font_cancel",
          },
        ],
      })
      .then((alert) => alert.present());
  }
}
