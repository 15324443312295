import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { ModalController } from '@ionic/angular';

import { environment } from '../../environments/environment';
import { QrcodeScannerPage } from '../pages/qrcode-scanner/qrcode-scanner.page';

@Injectable({
  providedIn: 'root'
})
export class Html5qrcodescannerService {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  /**
   * Abre a página de scan
   * @param options 
   */
  scan(options?: {
    side?: 'front'|'back',
    validation?: Function
  }): Observable<string> {
    // const text$: Subject<string> = new Subject()
    return new Observable(subscriber => {
      this.modalCtrl.create({
        component: QrcodeScannerPage,
        componentProps: {
          side: options.side,
          validation: options.validation || ((text: string) => true)
        },
        backdropDismiss: false,
        id: environment.html5qrcode.modalId,
      }).then((modal) => {
        modal.onDidDismiss().then(dismiss => {
          if(!dismiss.data.error) {
            subscriber.next(dismiss.data.message)
            subscriber.complete()
          } else subscriber.error(dismiss.data.message)
        })
        modal.present()
      })
    })
  }
}
