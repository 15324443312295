import { Injectable } from '@angular/core';

// import { Camera, CameraOptions } from '@ionic-native/camera/ngx'
// import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@ionic-native/camera-preview/ngx'
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CameraPageComponent } from '../shared/components/v2/camera-page/camera-page.component';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    private modalCtrl: ModalController,
  ) { }

  takePicture(): Observable<string> {
    return new Observable<string>(subscriber => {
      // const options: CameraPreviewOptions = {
      //   x: 0,
      //   y: 0,
      //   width: window.screen.width - (window.screen.width / 5),
      //   height: window.screen.height - (window.screen.height / 5),
      //   tapPhoto: true,
      //   previewDrag: true,
      //   // toBack: true,
      //   alpha: 1,
      //   tapFocus: true,
      // }
      this.modalCtrl.create({
        component: CameraPageComponent,
        cssClass: 'transparent modal-fullscreen'
      }).then(modal => {
        modal.onDidDismiss().then(dismiss => subscriber.next(dismiss.data))
        modal.present()
      })
      // this.cameraPreview.startCamera(cameraOptions || options).then(() => {
      //   // const base64Img: string = 'data:image/jpeg;base64,' + imgData
      //   // subscriber.next(base64Img)
      // }, err => subscriber.error(err))
      // this.cameraPreview.
    })

    // this.cameraPreview.setOnPictureTakenHandler().subscribe((result) => {
    //   console.log(result);
    //   // do something with the result
    // });
    
  }
  // constructor(
  //   private camera: Camera
  // ) { }

  // takePicture(cameraOptions?: CameraOptions): Observable<string> {
  //   return new Observable<string>(subscriber => {
  //     const options: CameraOptions = {
  //       quality: 100,
  //       destinationType: this.camera.DestinationType.FILE_URI,
  //       encodingType: this.camera.EncodingType.JPEG,
  //       mediaType: this.camera.MediaType.PICTURE,
  //     }
  
  //     this.camera.getPicture(cameraOptions || options).then((imgData) => {
  //       const base64Img: string = 'data:image/jpeg;base64,' + imgData
  //       subscriber.next(base64Img)
  //     }, err => subscriber.error(err))
  //   })
  // }
}
