import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Plugins } from '@capacitor/core'
const { CameraPreview } = Plugins
import { CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview'

import '@capacitor-community/camera-preview' // WEB
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-camera-page',
  templateUrl: './camera-page.component.html',
  styleUrls: ['./camera-page.component.scss'],
})
export class CameraPageComponent implements OnInit, OnDestroy {
  image: string
  cameraActive: boolean

  constructor(
    protected modalCtrl: ModalController
  ) { }

  ngOnInit() {
    const options: CameraPreviewOptions = {
      parent: 'camera-preview',
      className: 'camera-preview',
      height: document.documentElement.clientHeight - 100,
      width: document.documentElement.clientWidth,
    }
    CameraPreview.start(options)
    this.cameraActive = true
  }

  ngOnDestroy() {
    this.stop()
  }

  stop() {
    CameraPreview.stop().then(() => this.cameraActive = false)
  }

  takePicture() {
    const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
      quality: 100
    };

    CameraPreview.capture(cameraPreviewPictureOptions).then((data: { value: string }) => {
      this.image = data.value
      this.stop()
    })
  }

  flip() {
    CameraPreview.flip()
  }

  tryAgain() {
    this.image = ''
    this.ngOnInit()
  }

  dismiss() {
    this.modalCtrl.dismiss(this.image)
    this.stop()
  }
}
