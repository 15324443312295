import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { ModalController, Platform } from "@ionic/angular";

import { environment } from "../../../environments/environment";
import { Router, UrlSegment } from "@angular/router";
import { RestaurantService } from "src/app/services/restaurant.service";
// const Html5QrcodeScanner = require('html5-qrcode')
/**
 * Classe da lib do qrcode
 */
// import { Html5Qrcode} from 'html5-qrcode'
declare var Html5Qrcode;

/**
 * Objeto retornado da função getCameras
 */
interface CameraDevice {
  id: string;
  label: string;
}

interface DismissData {
  error: boolean;
  message: string;
}

// TODO Verificar uma maneira melhor de checar qual câmera é frontal ou traseira

@Component({
  selector: "app-qrcode-scanner",
  templateUrl: "./qrcode-scanner.page.html",
  styleUrls: ["./qrcode-scanner.page.scss"],
})
export class QrcodeScannerPage implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Não esqueça de desinscrever TODOS os observables e inscrições e de PARAR/DESLIGAR a câmera toda vez que trocar da traseira
   * para a frontal e vice/versa.
   * Na função ngOnDestroy, NÃO retire o comando para parar a câmera.
   * Se todas as câmeras não forem desligadas, elas continuarão ativas, mesmo destruindo o componente.
   * É importante não só para performance mas também para que o usuário sinta-se tranquilo, sem ficar com o dispositivo dizendo
   * que o app está usando a câmera quando não está.
   */

  /**
   * Diz se está como modal ou page
   */
  @Input("asModal") asModal = false;
  /**
   * Qual câmera será priorizada
   */
  @Input("side") side: "front" | "back" = "back";
  /**
   * Validação do texto scanneado no Qr Code
   */
  @Input("validation") validation: Function = (text: string) => true;
  /**
   * Todos os dispositivos de câmeras traseiros encontrados no aparelho
   */
  private backDevices: CameraDevice[] = [];
  /**
   * Todos os dispositivos de câmeras que não são traseiros encontrados no aparelho.
   * Se o label do dispositivo não conter a palavra 'back' ou 'trás', ele será jogado nesse array.
   * Porém não há garantia de que seja a câmera frontal
   */
  private frontDevices: CameraDevice[] = [];
  /**
   * Dispositivos de câmeras verificados
   */
  private checkedDevices: CameraDevice[] = [];
  /**
   * Classe usada para iniciar/parar a câmera
   */
  private camera;
  /**
   * Id do elemento da câmera na DOM
   */
  public qrcodeScannerElementId: string =
    environment.html5qrcode.qrcodeScanElement;
  /**
   * Status atual da leitura e imagem correspondente
   */
  public status$: BehaviorSubject<{
    currentImg: string;
    currentLabel: string;
  }> = new BehaviorSubject({
    currentImg: "stand.cat.gif",
    currentLabel: "Abrindo câmera",
  });

  constructor(
    private modalCtrl: ModalController,
    private platform: Platform,
    private restaurantService: RestaurantService,
    private router: Router
  ) {}

  ngOnInit() {
    const currentNavigation = this.router.getCurrentNavigation();
    const urlSegments: UrlSegment[] =
      currentNavigation?.extractedUrl?.root?.children?.primary?.segments || [];
    const path: string = urlSegments[0]?.path;

    this.asModal = path != environment.routePaths.scanner;
  }

  /**
   * Pega os dispositivos de câmera do aparelho e inicia o scan
   */
  ngAfterViewInit() {
    const getCamera = Html5Qrcode.getCameras();
    getCamera
      .then((devices: CameraDevice[]) => {
        //alert('getCamera' + JSON.stringify(devices)) // comentar leo
        devices.forEach((device) => {
          // alert('labe alterado ' + device.label +'gggggg ' + device.label[0] + device.label[1] + device.label[2] + device.label[3] + device.label.length)
          // alert(device.label + ' - ' + (device.label.includes('back')) + ' tradução ' + device.label.toLowerCase().includes('trás'))
          device.label.includes("back") ||
          device.label.toLowerCase().includes("traseira")
            ? this.backDevices.push(device)
            : this.frontDevices.push(device);
        }); // Facing front e facing back é padrão de câmeras de celulares. No note está como webcam hd, então eu trato tudo que não é traseiro como algo frontal
        // alert('back ' + this.backDevices.length + ' - front ' + this.frontDevices.length)
        this.scan();
      })
      .catch((error) => {
        // alert(error + ' - erro de não abrir a câmera')
        this.status$.next({
          currentImg: "bug.cat.gif",
          currentLabel: "Não foi possível abrir a câmera",
        });
      });
    this.camera = new Html5Qrcode(this.qrcodeScannerElementId);
  }

  /**
   * Desliga a câmera quando o componente está sendo destruído
   */
  ngOnDestroy() {
    this.camera.stop().then(() => console.log("Câmera desligada"));
  }

  /**
   * Tenta renderizar a câmera com um dos dispositivos encontrados
   */
  scan() {
    try {
      const device: CameraDevice =
        this.getDevice(); /*.pipe(retry(this.backDevices.length + this.frontDevices.length))*/
      if (!device) {
        throw "primeira";
      }
      this.camera
        .start(
          this.platform.is("desktop") || this.platform.is("mobileweb")
            ? device.id
            : { facingMode: "environment" },
          { fps: 10 },
          (text) => {
            this.scanned(text);
          },
          (error) => this.notScanned(error)
        )
        .catch((e) => {
          // alert('catch do start' + e)
          this.checkedDevices.push(device);
          if (device.label.includes("back"))
            this.backDevices = this.backDevices.filter(
              (bkDevice) => bkDevice.id != device.id
            );
          else
            this.frontDevices = this.frontDevices.filter(
              (ftDevice) => ftDevice.id != device.id
            );
          // alert('ckDvc: ' + this.checkedDevices.length + ' - bkDvc: ' + this.backDevices.length + ' - ftDvc: ' + this.frontDevices.length)
          this.scan();
        });
    } catch (e) {
      this.status$.next({
        currentImg: "bug.cat.gif",
        currentLabel: "Não foi possível abrir a câmera",
      });
    }
  }

  /**
   * Retorna um dos dispositivos de câmeras baseado nas preferências e no número de tentativas já feitas
   */
  // private getDevice(): Observable<CameraDevice> {
  private getDevice(): CameraDevice {
    if (
      (this.side == "back" || !this.frontDevices.length) &&
      this.backDevices.length
    ) {
      const backDeviceNotChecked = this.backDevices.find(
        (device) =>
          this.checkedDevices.findIndex((dvc) => dvc.id == device.id) == -1
      );
      return backDeviceNotChecked;
    } else if (
      (this.side == "front" || !this.backDevices.length) &&
      this.frontDevices.length
    ) {
      const frontDeviceNotChecked = this.frontDevices.find(
        (device) =>
          this.checkedDevices.findIndex((dvc) => dvc.id == device.id) == -1
      );
      return frontDeviceNotChecked;
    } else return null;
  }

  /**
   * Quando algo for scanneado
   * @param text
   */
  scanned(text: string) {
    // if(text.includes(environment.USER_GUID)) {
    if (this.validation?.(text)) {
      // TODO Insistir com o Xandão para que haja a verificação de cima
      this.status$.next({
        currentImg: "ihul.cat.gif",
        currentLabel: "QR Code encontrado!",
      });

      const rest = this.restaurantService.getRestaurant();
      if (rest) {
        const str = text.replace(environment.USER_GUID, "");
        this.modalCtrl.dismiss(
          { error: false, message: str },
          undefined,
          environment.html5qrcode.modalId
        );
      } else {
        if (text.includes("http")) {
          text = text.replace(environment.qrcodeConfigValidationLink, "");
          this.router.navigateByUrl(text);
        }
      }
    } else {
      this.status$.next({
        currentImg: "perdido.cat.gif",
        currentLabel: "QR Code inválido",
      });
    }
  }

  /**
   * Quando não for encontrado nada
   * @param error
   */
  notScanned(error) {
    // console.log(error)
    const rest = this.restaurantService.getRestaurant();
    this.status$.next({
      currentImg: "bored.cat.gif",
      currentLabel: !!rest
        ? "Procurando QR Code da COMANDA"
        : "Procurando QR Code do CARDÁPIO",
    });
  }

  /**
   * Troca a preferência de câmera
   */
  toggleCamera() {
    this.status$.next({
      currentImg: "stand.cat.gif",
      currentLabel: "Abrindo câmera",
    });
    // alert(JSON.stringify(this.backDevices) + ' |||| ' + JSON.stringify(this.frontDevices))
    this.camera.stop().then(() => {
      this.side = this.side == "back" ? "front" : "back";
      this.ngAfterViewInit();
    });
  }

  /**
   * Fecha este modal
   */
  closePage() {
    this.modalCtrl.dismiss(
      { error: true, message: "Nenhum QR Code scaneado" },
      undefined,
      environment.html5qrcode.modalId
    );
  }
}
