import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { CheckDetailsComponent } from './check-details.component';

@NgModule({
  declarations: [CheckDetailsComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
})
export class CheckDetailsModule { }
