import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  public images: string[] = []

  constructor(
    private storage: StorageService
  ) { }

  include(img64: string) {
    this.storage.push(environment.storage.v2.gallery, img64).subscribe({
      next: () => this.images.push(img64)
    })
  }

  remove(picture: string) {
    this.storage.filter(environment.storage.v2.gallery, (pic) => pic != picture).subscribe({
      next: () => this.refreshImages()
    })
  }

  init() {
    this.refreshImages()
  }

  private refreshImages() {
    this.storage.getV2<string[]>(environment.storage.v2.gallery).subscribe({
      next: images => this.images = images || []
    })
  }
}
