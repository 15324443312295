import { ModalController, ToastController } from "@ionic/angular";
import { Component, OnInit, Input } from "@angular/core";
import { ProductService } from "src/app/services/product.service";
import { PictureViewerComponent } from "../picture-viewer/picture-viewer.component";
import {
  ProductOption,
  ProductOptionType,
} from "src/app/interfaces/product-option.interface";
import { ItemCart } from "src/app/interfaces/itemCart.interface";
import { Utils } from "src/app/shared/util";

@Component({
  selector: "app-v2-item-complementosmeiomeio",
  templateUrl: "./v2-item-complementosmeiomeio.page.html",
  styleUrls: ["./v2-item-complementosmeiomeio.page.scss"],
})
export class V2ItemComplementosmeiomeioPage implements OnInit {
  @Input() half: any;
  @Input() item: ItemCart;
  complements: any;

  itemVerification: any;
  complementsSubTotal: number = 0;

  buttonAvailability: boolean = false;

  constructor(
    private productService: ProductService,
    private modalController: ModalController,
    private toastController: ToastController,
    public utils: Utils
  ) {}

  ngOnInit() {
    this.productService
      .getProductsHalfComplements(this.half.produtoId)
      .subscribe(
        (res: any) => {
          res = res.results;
          this.complements = res;

          this.processButtonAvailability();
          this.subTotal();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  // Checa se o complemento está incluído
  hasOption(optionId: string) {
    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );

    return (
      this.item.meiomeios[halfIndex].complementos.findIndex(
        (option) => option.pedidoItemOpcaoId == optionId
      ) != -1
    );
    // return false
  }

  /**
   * Mostra a quantidade de opções selecionadas naquela categoria de opções
   * @param complementId
   */
  getHalfsComplementsQuantity(complementId?: number): number {
    let i = 0;

    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );

    this.item.meiomeios[halfIndex].complementos.forEach((item) => {
      item.produtosOpcaoTipoId === complementId && (i += item.quantidade || 0);
    });

    return i;
  }

  removeOption(
    option: ProductOption,
    optionType: ProductOptionType,
    event?: MouseEvent
  ) {
    if (event) event.stopPropagation();

    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );

    this.item.meiomeios[halfIndex].CartComplementosMeioMeios.forEach(
      (complemento, index) => {
        if (
          complemento.complemento === option.nome &&
          complemento.Quantidade === 1
        ) {
          this.item.meiomeios[halfIndex].CartComplementosMeioMeios.splice(
            index,
            1
          );
        } else if (complemento.complemento === option.nome) {
          this.item.meiomeios[halfIndex].CartComplementosMeioMeios[index]
            .Quantidade--;
        }
      }
    );

    let ComplementosMeioMeiosArr = [];

    this.item.meiomeios[halfIndex].complementos.forEach(
      (complemento, index) => {
        if (complemento.pedidoItemOpcaoId === option.produtosOpcaoId) {
          ComplementosMeioMeiosArr.push(index);
        }
      }
    );

    this.item.meiomeios[halfIndex].complementos.splice(
      ComplementosMeioMeiosArr.reverse()[0],
      1
    );
  }

  addOption(
    option: ProductOption,
    optionType: ProductOptionType,
    event?: MouseEvent
  ) {
    if (event) event.stopPropagation();
    const qtd = this.getHalfsComplementsQuantity(
      optionType.produtosOpcaoTipoId
    );

    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );
    let itemIndex = this.item.meiomeios.length - 1;
    let itemComplementIndex =
      this.item.meiomeios[halfIndex].complementos.length;

    if (qtd < optionType.qtdeMax) {
      this.item.meiomeios[halfIndex].complementos.push({
        i: itemIndex,
        i2: itemComplementIndex,
        nome: option.nome,
        pedidoItemOpcaoId: option.produtosOpcaoId,
        produtoDesc: this.half.nome,
        produtoPdv: option["produtoPdv"],
        produtosOpcaoTipoId: optionType.produtosOpcaoTipoId,
        tipo: optionType.tipo,
        valor: option.valor,
        quantidade: 1,
      });
      const optionIndex = this.item.meiomeios[
        halfIndex
      ].CartComplementosMeioMeios.findIndex(
        (op) => op.complemento == option.nome
      );
      if (optionIndex == -1)
        (option.quantidade = 1) &&
          this.item.meiomeios[halfIndex].CartComplementosMeioMeios.push({
            Quantidade: 1,
            complemento: option.nome,
            valor: option.valor,
          });
      else
        this.item.meiomeios[halfIndex].CartComplementosMeioMeios[optionIndex]
          .Quantidade++;
    }
  }

  /**
   * Mostra a quantidade da opção selecionada
   */
  getOptionQtd(opt: ProductOption, optionId: string | number) {
    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );

    const optionsIndex = this.item.meiomeios[
      halfIndex
    ].CartComplementosMeioMeios.findIndex(
      (option) => option.complemento == opt.nome
    );

    const option =
      this.item.meiomeios[halfIndex].CartComplementosMeioMeios[optionsIndex];
    return option?.Quantidade || 0;
  }

  subTotal() {
    let subTotal: number = 0;

    this.item.meiomeios.forEach((item, halfIndex) => {
      let meioMeio = this.item.meiomeios[halfIndex];
      meioMeio.complementos.forEach(
        (item) => (subTotal = subTotal + item.valor)
      );
    });

    const maxHalf = this.item.meiomeios.reduce(function (prev, current) {
      return prev.valorRegular > current.valorRegular ? prev : current;
    });

    this.item.meiomeios[
      this.item.meiomeios.findIndex(
        (item) =>
          item.produtoGuid === maxHalf.produtoGuid &&
          item.produtoId === maxHalf.produtoId
      )
    ].valorVendaComplemento =
      this.item.meiomeios[
        this.item.meiomeios.findIndex(
          (item) =>
            item.produtoGuid === maxHalf.produtoGuid &&
            item.produtoId === maxHalf.produtoId
        )
      ].valorRegular + subTotal;
    this.complementsSubTotal = subTotal;

    this.processButtonAvailability();
  }

  processButtonAvailability() {
    let required: boolean = false;

    this.complements.forEach((complement) => {
      if (complement.obrigatorio) {
        let halfIndex = this.item.meiomeios.findIndex(
          (item) => item.produtoId === this.half.produtoId
        );

        let index = this.item.meiomeios[halfIndex].complementos.findIndex(
          (k) => k.produtosOpcaoTipoId === complement.produtosOpcaoTipoId
        );

        index !== -1 ? (required = false) : (required = true);
      }
    });

    this.buttonAvailability = required;
  }

  /**
   * Troca as quantidades de opções
   * @obs A lógica tá bem chatinha de entender, então cuidado na hora de mexer
   * @param selectedOption
   * @param optionType
   */
  toggleOption(selectedOption: ProductOption, optionType: ProductOptionType) {
    const qtd = this.getHalfsComplementsQuantity(
      optionType.produtosOpcaoTipoId
    );

    let halfIndex = this.item.meiomeios.findIndex(
      (item) => item.produtoId === this.half.produtoId
    );
    let itemIndex = this.item.meiomeios.length - 1;
    let itemComplementIndex =
      this.item.meiomeios[halfIndex].complementos.length;

    if (qtd < optionType.qtdeMax) {
      this.item.meiomeios[halfIndex].complementos.push({
        i: itemIndex,
        i2: itemComplementIndex,
        nome: selectedOption.nome,
        pedidoItemOpcaoId: selectedOption.produtosOpcaoId,
        produtoDesc: this.half.nome,
        produtoPdv: selectedOption["produtoPdv"],
        produtosOpcaoTipoId: optionType.produtosOpcaoTipoId,
        tipo: optionType.tipo,
        valor: selectedOption.valor,
        quantidade: 1,
      });
      const optionIndex = this.item.meiomeios[
        halfIndex
      ].CartComplementosMeioMeios.findIndex(
        (op) => op.complemento == selectedOption.nome
      );
      if (optionIndex == -1)
        (selectedOption.quantidade = 1) &&
          this.item.meiomeios[halfIndex].CartComplementosMeioMeios.push({
            Quantidade: 1,
            complemento: selectedOption.nome,
            valor: selectedOption.valor,
          });
      else
        this.item.meiomeios[halfIndex].CartComplementosMeioMeios[optionIndex]
          .Quantidade++;
    } else if (qtd == optionType.qtdeMax && optionType.tipo === 1) {
      let halfIndex = this.item.meiomeios.findIndex(
        (item) => item.produtoId === this.half.produtoId
      );

      let CartComplementosMeioMeios = null;

      this.complements.forEach((complement) => {
        if (
          complement.produtosOpcaoTipoId === selectedOption.produtosOpcaoTipoId
        ) {
          complement.produtoOpcaos.forEach((produto) => {
            CartComplementosMeioMeios = this.item.meiomeios[
              halfIndex
            ].CartComplementosMeioMeios.filter(
              (k) => k.complemento !== produto.nome
            );

            CartComplementosMeioMeios
              ? (this.item.meiomeios[halfIndex].CartComplementosMeioMeios =
                  CartComplementosMeioMeios)
              : "";
          });
        }
      });

      let ComplementosMeioMeiosArr = [];

      this.item.meiomeios[halfIndex].complementos.forEach(
        (complemento, index) => {
          if (
            complemento.pedidoItemOpcaoId === selectedOption.produtosOpcaoId
          ) {
            ComplementosMeioMeiosArr.push(index);
          }
        }
      );

      this.item.meiomeios[halfIndex].complementos.splice(
        ComplementosMeioMeiosArr.reverse()[0],
        1
      );

      this.toggleOption(selectedOption, optionType);
    }
  }

  // Adicionar e remover produtos do item
  changeNumber(operation: "add" | "remove") {
    this.item.number
      ? operation == "add"
        ? this.item.number++
        : this.item.number--
      : (this.item.number = 1);
  }

  /**
   * Salva as configurações dos complementos
   * @param item
   */
  addToCart() {
    console.log(this.item);

    this.closeItemDetailsMeioMeio();
    /* let complements1: any[] = [];
    let complements2: any[] = [];

    this.item.cartItemOpcoes.forEach((item, index) => {
      complements1.push({
        Quantidade: item.quantidade,
        complemento: item.nome,
        valor: item.valor,
      });

      complements2.push({
        i2: index,
        nome: item.nome,
        pedidoItemOpcaoId: item.produtosOpcaoId,
        produtoDesc: this.half.nome,
        produtoPdv: "",
        produtosOpcaoTipoId: item.produtosOpcaoTipoId,
        tipo: 1,
        valor: item.valor,
      });
    });

    this.half = { ...this.half, complements1, complements2 };

    console.log(complements1, complements2, this.half); */

    /* this.item.cartItemOpcoes.forEach((item)=>{
      this.half = {...this.half, CartComplementosMeioMeios:[]} }
    })
    
    console.log(this.item, this.half); */

    /* this.item.observacao = this.obs;
    console.log(this.obs); */
    /* const sub_options = this.options$.subscribe((options) => {
      let error: boolean; // Se existir algum erro nas validações, deve ser passado true para essa variável

      // Checagem de opcionais
      for (const option of options) {
        const qtd = this.getQtdSelected(option.produtosOpcaoTipoId);

        if (option.obrigatorio && option.quantidade > qtd) {
          this.alertCtrl
            .create({
              header: "Ops! Faltou algo",
              message: `A categoria ${option.nome} deve ter ${option.quantidade} escolhidos`,
            })
            .then((alert) => alert.present());
          error = true;
          break;
        } else if (option.qtdeMax < qtd) {
          this.alertCtrl
            .create({
              header: "Ops!",
              message: `A categoria ${option.nome} deve ter no máximo ${option.qtdeMax} escolhidos`,
            })
            .then((alert) => alert.present());
          error = true;
          break;
        }
      }
      sub_options.unsubscribe();

      // Checagem de meio a meios
      if (this.item.produto.numberMeiomeio > 1)
        if (this.item.meiomeios.length < 2) {
          this.alertCtrl
            .create({
              header: "Ops! Faltou algo",
              message: `Faltam ${
                this.item.produto.numberMeiomeio - this.item.meiomeios.length
              } sabores para serem escolhidos`,
            })
            .then((alert) => alert.present());
          error = true;
        }

      // Caso não haja erros, adicionará o item no carrinho
      if (!error) {
        if (this.mode == "new" || this.replay) {
          this.item.observacao = this.obs;
          this.cart.setItem(item);
          this.utils.animate("shaking adding", "cart-icon", "class", 900);
          this.toCallOrder();
        } else if (this.mode == "edit") this.cart.editItem(item);
        this.closeItemDetails();
        console.log("editado:", item);
      }
    }); */
  }

  callImageModal(img: string) {
    this.modalController
      .create({
        component: PictureViewerComponent,
        componentProps: {
          img,
        },
      })
      .then((modal) => modal.present());
  }

  closeItemDetailsMeioMeio() {
    if (!this.buttonAvailability) {
      this.modalController.dismiss();
    } else {
      this.toastController
        .create({
          message:
            "Não é possivel retornar no momento! Selecione o complemento obrigatório",
          duration: 3000,
          color: "danger",
        })
        .then((toast) => {
          toast.present();
        });
    }
  }

  karmatests() {
    console.log(
      this.item.meiomeios[
        this.item.meiomeios.findIndex(
          (k) => k.produtoId === this.half.produtoId
        )
      ]
    );
  }
}
