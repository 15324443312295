import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-dialog-scanner-page',
  templateUrl: './dialog-scanner-page.component.html',
  styleUrls: ['./dialog-scanner-page.component.scss'],
})
export class DialogScannerPageComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  dismiss(openQrCodePage: boolean = false) {
    this.modalCtrl.dismiss({
      confirm: openQrCodePage
    })
  }

}
