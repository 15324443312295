import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalController } from '@ionic/angular';

import { UserService } from '../../services/user.service';
import { RestaurantService } from '../../services/restaurant.service';
import { AuthService } from '../../shared/auth.service';
import { Utils } from '../../shared/util';

// PÁGINA DE LOGIN DE USUÁRIO
@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  asRestaurant: boolean = false;
  isLogin: boolean = true;
  fb: FormBuilder = new FormBuilder();
  form: FormGroup;

  constructor(
    private auth: AuthService,
    private modalController: ModalController,
    public restaurantService: RestaurantService,
    private userService: UserService,
    public utils: Utils,
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  ionViewDidEnter() {
  }

  // Monta o formulário
  buildForm() {
    this.form = this.fb.group({
      email: [null],
      nome: [null],
      sobrenome: [null],
      senha: [null],
    })
  }

  closeModal() {
    this.modalController.dismiss();
  }

  registerUser() {
    this.userService.create(this.form.value).subscribe(data => {
      // if(!data?.errors) {
      this.utils.showToast({ message: data.message })
      if(!data?.errors) {
        this.lin(this.form.value.email, this.form.value.senha, 'user');
      }
      // } else {
      //   console.error(data.message)
      //   this.toastController.create({
      //     message: 'Ops! Tivemos um problema :c',
      //     duration: 1000
      //   }).then(toast => toast.present())
      // }
    })
  }

  // Chama o serviço de autenticação para cliente ou restaurante
  lin(login: string, password: string, mode?: 'user'|'restaurant') {
    if(!this.asRestaurant) {
      this.auth.login(login, password, mode || 'user');
    } else {
      this.auth.login(login.replace(/[^0-9]/gm, ''), password, mode || 'restaurant');
    }
  }

  // maskInput(element: IonInput, mode) {
  //   if(mode !='waiterPassword') {
  //     element.value = this.utils.convert(element.value, mode);
  //   } else {
  //     element.value = this.utils.convert(element.value, 'onlyNumber').substr(0, 4);
  //   }
  // }
}
