import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalController, Platform } from '@ionic/angular';

import { SetDetailsComponent } from '../set-details/set-details.component';
import { StorageService } from '../../../services/storage.service';
import { ZimmerStorage } from '../../../models/zimmerStorage.model';
import { Utils } from '../../util';

@Component({
  selector: 'app-check-details',
  templateUrl: './check-details.component.html',
  styleUrls: ['./check-details.component.scss'],
})
export class CheckDetailsComponent {

  /**
   * Verifica se está rodando no navegador
   */
  web: boolean

  constructor(
    private modalCtrl: ModalController,
    platform: Platform,
    private router: Router,
    private storage: StorageService,
    public utils: Utils
  ) {
    this. web = platform.is('mobileweb') || platform.is('desktop')
  }

  /**
   * Escolhe um modo a ser utilizado
   * @param option 'qrcode'|'credentials'
   */
  choose(option: 'qrcode'|'credentials') {
    let tableNum
    if(option == 'qrcode') {
      return this.utils.scanQr().then(response => {
        const parameters = response.text.includes('http') ? response.text.replace('//', '').split('/') : response.text.split('/')

        const cnpj = parameters[2]
        const table = parameters[parameters.length - 2]
        const tab = parameters[parameters.length - 1]
        const zimmer = new Object() as ZimmerStorage
        zimmer.cnpj = cnpj
        zimmer.mode = parameters[1] as any
        zimmer.phoneNumber = zimmer.mode == 'zap' ? Number(table) : undefined

        if(table) zimmer.tableNumber = Number(table)
        if(tab) zimmer.preferences.categoryProdCardStyle = tab as any // TODO Retirar as any quando ajustar o nome doos campos
        tableNum = zimmer.tableNumber

        this.storage.prepare(zimmer)
        this.router.navigate(['home'])

        return true
      }).catch(error => {
        // this.alertCtrl.create({
        //   header: 'Error scanner',
        //   message: JSON.stringify(error)
        // }).then(alert => alert.present())
        return false
      }).then(success => !success || this.modalCtrl.dismiss(tableNum))
    } else {
      return this.modalCtrl.create({
        component: SetDetailsComponent,
        backdropDismiss: false
      }).then(modal => modal.present().then(() => modal.onDidDismiss().then(data => data.data?.incomplete || this.modalCtrl.dismiss().then(() => this.modalCtrl.dismiss()))))
    }
  }

}
