import { BaseModel } from '../base-class.model'

export class Card  extends BaseModel<Card> {
    num: number
    cvv: string
    monthExp: number|string
    yearExp: number|string
    brand?: string
    token?: string
    titular: string
    cpf: number
    checked?: boolean
    userId?: string
}