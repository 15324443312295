import { BaseModel } from "./base-class.model";
import { Cart } from "./cart/cart.model";
import { UserFavorite } from "../interfaces/user-favorite.interface";

export class ZimmerStorage extends BaseModel<ZimmerStorage> {
  // user: User;
  /**
   * @deprecated
   */
  userFavorites: UserFavorite[];
  /**
   * @deprecated
   */
  orders: Cart[];
  /**
   * Número da mesa
   */
  tableNumber: number;
  /**
   * Cnpj da empresa
   */
  cnpj: string;
  /**
   * Modo em que o app será executado
   */
  mode: "zap" | "pedido" | "qrcode" | "menu" | "ficha" | "gestor";
  /**
   * Número de telefone da empresa reserva. Só é usado na rota 'zap'
   * Só será utilizado caso o restaurante não tenha um número de telefone registrado em nosso DB
   */
  phoneNumber: number;
  /**
   * Preferências para a estilização do app
   */
  preferences: {
    /**
     * Estilo do card de produtos na página de categorias
     */
    categoryProdCardStyle?: number;
  };

  constructor(value?: ZimmerStorage) {
    super(value);
    if (!this.preferences) this.preferences = {};
  }
}
