import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { ModalController, Platform } from '@ionic/angular';

import { environment } from '../../../../../environments/environment';
import { Utils } from '../../../../shared/util';
import { Localizacao } from '../../../../models/address/localizacao.model';
import { MaskService } from '../../../../services/providers/mask.service';
import { AddressService } from '../../../../services/providers/address.service';

/**
 * LeafletJS
 */
declare const L

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.page.html',
  styleUrls: ['./add-address.page.scss'],
})
export class AddAddressPage implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Usado somente para receber o valor do componente que está instanciando essa classe. *Não* use para outra coisa
   * @ignore
   */
  @Input('address') address: Localizacao
  // address$: Observable<Address>
  private subs: Subscription[] = []
  /**
   * Diz se mostra ou não todos os campos de endereço da DOM
   */
  public showAllInfo: boolean
  form: FormGroup
  web: boolean
  delayToSearchPosition
  /**
   * Mapa renderizado na DOM
   */
  private map
  public locating: boolean
  private marker

  constructor(
    private addressService: AddressService,
    private formBuilder: FormBuilder,
    public mask: MaskService,
    private modalCtrl: ModalController,
    private utils: Utils,
    platform: Platform
  ) {
    this.web = platform.is('mobileweb') || platform.is('desktop')
  }

  /**
   * Pega o valor do endereço salvo
   */
  ngOnInit() {
    this.form = this.formBuilder.group({
      cep: [null],
      logradouro: [null, [Validators.required, Validators.minLength(1)]],
      numero: [null, [Validators.required, Validators.minLength(1)]],
      complemento: [null],
      bairro: [null],
      localidade: [null],
      uf: [null],
    })

    this.form.patchValue(this.address || {})
    if(this.address) this.showAllInfo = true

    this.subs.push(this.form.valueChanges.subscribe({
      next: value => this.address = new Localizacao(value)
    }))
  }

  /**
   * Limpa a inscrição para evitar memory leak
   */
  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe())
  }

  /**
   * Renderiza o mapa
   */
  ngAfterViewInit() {
    if(!this.web) {
      this.map = L.map('map-user').setView([48.8566, 2.3515], 11)
      const jawg_streets = L.tileLayer(
        'https://tile.jawg.io/jawg-streets/{z}/{x}/{y}.png?access-token=f3maPN2oqrnPi0ODiFM2HiVTSK2QjKIsbAvQpn51T0OaF47TkuhFWJqQeSHdKOCw',
        { }).addTo(this.map)
      this.map.attributionControl.addAttribution("<a href=\"https://www.jawg.io\" target=\"_blank\">&copy; Jawg</a> - <a href=\"https://www.openstreetmap.org\" target=\"_blank\">&copy; OpenStreetMap</a>&nbsp;contributors")
    }
    
  }

  /**
   * Fecha o modal e devolve o endereço
   */
  dismiss(data?: Localizacao) {
    this.modalCtrl.dismiss({
      success: !!data,
      address: data
    }, undefined, environment.addAddressModalId)
  }

  /**
   * Procura a localização atual do usuário por componentes nativos
   */
  locateUser() {
    this.locating = true
    this.addressService.locateUser().subscribe(address => {
      this.form.patchValue(address)
      this.showAllInfo = true
      this.locating = false
    })
  }

  /**
   * Procura as coordenadas para que seja renderizado no mapa
   * @param address 
   */
  searchLocation(address: Localizacao) {
    if(this.web) return // Não deve procurar no mapa porque não vou renderizar no mapa pela web... ainda
    if(this.delayToSearchPosition) clearTimeout(this.delayToSearchPosition)
    this.delayToSearchPosition = setTimeout(() => {
      if(Localizacao.isValid(address)) {
        // TODO Pesquisar cep caso não tenha sido digitado
        const sub_address = this.addressService.getCoordinatesFromAddress(
          `${address.logradouro ? (address.logradouro + ', ') : ''}
          ${address.numero ? (address.numero + ' - ') : ''}
          ${address.bairro ? (address.bairro + ', ') : ''}
          ${address.localidade} - ${address.uf}, ${this.address.cep}, Brazil`
        ).subscribe(addressResult => {
          const setView = this.map.setView([addressResult.latitude, addressResult.longitude], 17)
          if(this.marker) this.marker.removeFrom(this.map)
          this.marker = L.marker([addressResult.latitude, addressResult.longitude]).addTo(this.map)
          // const markers = L.markerClusterGroup()
          // markers.clearLayers()
          sub_address.unsubscribe()
        }) // O segundo parâmetro no setView é o zoom no mapa
      }
    }, 800)
  }

  /**
   * Procura o endereço pelo CEP
   * @param cep 
   */
  getAddressFromCep(cep: string): void {
    this.utils.setStateLoading(true)
    const validCep = cep.replace(/[^0-9]/gm, '')
    this.addressService.getAddressFromCep(validCep)
      .subscribe({ next: data => {
          this.form.patchValue(new Localizacao(data))
          this.showAllInfo = true
          this.utils.setStateLoading(false)

          this.searchLocation(this.address)
        }, error: error => {
          // this.utils.showToaster('Ops! algo deu errado')
          this.utils.setStateLoading(false)
        }
      })
  }

  /**
   * Retorna o endereço para o component pai
   */
  saveAddress(): void {
    const validation = Localizacao.isValid(this.address)
    if(!environment.production) console.log('Address is valid?', validation.isValid)
    if(!environment.production && validation.errors?.length > 0) console.log('Invalid fields', validation.errors)
    if(validation.isValid) {
      this.dismiss(this.address)
    } else validation.errors.forEach(error => this.utils.showToast({
      message: error
    }))
  }

  /**
   * Checa se o endereço é válido
   * @param address 
   */
  isValidAddress(address: Localizacao) {
    return Localizacao.isValid(address).isValid
  }
}
