import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from '../shared/base.service';
import { Category } from '../interfaces/category.interface';
import { CartService } from './cart.service';
import { StorageService } from './storage.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends BaseService<Category> {

  /**
   * Categorias do restaurante atual
   */
  private categories: Category[] = []

  constructor(
    protected http: HttpClient,
    protected cart: CartService,
    private storage: StorageService,
  ) {
    super(http, 'category')
  }

  // setCategory(cat: Category) {
  //   this.category = cat;
  // }

  /**
   * Insere as categorias no service
   */
  setCategories(cats: Category[]): void {
    this.categories = cats;
  }

  /**
   * @returns Category[]
   */
  getCategories(): Category[] {
    return this.categories || [];
  }

  /**
   * Category
   * @param id 
   */
  getCategoryById(id): Category {
    return this.categories?.find(c => c.categoriaId == id)
  }

  /**
   * Verifica qual estilo de lista de itens será aplicado
   */
  checkItemStyle(): string|number {
    return this.storage.get<{ categoryProdCardStyle: number }>(environment.storage.preferences).categoryProdCardStyle
  }

}
