import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';

import { IonInput, ModalController, AlertController } from '@ionic/angular';

import { UserService } from '../services/user.service';
import { RestaurantService } from '../services/restaurant.service';
import { User } from '../models/user.model';
import { Restaurant } from '../models/restaurant.model';
import { Product } from '../models/produto.model';
import { StorageService } from '../services/storage.service';
import { UserFavorite } from '../interfaces/user-favorite.interface';
import { Utils } from './util';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { flatMap, map, take, tap } from 'rxjs/operators';
import { ConfigurationService } from '../services/configuration.service';

export const httpOptions: { headers: HttpHeaders } = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    'Accept': 'application/json, text/plain',
    'cache-control': 'no-cache',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT'
  })
}

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token: string;
  private user: User;
  private userRestaurant: Restaurant;
  private passwordAttempts: number = 0;
  private tableNumber$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private restaurantService: RestaurantService,
    private router: Router,
    private storage: StorageService,
    private userService: UserService,
    public utils: Utils
  ) {
    
    // config.getConfigV2().pipe(flatMap(
    //   configs => this.storage.saveV2(environment.storage.v2.tableNumber, configs.reference).pipe(map(() => configs.reference)
    // )), tap(ref => console.log(ref, 'changing ref'))).subscribe({
    //   next: reference => this.tableNumber$.next(reference)
    // })
  }

  init(): Observable<void> {

    return this.storage.getV2<string>(environment.storage.v2.tableNumber).pipe(flatMap(
      reference => {
        this.tableNumber$.next(reference)        
        return this.storage.saveV2(environment.storage.v2.tableNumber, reference)
      }
    ), take(1), tap(() => console.log('Termina de salvar no storage o tableNumber', environment.storage.v2.tableNumber)))
  }

  /**
   * Login no sistema - está parte do projeto está parada
   * @param { string } login Cnpj ou email do usuário
   * @param { string } password
   * @param { string } as 'user' para login do cliente final e 'restaurant' para estabelecimentos
   */
  login(login: string, password: string, as: 'user'|'restaurant') {
    switch (as) {
      case 'user':
        this.userService.login(login, password).subscribe(data => {
          if(data.authenticated) {
            this.user = data.usuario;
            this.token = data.accessToken;

            this.utils.showToast({ message: 'Bem vindo ' + this.user.nome, position: 'middle' })
            this.modalCtrl.dismiss();

          } else {
            this.utils.showToast({ message: data.message })
          }
        })
        break;

      case 'restaurant':
        this.restaurantService.login(login, password).subscribe((data: { restaurant: Restaurant, token: string }) => {
          if(data?.restaurant) {
            this.userRestaurant = data?.restaurant;
            this.restaurantService.setRestaurant(this.userRestaurant);

            // Caso a troca de mesas esteja bloqueada por errar a senha do garçom muitas vezes, ela será liberada aqui
            this.storage.save('tableChangePermitted', true)

            this.router.navigate(['restaurant', this.userRestaurant?.cnpj, 'edit'])
            this.modalCtrl.dismiss();
          } else {
            this.utils.showToast({ message: 'Falha no login' })
          }
        })
        break;

      default:
        break;
    }
  }

  /**
   * Injeta um estabelecimento no serciço
   * @param { Restaurant } restaurant Restaurante a ser injetado no serviço
   * @param token Token a ser injetado. Caso nada seja passado, ele pegará do restaurante passado
   */
  setUserRestaurante(restaurant: Restaurant, token?: string) {
    this.userRestaurant = restaurant;
    this.token = token || restaurant.token;
    this.restaurantService.setRestaurant(restaurant);
  }

  /**
   * Remove os usuários logados e o token do serviço e os dados salvos do app
   */
  logout(): void {
    this.user = null;
    this.userRestaurant = null;
    this.token = null;
    localStorage.removeItem('zimmer');
  }

  /**
   * 
   * @param { string } password
   * @param { string } as
   * @returns { boolean } True se a senha bater, false caso não
   */
  checkPassword(password: string, as: 'user'|'restaurant'): boolean {
    // Comparando as senhas com os usuários armazennados no serviço
    switch (as) {
      case 'user':
        return this.user.senha == password;

      case 'restaurant':
        return this.userRestaurant.senhaMaster == password

      default:
        throw false;
    }

  }

  // USER

  /**
   * @returns Cliente final logado
   */
  getUser(): User {
    return this.user
  }

  /**
   * Injeta no service o usuário
   * @param { User } user
   */
  setUser(user: User): void {
    if(!this.user) {
      this.user = user;
    } else {
      const keys = Object.keys(user);
      keys.forEach(key => {
        this.user[key] = user[key];
      })
    }
  }

  /**
   * @returns Produtos favoritados
   */
  getUserFavorites(): UserFavorite[] {
    return this.storage.get('userFavorites') || [];
  }

  /**
   * Revisar necessidade de função
   * @param userFavs UserFavorite[]
   */
  setUserFavorites(userFavs: UserFavorite[]): void {
    this.storage.save('userFavorites', userFavs);
  }

  /**
   * Inserção de novo favorito
   * @param { Product } prod 
   */
  setUserFavorite(prod: Product): void {
    const userFavorites = this.storage.get('userFavorites');
    userFavorites.push({ userId: this.user.userID, produtoId: prod.produtoId, produto: prod });
    this.storage.save('userFavorites', userFavorites)
  }

  /**
   * Remoção de produto de favoritos
   * @param { Product } prod
   */
  removeFromUserFavorites(prod: Product): void {
    const userFavorites = this.storage.get('userFavorites')
    const newUserFavorites = userFavorites?.filter(fav => fav.produtoId != prod.produtoId)
    this.storage.save('userFavorites', newUserFavorites);
  }

  // USER RES

  /**
   * @returns Estabelecimento logado
   */
  getUserRestaurant(): Restaurant {
    return this.userRestaurant
  }

  /**
   * @returns número da mesa salvo no storage
   */
  getTable(): number {
    return this.storage.get('tableNumber')
  }

  /**
   * 
   * @returns Observable com o número da mesa
   */
  getTableV2(): Observable<string> {
    return this.tableNumber$.asObservable()
  }

  /**
   * Dispara um alert para alterar o número da mesa através da senha do garçom
   * Revisar localização de código(talvez fosse melhor tal método estar em um component e não no service)
   */
  setTable(): void {

    // Checa se está permitido mudar o número da mesa
    if(this.storage.get('tableChangePermitted') !== false) {
      // Mostra o alert com os inputs de número e senha
      this.alertCtrl.create({
        header: 'Mesa atual :' + this.getTable(),
        message: 'Tentativas restantes: '+ (3 - this.passwordAttempts),
        inputs: [
          { type: 'number', placeholder: 'Mesa', id: 'mesa-id', min: 1 },
          { type: 'password', placeholder: 'Senha', id: 'table-password' }
        ],
        buttons: [
          { text: 'CONFIRMAR', handler: () => this.changeTable() }
        ]
      }).then(alert => alert.present());
    } else {
      // Caso não esteja permitido trocar de mesa, mostra um alert
      this.blockTableChange();
    }
  }

  /**
   * Troca o número da mesa. Caso seja passado, será setado automaticamente, se não chamará a função setTable
   * @param { number } tableNumber Número da mesa
   */
  changeTable(tableNumber?: number): void {
    // Checa se foi passado um número pra mesa
    if(!tableNumber) {
      // Se não, checa se está permitido mudar
      if(this.storage.get('tableChangePermitted') !== false) {
        const mesaId = document.getElementById('mesa-id') as unknown as IonInput;
        const password = document.getElementById('table-password') as unknown as IonInput;
        // Se sim, checa a senha do garçom com a digitada no alert de SetTable()
        if(this.checkTablePassword(password.value as number)) {
          if((mesaId.value + '').length > 0) {
            this.storage.save('tableNumber', mesaId.value as number);
            this.tableNumber$.next(mesaId.value as string) // TODO Mudar todos os  usos do número da mesa para observable
            this.passwordAttempts = 0;
          } else {
            this.utils.showToast({ message: 'Número de mesa inválido', duration: 1000 })
            this.setTable()
          }
        } else {
          // Se diferentes, checa o número de tentativas já feitas
          if(2 - this.passwordAttempts > 0) {
            this.passwordAttempts++;
            this.setTable()
            this.alertCtrl.dismiss()
          // Se maior que 3, mostra um alert
          } else this.blockTableChange()
        }
      } else this.blockTableChange()
    } else {
      // Se foi passado um número, ele muda a mesa
      this.storage.save('tableNumber', tableNumber)
      this.tableNumber$.next(tableNumber + '')
    }
  }

  /**
   * Checa a senha do garçom salva no storage
   * @param { number } password
   */
  checkTablePassword(password: number): boolean {
    const tablePassword = this.storage.get('tablePassword');
    return tablePassword == password
  }

  /**
   * Bloqueia a troca de mesa e emite um alerta
   */
  blockTableChange(): void {
    this.storage.save('tableChangePermitted', false)
    this.alertCtrl.create({
      header: 'Troca de mesa bloqueada',
      buttons: [
        { text: 'FECHAR' }
      ]
    }).then(alert => alert.present());
  }
}