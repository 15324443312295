import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// import * as M from 'materialize-css';

// import { BaseModel } from './models/base.model';
import { environment } from '../../environments/environment';
import { CartService } from '../services/cart.service';

export interface ResponseApi<T> {
	extras: [];
	results: T[];
	totalPage: number;
}

export interface ResponseApiUpdate {
	errors: boolean;
	message: string;
}

const options: {
	headers?: HttpHeaders | { [header: string]: string | string[] },
	observe?: 'body' | 'events' | 'response',
	params?: HttpParams | { [param: string]: string | string[] },
	reportProgress?: boolean,
	responseType?: 'arraybuffer' | 'blob' | 'json' | 'text',
	withCredentials?: boolean,
} = {
	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
	reportProgress: false,
	observe: 'body',
	responseType: 'json',
}

/**
 * Seria legal conversar com o Xandão sobre os caminhos padronizados da apí pra ter uma classe "abstrata" de serviço de requisições
 * @deprecated
 */
export class BaseService<T> {

    toastController: ToastController = new ToastController();

	constructor(
		protected http: HttpClient,
		protected classPath: string,
	) { }

	getAll(): Observable<T[] | ResponseApi<T>> {
        return this.http.get<T[]>(`${environment.SERVERHOST}/${this.classPath}/${this.classPath}s/999/1`,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'getAll');
			return [];
		}));
	}

	getById(model: string): Observable<T> {
        return this.http.get<T>(`${environment.SERVERHOST}/${this.classPath}/${model}`,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'getById');
			return [];
		}));
	}

	getOne(model: T | T[]): Observable<T> {
        return this.http.post<T>(`${environment.SERVERHOST}/${this.classPath}/getone`, model,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'getOne');
			return null;
		}));
	}

	getByValue(model: T | T[]): Observable<T | T[]> {
        return this.http.post<T | T[]>(`${environment.SERVERHOST}/${this.classPath}/getbyvalue`, model,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'getByValue');
			return null;
		}));
	}

	create(model: T | T[]): Observable<T | T[] | boolean> {
        return this.http.post<T | T[]>(`${environment.SERVERHOST}/${this.classPath}/adicionar`, model,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'create');
			return [];
		}));
	}

	update(model: T): Observable<ResponseApiUpdate> {
        return this.http.post<ResponseApiUpdate>(`${environment.SERVERHOST}/${this.classPath}/adicionar`, model
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'update');
			return [];
		}));
	}

	delete(model: string): Observable<T | false> {
        return this.http.delete<T>(`${environment.SERVERHOST}/${this.classPath}/${model}`,
        // {
		// 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
		// 	reportProgress: false,
		// 	observe: 'body',
		// 	responseType: 'json',
        // }
        ).pipe(tap(v => {
			return v
		}, (e) => {
			this.handleError(e, 'delete');
			return [];
		}));
	}

	handleError(e: any, msgAux: string = ''): any {
		let message: string = 'Ops! Um erro inesperado ocorreu'
		let suport: boolean = false;
		switch (e?.error?.statusCode) {
			case 401:
				message = 'Ação não autorizada';
				break;

			case 404:
				message = 'Rota não encontrada';
				suport = true;
				break;

			case 500:
				message = 'Erro interno do servidor';
				suport = true;
				break;

			default:
				message = 'Erro desconhecido: ' + e?.error?.statusCode;
				suport = true;
				break;
		}

        message += (msgAux.length > 0) ? (' - ' + msgAux) : '';
        const toast = this.toastController.create({
            color: 'dark',
            duration: 2000,
            message,
            keyboardClose: true
        }).then(t => {
            t.present();
        });
		// if (!this.toastErrorActive) {
		// 	M.toast({ html: message });
		// 	if (suport) {
		// 		M.toast({ html: 'Contate o suporte' });
		// 	}
		// 	this.toastErrorActive = true;
		// 	setTimeout(() => {
		// 		this.toastErrorActive = false;
		// 	}, 1000)
		// }
	}

	toastErrorActive: boolean = false;
}