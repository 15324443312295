import { Injectable } from "@angular/core";

/**
 * Usado junto com a diretiva IonMask que eu criei para os `IonInput`'s
 */
@Injectable({
  providedIn: "root",
})
export class MaskService {
  /**
   * Máscaras setadas
   *
   * *obs.:* `Não` instancie este serviço novamente a menos que para algo específico pois as máscaras próprias serão perdidas
   * Nada a ver isso aqui tio, usa essa varioável não (não sei o que eu tava na cabeça)
   */
  public customMasks: Object = new Object();

  public cep: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public cepGestor: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public phoneGestor13: Array<RegExp | string> = [
    "(",
    /[0-9]/,
    /[0-9]/,
    ")",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public phoneGestor14: Array<RegExp | string> = [
    "(",
    /[0-9]/,
    /[0-9]/,
    ")",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public uf: Array<RegExp | string> = [/[a-zA-Z]/, /[a-zA-Z]/];

  public cardNumber: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public cardCvv: Array<RegExp | string> = [/[0-9]/, /[0-9]/, /[0-9]/];

  public cardExpDate: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    "/",
    /[0-9]/,
    /[0-9]/,
  ];

  public cpf: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    ".",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
  ];

  public cardConsumo: Array<RegExp | string> = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  /**
   * Placa de carro
   */
  public licensePlate: Array<RegExp | string> = [
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    "-",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  public troco: (value: string) => Array<RegExp | string> = (
    value: string
  ): Array<RegExp | string> => {
    switch (value.length) {
      case 0:
        return ["0", ",", "0", "0"];
      case 1:
        return ["0", ",", "0", /[0-9]/];
      case 2:
        return ["0", ",", /[0-9]/, /[0-9]/];
      case 3:
        return [/[0-9]/, ",", /[0-9]/, /[0-9]/];
      default:
        const regexArr = new Array<RegExp | string>(value.length - 3);
        regexArr.forEach((v) => {
          // v = /[0-9]/
          v = new RegExp("[0-9]");
        });
        return [...regexArr, ",", /[0-9]/, /[0-9]/];
    }
  };

  constructor() {}

  setCustom(maskName: string, mask: Array<RegExp | string>): void {
    this.customMasks[maskName] = mask;
  }

  getCustom(maskName: string): Array<RegExp | string> {
    return this.customMasks[maskName] || this[maskName];
  }

  isValid(value: string, mask: Array<RegExp | string>) {
    value = value + "";
    const invalidIndex = mask.findIndex((r, index: number) =>
      r instanceof RegExp ? !r.test(value[index]) : r != value[index]
    );
    return invalidIndex == -1;
  }
}
