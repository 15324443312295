import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, mergeAll, tap } from 'rxjs/operators';

import { Geolocation } from '@ionic-native/geolocation/ngx'
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx'

import { Localizacao } from '../../models/address/localizacao.model';
import { GeolocationPosition } from '../../interfaces/geolocation-position.interface';
import { environment } from '../../../environments/environment';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private address$: BehaviorSubject<Localizacao>
  private _address: Localizacao
  private _context: string = 'AddressService'

  constructor(
    private geolocation: Geolocation,
    private http: HttpClient,
    private nativeGeocoder: NativeGeocoder,
    private storage: StorageService,
  ) {
    this.address$ = new BehaviorSubject<Localizacao>(null)
    storage.getV2<Localizacao>(environment.storage.v2.userAddress).subscribe({
      next: address => this.address$.next(address)
    }) // Checa se já existe um endereço salvo
  }

  get(): Observable<Localizacao> {
    return this.address$.asObservable().pipe(tap(address => this._address = address))
  }

  /**
   * Retorna o valor do endereço atual do usuário
   * @note Não use à menos que realmente seja necessário. Há 99,9% de chance de a lógica do código estar errada, caso precise disso
   * @note Devido a minha falta de conhecimento pra controlar uma estrutura puramente declarativa, resolvi adotar essa maneira de trabalhar.
   * Mantenha as alterações do set de address pelo subject, mas está liberada o uso dessa função para a passagem de parâmetros por exemplo
   */
  getCurrentAddress(): Localizacao {
    return this._address
  }

  /**
   * Salva o endereço no storage e depois passa o valor pra todas as inscrições
   * @param address 
   */
  set(address: Localizacao|GeolocationPosition): Observable<Localizacao> {
    if(!environment.production) console.log('saving address')
    const saveAddress$ = new Subject<Localizacao>()

    if(address instanceof Localizacao) {
      if(!environment.production) console.log('address is an Address')

      // Salvando no storage
      const sub_set = this.storage.saveV2(environment.storage.v2.userAddress, address).subscribe({
        next: value => {
          if(!environment.production) console.log(value, 'value from AddressService - set')
          this.address$.next(address)
          saveAddress$.next(address)
          // Limpando inscrição para evitar memory leak
          sub_set.unsubscribe()
        }
      })

    } else {
      if(!environment.production) console.log('address is a Geolocation')

      // Buscando endereço através da localização
      this.getAddressFromCoodinates(address).subscribe(add => {
        const newAddress = new Localizacao(add)
        this.address$.next(newAddress)
        saveAddress$.next(newAddress)
      })
    }
    return saveAddress$.asObservable()
  }

  /**
   * Busca a localização do usuário
   */
  searchLocation(): Observable<GeolocationPosition> {
    return new Observable<GeolocationPosition>(subscriber => {
      this.geolocation.getCurrentPosition()
        .then(position => subscriber.next(position))
        .catch(err => subscriber.error(err))
    })
  }

  /**
   * Retorna as coordenadas baseado na string que foi pesquisada
   * @param address 
   */
  getCoordinatesFromAddress(address: string): Observable<NativeGeocoderResult> {
    if(!environment.production) console.log('getting coordinates from address')
    const coordinates$ = new Subject<NativeGeocoderResult>()
    this.nativeGeocoder.forwardGeocode(address, {
      maxResults: 5,
      useLocale: true
    }).then(result => coordinates$.next(result[0]))
    return coordinates$.asObservable()
  }

  /**
   * Busca o endereço pelas coordenadas
   * @param position 
   */
  getAddressFromCoodinates(position: GeolocationPosition): Observable<Localizacao> {
    if(!environment.production) console.log('getting address from coordinates')
    const geocoderResult = new Subject<Localizacao>()
    this.nativeGeocoder.reverseGeocode(position.coords.latitude, position.coords.longitude, {
      useLocale: true,
      maxResults: 5
    }).then(result => geocoderResult.next(new Localizacao(Localizacao.assignLocation(result[0]))))
    return geocoderResult
  }

  /**
   * Retorna o endereço pelo cep
   * @param cep 
   */
  getAddressFromCep(cep: string): Observable<Localizacao> {
    if(!environment.production) console.log('searching address from cep')
    return this.http.get<Localizacao>(`${environment.SERVERHOST}/localizacao/viaCep/${cep}`)
  }

  /**
   * Retorna o endereço atual do usuário
   */
  locateUser() {
    if(!environment.production) console.log('location user')
    return new Observable<Localizacao>(subscriber => {
      const sub = this.searchLocation().pipe(
        map(position => this.getAddressFromCoodinates(position)),
        mergeAll(1)
      ).subscribe(result => {
        if(!environment.production) console.log(result, 'user located')
        subscriber.next(new Localizacao(result))
        subscriber.complete()
        sub.unsubscribe()
      })
    })
  }
}
