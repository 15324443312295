import { BaseModel } from './base-class.model';

export class User extends BaseModel<User> {

    accessKey?: string;
    nome?: string;
    sobrenome?: string;
    cpf?: string;
    senha?: string;
    fone?: string;
    email?: string;
    restauranteId?: number;
    facebook?: string;
    imagem?: string;
    isValid?: boolean;
    userID?: string;
}