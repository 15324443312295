import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { retry, tap } from "rxjs/operators";

import { RestaurantService } from "../../services/restaurant.service";
import { StorageService } from "../../services/storage.service";
import { environment } from "../../../environments/environment";

/**
 * Caso o restaurante não esteja operando, ele redirecionará para página de restaurante fechado
 */
@Injectable({
    providedIn: 'root'
})
export class OperationGuard implements CanActivate {
    
    constructor(
        private restaurantService: RestaurantService,
        private router: Router,
        private storage: StorageService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const cnpj: string = this.storage.get<string>(environment.storage.cnpj)

        const promiseRest = this.restaurantService.getByCnpj(cnpj).pipe(retry(2), tap(rest => this.restaurantService.setRestaurant(rest))).toPromise()
        return promiseRest.then(rest => rest.isOperation || this.router.createUrlTree([environment.routePaths.closed]))
            .catch(error => Promise.resolve(this.router.createUrlTree([environment.routePaths.sorry])))
    }
}