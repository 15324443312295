import { Injectable } from '@angular/core';

import { ItemCart } from '../interfaces/itemCart.interface';
import { Localizacao } from '../models/address/localizacao.model';
import { Utils } from './util';

@Injectable({
    providedIn: "root"
})
export class WhatsMsgService {

    constructor(
        private utils: Utils,
    ) { }

    /**
     * Retorna a mensagem formatada que será enviada via whatsapp - rota `zap`
     */
    buildMsg(parameters: {
        restaurantName: string,
        userName: string,
        items: ItemCart[],
        pedidoTotal?: number,
        userFone: number,
        mode: 'user'|'table'
        aquisition: 'Retirada'|'Entrega'| '',
        endereco: Localizacao,
        pagamento: number,
        troco: number,
        observation: string,
        totalValue: number,
        tableNumber?: number|string
    }): string {
        const date = new Date()
        let msg = '*' + parameters.restaurantName.toUpperCase() + '*'
            + '%0a'
            + '*' + `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` + ' | ' + `${date.getHours().toLocaleString()}:${date.getMinutes().toLocaleString()}` + '*'
            + '%0a'
            + '%0a'

            + 'Nome: *' + parameters.userName + '*'
            + (parameters.tableNumber ? /*('%0aMesa: *' + parameters.tableNumber + '*')*/'' : '')
            + ''
            + '%0a'
            + (parameters.userFone ? this.utils.phoneNumber(parameters.userFone) : '')
            + '%0a'
            + '~-----------------------------------------------~'
            + '%0a'
            + '%0a'
        parameters.items.forEach(item => {
            let halfStr: string = ''
            item.meiomeios.forEach(half => {
                halfStr += (`_• 1 / ${item.produto.numberMeiomeio} ${half.nome}_%0a`)
            })

            let optStr: string = ''
            item.cartItemOpcoes.forEach(option => {
                optStr += (`_•${option.nome} x${option.quantidade}_%0a`)
            })
            optStr = optStr.substr(0, optStr.length - 3)
            console.log(item)
            msg += '*' + item.produto.nome.trim() + '* ```x' + item.number + '```' +
                '%0a' +
                (item.meiomeios.length > 0 ? ('*Meio a meios* %0a' + halfStr) : '') +
                '%0a' +
                '%0a' +
                optStr +
                '%0a ' +
                (item?.observacao || '') +
                '%0a' +
                '%0a' +
                'R$ ' + this.utils.numberToPriceBr(ItemCart.calcTotalValue(item)) +
                '%0a' +
                '~-----------------------------------------------~' +
                '%0a'
        })
        msg +=  '%0a'
            + (parameters.observation ? ('*Observação*: ' + parameters.observation) : '')
            + '%0a'
            + '%0a'
            + '*TOTAL: R$ ' + this.utils.numberToPriceBr(parameters.pedidoTotal) + '*'
            + '%0a'
            + '%0a'
            + '~-----------------------------------------------~'
            + '%0a'
            + '%0a'
            + (parameters.aquisition ? ('*' + parameters.aquisition + '*') : '')
            + '%0a'
            + (parameters.aquisition ? (parameters.aquisition == 'Entrega' ?
                ('%0a' + parameters.endereco.logradouro + ', ' + parameters.endereco.numero + ' - ' + parameters.endereco.bairro
                    + '%0a' + parameters.endereco.localidade + '/' + parameters.endereco.uf + ' - '
                    + (parameters.endereco.cep ? this.utils.numberToCep(parameters.endereco.cep) : '')
                    + '%0a' + (parameters.endereco.complemento ? 'Complt: '+parameters.endereco.complemento : ''))
                : '') : '')
            + '%0a'
            + 'Forma de pagamento: ' + (parameters.pagamento == 1 ? 'Dinheiro' : 'Cartão')
            + '%0a'
            // + 'Taxa de entrega: ' + parameters.deliveryFee
            + '%0a'
            + (parameters.troco ? ('Troco: R$ ' + this.utils.numberToPriceBr((parameters.troco - parameters.totalValue).toFixed(2))) : '')

        return msg
    }
}