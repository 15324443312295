// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { ZimmerStorage } from "../app/models/zimmerStorage.model";

export const environment = {
  production: false,
  //SERVERHOST: document.location.port == "4200" ? "http://localhost:56435/api" : "https://api.zclub.com.br/api",
  SERVERHOST: "https://api.zclub.com.br/api",
  //SERVERHOST: "http://api-teste.zclub.com.br/api",
  RES_CNPJ: (JSON.parse(localStorage.getItem("zimmer")) as ZimmerStorage)?.cnpj || "",
  BASE_URL: "home",
  USER_GUID: "09766F36-B493-453E-8900-666EBACCC45C",
  table_number_input_id: "table-number-input",
  storage: {
    tableNumber: "tableNumber",
    ficha: "senha",
    preferences: "preferences",
    appMode: "mode",
    comandaId: "comandaId",
    cnpj: "cnpj",
    v2: {
      userAddress: "address",
      gallery: "gallery",
      mode: "mode",
      cnpj: "cnpj",
      preferences: "preferences",
      tableNumber: "reference",
      breed: "breed",
    },
  },
  modals: {
    cartNewId: "cart-new",
    configAppId: "config-app",
    comandaScannerId: "comanda-scanner",
  },
  html5qrcode: {
    modalId: "modal-html5qrcodescanner",
    qrcodeScanElement: "qrcode-scan",
  },
  appModes: {
    /**
     * Envio de pedido via WhatsApp
     */
    zap: "zap",
    /**
     * Sem envio de pedido
     */
    menu: "menu",
    /**
     * Finalização de pedido com pagamento online ou normal e retorno de 'senha' para identificação do cliente final
     */
    onlinePayment: "pedido",
    /**
     * Finalização de pedido normal sem pagamento
     */
    table: "qrcode",
    /**
     * Finalização de pedido através de uma ficha(QR Code)
     */
    comanda: "ficha",
    /**
     * Finalização de pedido para drive thru
     */
    drive: "drive",
    consumo: "consumo",
    /**
     * Finalização de pedido via API no Gestor
     */
    gestor: "gestor",
  },
  routeParams: {
    appMode: "mode",
    cnpj: "cnpj",
    phoneNumber: "phoneNumber",
    prodCardStyle: "prodCardStyle",
    tableNumber: "tableNumber",
  },
  routePaths: {
    scanner: "scanner",
    sorry: "sorry",
    closed: "closed",
    v2: {
      version: "v2",
      home: "home",
      scanner: "scanner",
      breed: "breed",
    },
  },
  qrcodeConfigValidationLink: "https://zclub.com.br",
  pedidoURL: "/v2/pedido/",
  addAddressModalId: "add-address-modal",
  cnpjRest: '' /* cnpj pego após a aba config */,
  // theme: 0 // Not implemented
  // zimmer: new Object() as ZimmerStorage
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
