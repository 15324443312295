import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";

import { StorageService } from "../../services/storage.service";
import { environment } from "../../../environments/environment";

/**
 * Se não houver cnpj cadastrado, ele abrirá a página de scanner para que seja redirecionado à uma rota
 */
@Injectable({
    providedIn: 'root'
})
export class ScannerGuard implements CanActivate {

    constructor(
        private router: Router,
        private storage: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(state.url.includes('home')) {
            const cnpj: string = this.storage.get<string>(environment.storage.cnpj)
            return !!cnpj || this.router.createUrlTree([environment.routePaths.scanner])
        } else return !!route.params.cnpj || this.router.createUrlTree([environment.routePaths.scanner])
    }
}