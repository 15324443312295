import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { HomePageRoutingModule } from './home-routing.module';
import { HomePage } from './home.page';
import { SetDetailsModule } from '../../shared/components/set-details/set-details.module';
import { CheckDetailsModule } from '../../shared/components/check-details/check-details.module';
import { UserInformationPageModule } from '../cart-new/user-information/user-information.module';
import { CartNewPageModule } from '../cart-new/cart-new.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    HomePageRoutingModule,
    SetDetailsModule,
    CheckDetailsModule,
    UserInformationPageModule,
    CartNewPageModule
  ],
  providers: [
    BarcodeScanner,
  ],
  declarations: [HomePage]
})
export class HomePageModule { }
