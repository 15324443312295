import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { flatMap, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Order } from '../models/order/order.model';
import { AuthService, httpOptions } from '../shared/auth.service';
import { ResponseApi } from '../shared/base.service';
import { OrderItemResponse, OrderResponse } from '../models/order/order-response.model';
import { StorageService } from './storage.service';
import { RestaurantService } from './restaurant.service';
import { QrcodeService } from './qrcode.service';

/**
 * Status possíveis atualmene no pedido
 * @improves Trazer da API na primeira carga de dados. Assim é possível atualizá-los somente via API. O tratamento então seria feito de mmaneira completamente dinâmica, deixando o front cada vez mais burro
 */
export const orderStatus: {
  ARRIVED_AT_ORIGIN: 8,
  CANCELLATION_REQUESTED: 10,
  CANCELLED: 4,
  CLOSED_TABLE: 96,
  COMANDAID_NOT_FOUND: 999,
  CONCLUDED: 7,
  CONFIRMED: 2,
  DISPATCHED: 5,
  DELIVERED: 6,
  ERROR_OPMESA1: 97,
  ERROR_OPMESA2: 99,
  GOING_TO_ORIGIN: 9,
  INTEGRATED: 3,
  INVISIBLE: 0,
  ITEM_NOT_INSERTED: 98,
  PLACED: 1,
  PRODUCT_NOT_FOUND: 90,
  READYTODELIVER: 11,
  TOTENID_NOT_FOUND: 91,
  UNKNOWN_ERROR: 95,
  WAITING_PAYMENT: 92,
  labels: string[]
} = {
  ARRIVED_AT_ORIGIN: 8,
  CANCELLATION_REQUESTED: 10,
  CANCELLED: 4,
  CLOSED_TABLE: 96,
  COMANDAID_NOT_FOUND: 999,
  CONCLUDED: 7,
  CONFIRMED: 2,
  DISPATCHED: 5,
  DELIVERED: 6,
  ERROR_OPMESA1: 97,
  ERROR_OPMESA2: 99,
  GOING_TO_ORIGIN: 9,
  INTEGRATED: 3,
  INVISIBLE: 0,
  ITEM_NOT_INSERTED: 98,
  PLACED: 1,
  PRODUCT_NOT_FOUND: 90,
  READYTODELIVER: 11,
  TOTENID_NOT_FOUND: 91,
  UNKNOWN_ERROR: 95,
  WAITING_PAYMENT: 92,
  labels: [
    'Invisível',
    'Placed',
    'Confirmado',
    'Realizado',
    'Cancelado',
    'Despachado',
    'Entregue',
    'Concluído',
    'No destino',
    'Entregando',
    'Cancelamento solicitado',
    'Pronto para entrega',
    'Produto não encontrado',
    'TotemID não encontrado',
    'Aguardando pagamento',
    'Erro desconhecido',
    'Mesa fechada',
    'Erro OpMesa1',
    'Item não inserido',
    'Erro OpMesa2',
    'ComandaID não encontrada'
  ]
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private orders$: BehaviorSubject<OrderResponse[]> = new BehaviorSubject<OrderResponse[]>([])
  private comandaId$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  /**
   * Intervalo de buscas na api por pedidos através da comanda
   */
  private intervalLoadOrders

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private restService: RestaurantService,
    private scanner: QrcodeService,
    private storage: StorageService,
  ) {
    this.initOrdersStates()
    storage.get(environment.storage.comandaId)
  }

  /**
   * Retorna da API um pedido pelo guid
   * @param id 
   */
  getById(id: string, restToken: string): Observable<Order> {
    return this.http.get<Order>(`${environment.SERVERHOST}/v2/Pedido/byId/${id}/${restToken}`, httpOptions).pipe(tap(response => {
      if (!environment.production) {
        console.log('response', response)
      }
    }))
  }

  /**
   * Retorna um observable com a comandaId registrada
   * @note Está somente na v1
   * @deprecated
   */
  getComandaId():Observable<string> {
    return this.comandaId$.pipe(tap(comandaId => this.storage.save(environment.storage.comandaId, comandaId)))
  }

  /**
   * Retorna os pedidos salvos no service
   */
  getOrders(): Observable<OrderResponse[]> {
    return this.orders$.asObservable()
  }

  /**
   * Se não for passado uma comandaId, ele abrirá o scanner de QR Code para que o cliente scaneie
   */
  getOrderByComandaId(comandaId?: string): Observable<OrderResponse> {
    if(comandaId) return this.http.get<OrderResponse>(`${environment.SERVERHOST}/pedido/ficha/${comandaId}`)
    else {
      return this.scanner.scan({
        validation: (v) => v.length === 36
      }).pipe(mergeMap(result => this.http.get<OrderResponse>(`${environment.SERVERHOST}/pedido/ficha/${result}`)))
    }
  }

  /**
   * @returns Número do status do pedido
   * @param pedidoId 
   */
  getStatus(pedidoId: string): Observable<number> {
    return this.http.get<number>(`${environment.SERVERHOST}/pedido/obterStatus/${pedidoId}`, httpOptions)
  }

  /**
   * Seta um Id no observable do ID da comanda
   */
  setComandaId(comandaId: string) {
    this.comandaId$.next(comandaId)
  }

  /**
   * Requisita na API os pedidos conforme o número da comanda
   */
  initOrdersStates() {
    this.comandaId$.subscribe({
      next: comandaId => {
        if(comandaId) {
          this.intervalLoadOrders = setInterval(() => {
            this.getOpenOrdersByComandaId(comandaId).subscribe({
              next: orders => this.orders$.next(orders)
            })
          }, 5000)
        } else clearInterval(this.intervalLoadOrders)
      }
    })
  }

  /**
   * Retorna todos os pedidos aberto no zimmer
   */
  getOpenOrdersByZimmer(): Observable<OrderResponse[]> {
    return this.http.get<ResponseApi<OrderResponse>>(`${environment.SERVERHOST}/v2/pedido/getByEnactment/${environment.USER_GUID}/30/1`).pipe(map(response => response.results))
  }

  /**
   * Devolve todos os pedidos abertos 
   * @param table 
   */
  private getOpenOrdersByComandaId(table: string|number): Observable<OrderResponse[]> {
    const orders$ = new Subject<OrderResponse[]>()
    // this.getOpenOrdersByZimmer().subscribe(response => openOrders.next(response.filter(order => order.restaurante.cnpj == this.storage.get('cnpj'))))
    this.comandaId$.subscribe({
      next: comandaId => {
        this.http.get<OrderResponse[]>(`${environment.SERVERHOST}/pedido/ficha/${comandaId}`).subscribe({
          next: orders => orders$.next(orders)
        })
      }
    })
    return orders$.asObservable()
  }

  /**
   * Pega os itens pedido pela mesa
   */
  getItemsOrderedByTableNumber(): Observable<OrderItemResponse[]> {
    return this.restService.getRest().pipe(
      flatMap(rest => this.auth.getTableV2().pipe(map(table => ({ restId: rest.restauranteId, mesaId: table })))),
      flatMap(data => this.http.get<OrderItemResponse[]>(`${environment.SERVERHOST}/pedido/itensByMesa/${data.restId}/${data.mesaId}`))
    )
  }
}
