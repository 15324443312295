import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-picture-viewer',
  templateUrl: './picture-viewer.component.html',
  styleUrls: ['./picture-viewer.component.scss'],
})
export class PictureViewerComponent implements OnInit {

  @Input('img') img: string;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  dismiss() {
    this.modalCtrl.dismiss()
  }
}
