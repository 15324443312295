import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { CartService } from '../../../services/cart.service';
import { ItemPage } from '../../item/item.page';
import { ItemCart } from '../../../interfaces/itemCart.interface';
import { AuthService } from '../../../shared/auth.service';
import { Utils } from '../../../shared/util';
import { OrderService } from '../../../services/order.service';
import { OrderResponse } from 'src/app/models/order/order-response.model';
import { Observable } from 'rxjs';
import { CartNewPage } from '../../cart-new/cart-new.page';

// Página de vizualização de pedidos já realizados
@Component({
  selector: 'app-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {

  orders$: Observable<OrderResponse[]>

  constructor(
    protected auth: AuthService,
    public cartService: CartService,
    private ordersService: OrderService,
    protected modalController: ModalController,
    public utils: Utils
  ) { }

  ngOnInit() {
    this.loadOrders()
  }

  // Abre um modal com os detalhes dp produto
  showDetails(item: ItemCart) {
    this.modalController.create({
      component: ItemPage,
      componentProps: {
        itemCart: item,
        readOnly: true
      },
      cssClass: 'modal-fullscreen'
    }).then(modal => modal.present())
  }

  loadOrders() {
    // if(this.auth.getUser()) {
      // this.cartService.getOrderByUserId(this.auth.getUser().userID).subscribe(result => {
      //   this.orders = result;
      // })
    // } else {
    //   this.modalController.create({
    //     component: LoginPage,
    //   }).then(modal => modal.present());
    // }
    this.orders$ = this.ordersService.getOrders()
  }

  getDateFromOrder(date: string) {
    const dateObj = new Date(date)
    return dateObj.getHours() + ':' + dateObj.getMinutes()
  }

  callCartDetail(order: OrderResponse) {
    this.modalController.create({
      component: CartNewPage,
      componentProps: {
        order,
        readOnly: true
      },
      id: 'cart-' + order.pedidoId
    }).then(modal => modal.present())
  }

  closeCart() {
    this.modalController.dismiss()
  }
}
