import { Half } from "../models/half.model";
import { Product } from "../models/produto.model";
import { ProductOption } from "./product-option.interface";

export class ItemCart {
  id?: number;
  produtoId?: number;
  produto?: Product;
  number?: number;
  // complementos: Complemento[]
  cartItemOpcoes?: ProductOption[] = [];
  meiomeios?: Half[] = [];
  observacao?: string;

  constructor(values?: ItemCart) {
    if (values) {
      Object.keys(values).forEach((key) => {
        this[key] = values[key];
      });
    }
  }

  static calcTotalValue(item: ItemCart) {
    let total: number = 0;
    item.cartItemOpcoes.forEach(
      (option) => (total += option.valor * option.quantidade)
    ); 
    if (
      item.produto.numberMeiomeio > 1 &&
      !item.produto.valorVenda &&
      item.meiomeios.length > 0
    ) {
      const mostExpensive = item.meiomeios.reduce((lastHalf, half) =>
        (lastHalf.valorPromocao || lastHalf.valorVenda) >
        (half.valorPromocao || half.valorVenda)
          ? lastHalf
          : half
      );
      total +=
        mostExpensive.valorPromocao || mostExpensive.valorVendaComplemento;
    }
    
    let preco =
      item.produto.valorPromocao ||
      item.produto.valorVenda ||
      item.produto.valorRegular ||
      0;

    total += item.number * preco;    

    return Number(total.toFixed(2));
  }
}
