import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage'
import { forkJoin, Observable } from 'rxjs';
import { flatMap, map, take, tap } from 'rxjs/operators';

import { ZimmerStorage } from '../models/zimmerStorage.model';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage
  ) { }

  /**
   * Cria o objeto 'zimmer' no storage passado no parâmetro, se não for passado, cria um novo
   * @param zimmerObj - ZimmerStorage
   */
  prepare(zimmerObj: ZimmerStorage): void {
    // const zimmer = (JSON.parse(localStorage.getItem('zimmer')) as ZimmerStorage) || new Object() as ZimmerStorage
    const zimmer = new Object() as ZimmerStorage
    Object.keys(zimmerObj).forEach(key => {
      zimmer[key] = zimmerObj[key]
    })
    sessionStorage.setItem('zimmer', JSON.stringify(zimmer))
  }

  /**
   * chaveia um valor no storage
   * @param { string } key Chave
   * @param { string } value
   */
  save(key: string, value): void {
    const zimmer = JSON.parse(sessionStorage.getItem('zimmer')) as ZimmerStorage || new Object()
    zimmer[key] = value
    sessionStorage.setItem('zimmer', JSON.stringify(zimmer))
  }

  /**
   * chaveia um valor no storage
   * @param { string } key Chave
   * @param { string } value
   */
  saveV2(key: string, value): Observable<void> {
    return (new Observable(subscriber => {
      this.storage.set(key, value).then(() => {
        subscriber.next()
        subscriber.complete()
      })
    }))
  }

  /**
   * Retorna o valor da chave do storage
   * @param { string } key
   */
  get<T = any>(key?: string): T {
    const zimmer = JSON.parse(sessionStorage.getItem('zimmer')) as ZimmerStorage
    return key ? zimmer?.[key] : zimmer
  }

  /**
   * Retorna o valor da chave do storage
   * @param { string } key
   */
  getV2<T = any>(key?: string): Observable<T> {
    return new Observable(subscriber => {
      this.storage.get(key).then(value => {
        subscriber.next(value)
        subscriber.complete()
      })
    })
  }

  /**
   * Junta todas os resultados em um objeto
   */
  getMany<T = any>(keys: string[]): Observable<T> {
    // return new Observable(subscriber => {
    //   const result: T = new Object() as T
    //   const storageSearchs: Observable<any>[] = keys.map(key => this.getV2(key).pipe(tap(data => result[key] = data)))
      const storageSearchsObj: T = new Object() as T
      keys.forEach(key => storageSearchsObj[key] = this.getV2(key).pipe(take(1)))
      // console.log(new Object({
      //   ...keys
      // }))
      
      // console.log(storageSearchs)
      return forkJoin(storageSearchsObj)/*.subscribe({
        next: () => {
          console.log('conseguiu dar sub')
          subscriber.next(result)
        }
      })*/
    // })
  }

  /**
   * Apaga o valor de uma chave do storage
   */
  delete(key: string): void {
    const zimmer = JSON.parse(sessionStorage.getItem('zimmer')) as ZimmerStorage
    delete zimmer[key]
    sessionStorage.setItem('zimmer', JSON.stringify(zimmer))
  }

  /**
   * Apaga o valor de uma chave do storage
   */
  deleteV2(key: string): Observable<void> {
    return new Observable(subscriber => {
      this.storage.remove(key).then(() => {
        subscriber.next()
        subscriber.complete()
      })
    })
  }
  clearV2(){
    this.storage.clear();
  }
  /**
   * Chaveia um valor na session
   * @param key 
   * @param value 
   */
  setSessionData(key: string, value): void {
    const zimmer = JSON.parse(sessionStorage.getItem('zimmer')) || new Object()
    zimmer[key] = value
    sessionStorage.setItem('zimmer', JSON.stringify(zimmer))
  }

  /**
   * Retorna um valor chaveado na session
   * @param key 
   */
  getSessionData<T = any>(key: string): T {
    const zimmer = JSON.parse(sessionStorage.getItem('zimmer'))
    return zimmer ? zimmer[key] : zimmer
  }

  /**
   * Funciona somente no V2
   */
  push(key: string, valueToPush: any): Observable<void> {
    return this.getV2<any[]>(key).pipe(
      map(value => value || []),
      flatMap(arr => {
        arr.push(valueToPush)
        return this.saveV2(key, arr)
      })
    )
  }

  /**
   * Funciona somente no V2
   */
  filter(key: string, filterCondition: (any) => any): Observable<void> {
    return this.getV2<any[]>(key).pipe(
      map(value => (value || []).filter(filterCondition)),
      flatMap(arr => {
        return this.saveV2(key, arr)
      })
    )
  }
}
