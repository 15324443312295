import { BaseModel } from '../base-class.model'

export class cardConsumo extends BaseModel<cardConsumo> {
    cartaoConsumoId?: string
    numero?: string
    descricao?: string
    valor?: number
    validade?: string
    cpf?: string
    desconto?: number
    nome?: string
    restauranteId?: number
    saldoAtual?: number

}