import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OperationGuard } from 'src/app/shared/guards/operation.guard';
import { environment } from 'src/environments/environment';

import { HomePage } from './home.page';

const routes: Routes = [
  { path: '', component: HomePage },
  {
    path: 'order',
    loadChildren: () => import('./order-details/order-details.module').then(m => m.OrderDetailsPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersPageModule)
  },
  {
    path: 'product-detail',
    loadChildren: () => import('../restaurant/products/product-detail/product-detail.module').then(m => m.ProductDetailPageModule)
  },
  {
    path: 'table',
    loadChildren: () => import('./table/table.module').then(m => m.TablePageModule)
  },
  {
    path: 'categorias',
    loadChildren: () => import('./category-new/category-new.module').then(m => m.CategoryNewPageModule),
    canActivate: [OperationGuard]
  },
  {
    path: `:${environment.routeParams.tableNumber}/:${environment.routeParams.prodCardStyle}`,
    component: HomePage
  },
  {
    path: `:${environment.routeParams.prodCardStyle}`,
    component: HomePage
  },
  {
    path: `:${environment.routeParams.phoneNumber}/:${environment.routeParams.prodCardStyle}`, // TODO Rota inalcançável - transfira para app component com phone number lá
    component: HomePage
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomePageRoutingModule {}