import { Component, OnInit } from "@angular/core";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { take, tap } from "rxjs/operators";
import { CartService } from "src/app/services/cart.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ToastaddService } from "src/app/services/toastadd.service";

import { environment } from "src/environments/environment";

import { QrcodeService } from "../../../services/qrcode.service";

@Component({
  selector: "app-comanda-scan",
  templateUrl: "./comanda-scan.page.html",
  styleUrls: ["./comanda-scan.page.scss"],
})
export class ComandaScanPage implements OnInit {
  private web: boolean;
  private ios: boolean;
  private camSide: any;
  private isTablet: boolean;
  private isNroMesa: boolean = false;
  private logoZimmer: any;
  private restImagem: any;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private qrScanner: QrcodeService,
    private cart: CartService,
    private config: ConfigurationService,
    private alerta: ToastaddService,
    platform: Platform
  ) {
    this.web = platform.is("mobileweb") || platform.is("desktop");
    this.isTablet = this.config.isTablet;
    this.logoZimmer = "assets/v2/logo_zimmer.png";
    this.restImagem =
      this.config.imagemRest.imagem != null
        ? this.config.imagemRest.imagem
        : this.logoZimmer;
    // this.ios = platform.is('ios') || platform.is('iphone') || platform.is('ipad')
    // this.camSide = this.ios ? 'trás' : 'back'
    // this.isNroMesa = this.config.isNroMesa
  }

  ngOnInit() {}

  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }

  /**
   * Chama o scanner de QR Code
   */
  scan() {
    if (!this.config.isNroTable) {
      this.alerta.alertaMsg(
        "Número da mesa em branco, digite corretamente para receber seu pedido"
      );
      this.cancelAction();
      return;
    }
    this.qrScanner
      .scan({
        useNativePlugin: !this.web,
        side: "back",
        validation: (value: string) => value.length === 36 || value.length <= 8,
      })
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          /* this.config.isNroTable = false;
          this.modalCtrl.dismiss(
            result,
            "confirm",
            environment.modals.comandaScannerId
          ); */
          this.cart.testMonitorID().subscribe((monitor) => {
            if (monitor.verificaComanda) {
              if (result.length <= 8 && /(^\d+$)/.test(result)) {
                this.cart.testCardID(parseInt(result)).subscribe(
                  (res) => {
                    if (!res) {
                      return this.alertCtrl
                        .create({
                          message: "Comanda inexistente!",
                          buttons: [
                            {
                              text: "Tentar Novamente",
                              handler: () => {
                                this.scan();
                              },
                            },
                          ],
                        })
                        .then((alert) => {
                          alert.present();
                        });
                    } else {
                      if (!res.ativo) {
                        return this.alertCtrl
                          .create({
                            message: "Comanda bloqueada. Chame o garçom!",
                            buttons: [
                              {
                                text: "Tentar Novamente",
                                handler: () => {
                                  this.scan();
                                },
                              },
                            ],
                          })
                          .then((alert) => {
                            alert.present();
                          });
                      } else if (!res.aberto) {
                        return this.alertCtrl
                          .create({
                            message: "Comanda não disponível. Chame o garçom!",
                            buttons: [
                              {
                                text: "Tentar Novamente",
                                handler: () => {
                                  this.scan();
                                },
                              },
                            ],
                          })
                          .then((alert) => {
                            alert.present();
                          });
                      } else {
                        this.config.isNroTable = false;
                        this.modalCtrl.dismiss(
                          result,
                          "confirm",
                          environment.modals.comandaScannerId
                        );
                      }
                    }
                  },
                  (err) => {
                    return this.alertCtrl
                      .create({
                        message: "Ocorreu um erro ao ler a Comanda!",
                        buttons: [
                          {
                            text: "Tentar Novamente",
                            handler: () => {
                              this.scan();
                            },
                          },
                        ],
                      })
                      .then((alert) => {
                        alert.present();
                      });
                  }
                );
              } else if (/([A-Za-z0-9-]{36})+/.test(result)) {
                this.config.isNroTable = false;
                this.modalCtrl.dismiss(
                  result,
                  "confirm",
                  environment.modals.comandaScannerId
                );
              } else {
                this.alertCtrl
                  .create({
                    message: "Ocorreu um erro ao ler a Comanda!",
                    buttons: [
                      {
                        text: "Tentar Novamente",
                        handler: () => {
                          this.scan();
                        },
                      },
                    ],
                  })
                  .then((alert) => {
                    alert.present();
                  });
              }
            } else {
              this.config.isNroTable = false;
              this.modalCtrl.dismiss(
                result,
                "confirm",
                environment.modals.comandaScannerId
              );
            }
          });
        },
        error: (err) => {
          console.log(err, "ERRO");
          this.alertCtrl.create({ message: err }).then((alert) => {
            this.config.isNroTable = false;
            alert
              .onDidDismiss()
              .then(() => this.modalCtrl.dismiss(undefined, "cancel"));
            alert.present();
          });
        },
      });
  }
}
