import { Component, OnInit } from "@angular/core";
import { AlertController, IonInput, ModalController } from "@ionic/angular";
import { Observable } from "rxjs";
import { CartService } from "src/app/services/cart.service";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ToastaddService } from "src/app/services/toastadd.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-table-page",
  templateUrl: "./table-page.page.html",
  styleUrls: ["./table-page.page.scss"],
})
export class TablePagePage implements OnInit {
  private isTablet: boolean;
  private inputNro: number | undefined;
  private restImagem: any;
  private logoZimmer: any;

  constructor(
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private config: ConfigurationService,
    private cartService: CartService,
    private alerta: ToastaddService
  ) {
    //this.web = platform.is('mobileweb') || platform.is('desktop')
    this.isTablet = this.config.isTablet;
    this.logoZimmer = "assets/v2/logo_zimmer.png";
    this.restImagem =
      this.config.imagemRest.imagem != null
        ? this.config.imagemRest.imagem
        : this.logoZimmer;
    this.config.isNroTable = false;
  }

  ngOnInit() {}

  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }

  saveNumber() {
    if (this.inputNro == undefined) {
      this.alerta.alertaMsg(
        "Número da mesa em branco, digite corretamente para receber seu pedido"
      );
      return;
    }
    const inputNumber = this.inputNro;
    this.modalCtrl.dismiss(
      inputNumber,
      "confirm",
      environment.table_number_input_id
    );
    this.config.isNroTable = true;
  }

  persistOnlyNumbers(event: any) {
    let characterCode = event.which ? event.which : event.keyCode;

    if (characterCode > 31 && (characterCode < 48 || characterCode > 57))
      return false;
    return true;
  }
}
