import { Component, OnInit, Input } from "@angular/core";

import { ModalController, IonInput, AlertController } from "@ionic/angular";

import { Card } from "../../../models/payment/card.model";
import { CartService } from "../../../services/cart.service";
import { Localizacao } from "../../../models/address/localizacao.model";
import { RestaurantService } from "../../../services/restaurant.service";
import { StorageService } from "../../../services/storage.service";
import { Utils } from "../../../shared/util";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-user-information",
  templateUrl: "./user-information.page.html",
  styleUrls: ["./user-information.page.scss"],
})
export class UserInformationPage implements OnInit {
  data: {
    userName?: string;
    cardNumber?: number;
    cardCvv?: number;
    expiration?: string;
    cpf?: number;
    fone?: number;
    endereco?: Localizacao;
    email?: string;
    marketing?: boolean;
  } = new Object({ endereco: new Localizacao() });
  keys: {
    key: string;
    label: string;
    greetings?: string;
    mask?: string;
    minLength?: number;
    inputId: string;
  }[] = [
    { key: "userName", label: "Nome", minLength: 1, inputId: "nome-input" },
    {
      key: "cpf",
      label: "CPF",
      mask: "numberToCpf",
      minLength: 14,
      inputId: "cpf-input",
    },
    {
      key: "cardNumber",
      label: "Cartão",
      mask: "cardNumber",
      minLength: 19,
      inputId: "numero-input",
    },
    {
      key: "cardCvv",
      label: "CVV",
      mask: "cardCvv",
      minLength: 3,
      inputId: "cvv-input",
    },
    {
      key: "expiration",
      label: "Validade",
      mask: "cardExpDate",
      minLength: 5,
      inputId: "exp-input",
    },
  ];
  options: {
    aquisition?: number;
    payment?: number;
    trocoPara?: number;
    observation?: string;
  } = {
    aquisition: 1,
    trocoPara: 0,
  };
  public trocoFormatted: string;
  private inputValue: IonInput;
  public slide: number = 0;
  public submitting: boolean;
  public appMode: string;
  public addressFormIsOpen: boolean;
  public paymentMethod: number = 1;

  constructor(
    private alertCtrl: AlertController,
    public cart: CartService,
    private modalCtrl: ModalController,
    public restaurantService: RestaurantService,
    private storage: StorageService,
    public utils: Utils
  ) {}

  ngOnInit() {
    this.appMode = "qrcode";
    const data = this.storage.getSessionData("user-information");
    if (data && !data.endereco) data.endereco = new Localizacao();
    if (data) this.data = data || new Object({ endereco: new Localizacao() });
    if (Localizacao.isValid(this.data.endereco).isValid)
      this.addressFormIsOpen = true;
    setTimeout(() => {
      this.inputValue = document.getElementById(
        "nome-input"
      ) as any as IonInput;
      this.inputValue.setFocus();
    }, 1000);
  }

  dismiss(success?: boolean) {
    this.modalCtrl.dismiss({ success });
  }

  next() {
    this.utils.setStateLoading(true);
    if (this.trocoFormatted != "0" && this.trocoFormatted)
      this.options.trocoPara = Number(
        this.trocoFormatted.replace(", ", ".").replace(",", ".")
      );
    if (
      this.options.trocoPara < this.cart.getTotalValue() &&
      this.options.trocoPara != 0
    ) {
      this.utils.setStateLoading(false);
      return this.utils.showToast({
        message: "Troco não pode ser menor que o valor do pedido",
      });
    }

    for (const [index, field] of this.keys.entries()) {
      const inputValue = document.getElementById(
        field.inputId
      ) as any as IonInput;
      this.data[this.keys[index].key] = inputValue?.value;
    }
    if (
      this.appMode == "pedido" &&
      !this.utils.cpfIsValid(this.data.cpf) &&
      this.paymentMethod == 1
    ) {
      this.utils.setStateLoading(false);
      return this.utils.showAlert({ message: "CPF inválido" });
    }
    this.slide++;
    this.storage.setSessionData("user-information", this.data);
    this.utils.setStateLoading(false);
  }

  back() {
    this.inputValue.value = this.data[this.keys[--this.slide].key];
    this.inputValue.setFocus();
  }

  prepareData() {
    this.utils.setStateLoading(true);
    this.submitting = true;

    if (this.appMode == "qrcode") {
      this.cart
        .sendOrder({
          userZimmer: {
            aceitaMarketing: this.data.marketing,
            bairro: this.data.endereco.bairro,
            cep: this.data.endereco.cep,
            cidade: this.data.endereco.localidade,
            email: this.data.email,
            estado: this.data.endereco.uf,
            fone: (this.data.fone + "").replace(/[^0-9]/gm, ""),
            logradouro: this.data.endereco.logradouro,
            nome: this.data.userName,
            restauranteId: this.restaurantService.getRestaurant().restauranteId,
          },
        })
        .subscribe((response) => {
          this.utils.setStateLoading(false);
          this.alertCtrl
            .create({
              header: "Prontinho!",
              message: "Pedido encaminhado!",
              buttons: [{ text: "OK", handler: () => this.dismiss() }],
            })
            .then((alert) => alert.present().then(() => alert.onDidDismiss()));
        });
    } else if (this.appMode == "pedido") {
      if (this.paymentMethod == 1) {
        const card = new Card();
        const dateExp = this.data.expiration.split("/");

        card.cvv = (this.data.cardCvv + "").replace(/[^0-9]/gm, "");
        card.num = Number((this.data.cardNumber + "").replace(/[^0-9]/gm, ""));
        card.titular = this.data.userName;
        card.cpf = Number((this.data.cpf + "").replace(/[^0-9]/gm, ""));

        card.monthExp = Number(dateExp[0].replace(/[^0-9]/gm, ""));
        const yExp = "0" + dateExp[1].replace(/[^0-9]/gm, "");
        card.yearExp = Number(yExp.length == 2 ? "20" + yExp : "2" + yExp);

        const extraData = this.data;

        this.cart.doOrderWithOnlinePayment(card, extraData).subscribe({
          next: (result) => {
            this.utils.setStateLoading(false);

            if (result.success) {
              this.storage.save("ficha", result.pedido);
              this.alertCtrl
                .create({
                  header: result.pedido ? "Senha: " + result.pedido : "",
                  message:
                    "Seu pedido foi encaminhado para o preparo! <br>No momento de retirá-lo chamaremos sua senha pelo painel! <br>Obrigado e Bom Apetite!",
                  buttons: [
                    { text: "OK", handler: () => this.dismiss(result.success) },
                  ],
                })
                .then((alert) =>
                  alert
                    .present()
                    .then(() =>
                      alert
                        .onDidDismiss()
                        .then(() =>
                          this.modalCtrl.dismiss(
                            undefined,
                            undefined,
                            environment.modals.cartNewId
                          )
                        )
                    )
                );
            } else {
              this.alertCtrl
                .create({
                  header: "Algo deu errado!",
                  message: result.message,
                  buttons: [{ text: "FECHAR", handler: () => this.dismiss() }],
                })
                .then((alert) => alert.present());
            }
          },
          error: () => {
            this.utils.setStateLoading(false);
            this.alertCtrl
              .create({
                header: "Algo deu errado!",
                buttons: [{ text: "FECHAR", handler: () => this.dismiss() }],
              })
              .then((alert) => alert.present());
          },
        });
      } else {
        this.cart
          .sendOrder({
            userZimmer: {
              aceitaMarketing: this.data.marketing,
              bairro: this.data.endereco.bairro,
              cep: this.data.endereco.cep,
              cidade: this.data.endereco.localidade,
              email: this.data.email,
              estado: this.data.endereco.uf,
              fone: (this.data.fone + "").replace(/[^0-9]/gm, ""),
              logradouro: this.data.endereco.logradouro,
              nome: this.data.userName,
              restauranteId:
                this.restaurantService.getRestaurant().restauranteId,
            },
          })
          .subscribe((response) => {
            this.utils.setStateLoading(false);
            this.alertCtrl
              .create({
                header: response ? "Senha: " + response : "",
                message:
                  "Seu pedido foi encaminhado ao caixa! <br>Dirija-se ao caixa e efetue o pagamento para que seu pedido seja preparado! Memorize sua senha! <br>Estamos aguardando você! Obrigado!",
                buttons: [{ text: "OK", handler: () => this.dismiss(true) }],
              })
              .then((alert) => alert.present());
          });
      }
    } else {
      const phone = Number(
        "55" +
          (
            (this.restaurantService.getRestaurant().foneCelular ||
              this.storage.get("phoneNumber")) + ""
          ).replace(/[^0-9]/, "")
      );
      const options = {
        aquisition: this.options.aquisition,
        endereco: this.data.endereco,
        number: phone,
        pagamento: this.options.payment,
        troco: this.options.trocoPara,
        userFone: this.data.fone,
        userName: this.data.userName,
        observation: this.options.observation,
        // paymentMethod: this.paymentMethod == 1 ? 'Dinheiro' : 'Cartão'
      };
      this.cart.sendOrderWhats(options);
      this.utils.setStateLoading(false);
      this.dismiss(true);
    }
  }

  minLengthIsValid(): boolean {
    if (this.appMode == "pedido") {
      if (this.paymentMethod == 1) {
        for (const field of this.keys) {
          const inputValue: IonInput = document.getElementById(
            field.inputId
          ) as any;
          if (field.minLength > (inputValue.value + "").trim().length)
            return false;
        }
      } else if (this.paymentMethod == 2) {
        const nameValue: IonInput = document.getElementById(
          "nome-input"
        ) as any;
        return (
          this.keys[0].minLength <= (nameValue.value + "").trim().length &&
          (this.data.fone + "").trim().length >= 14
        );
      }
    }
    if (this.appMode == "zap") {
      const inputValue: IonInput = document.getElementById(
        this.keys[0].inputId
      ) as any;
      if (this.keys[0].minLength > (inputValue.value + "").trim().length)
        return false;
    }
    return true;
  }

  askCep(cep: string | number) {
    this.utils.setStateLoading(true);
    this.utils.askCep(cep).subscribe({
      next: (localizacao) => {
        this.data.endereco = localizacao;
        this.utils.setStateLoading(false);
      },
      error: (error) => {
        this.utils.showToast(error.message);
        this.utils.setStateLoading(false);
      },
    });
  }

  informationIsValid() {
    return (
      this.minLengthIsValid() &&
      (this.options.aquisition == 2
        ? Localizacao.isValid(this.data.endereco).isValid
        : true)
    );
  }
}
