import { NgModule, DEFAULT_CURRENCY_CODE, LOCALE_ID } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { registerLocaleData } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy, UrlSerializer } from "@angular/router";
import localePt from "@angular/common/locales/pt";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { SQLite } from "@ionic-native/sqlite/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
// import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { LoginPageModule } from "./pages/login/login.module";
import { HomePageModule } from "./pages/home/home.module";
import { LowerCaseUrlSerializer } from "./shared/interceptor/lower-case-url-serializer.interceptor";
import { CartNewPageModule } from "./pages/cart-new/cart-new.module";

registerLocaleData(localePt, "pt-BR");
@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: "md",
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    LoginPageModule,
    HomePageModule,
    CartNewPageModule,
    // CategoryNewPageModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    NativeGeocoder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer }, // Interceptor para que as rotas sejam caseInsensitive
    SQLite,
    // BarcodeScanner
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
