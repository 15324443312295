// Funções úteis no desenvolvimento

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ToastController, AlertController, IonInput, LoadingController } from '@ionic/angular';
import { BarcodeScanner, BarcodeScanResult } from '@ionic-native/barcode-scanner/ngx';

import { environment } from '../../environments/environment';
import { Localizacao } from '../models/address/localizacao.model';

@Injectable({
  providedIn: 'root'
})
export class Utils {
  private spinner: HTMLIonLoadingElement

  constructor(
    private alertCtrl: AlertController,
    private barcodeScn: BarcodeScanner,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController
  ) { }

  /**
   * Chama a câmera para escanear um QRCode
   */
  scanQr(): Promise<BarcodeScanResult> {
    // const barcodeScanner = new BarcodeScanner()
    return this.barcodeScn.scan({
      showTorchButton: true
    })
  }

  /**
   * Separa uma string em vários pedaços
   * @param str
   * @param size Tamanho de cada pedaço
   */
  chunkSubstr(str: string, size: number) {
    const numChunks = Math.ceil(str.length / size)
    const chunks = new Array(numChunks)
  
    for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
      chunks[i] = str.substr(o, size)
    }
    return chunks
  }

  /**
   * Anima um elemmento no DOM
   * @param animateName
   * @param element
   * @param getBy Seleção por classe ou ID - 'id' | 'class'
   * @param timer Tempo de duração da animação
   */
  animate(animateName: string, element: string, getBy: 'id' | 'class' = 'id', timer: number = 1000) {
    const elements = getBy == 'id' ? [document.getElementById(element)] : document.getElementsByClassName(element)
    for (const element of elements as any) {
      element.className += ' ' + animateName;
      if(timer => 0) {
        setTimeout(() => {
          element.className = element.className.replace(' ' + animateName, '').replace(animateName, '')
        }, timer)
      }
    }
  }

  /**
   * Converte um valor para o tipo passado
   * @param value 
   * @param mode Conversão
   */
  convert = (value: any, mode: 'numberToPriceBr'|'numberToPriceBrEditing'|'cardNumber'|'cardNumberHide'|'numberToCpf'|'numberToCnpj'|'onlyNumber'|'phoneNumber'|'cardExpDate'|'cardCvv') => {
    if(!value) {
      return value
    }

    let result;
    switch(mode) {
      case 'numberToPriceBr':
        // const valNtpbr = Number((value + '').replace(/[^0-9.]/gm, ''))
        // result = valNtpbr.toFixed(2).replace('.', ',')
        const valNtpbr = value + '';
        result = (valNtpbr+(valNtpbr.includes('.') ? '' : ',')).replace('.', ',');
        let lengthAfterComma: number = result.substr(result.indexOf(',')).length;
        while(lengthAfterComma < 3) {
          result += '0';
          lengthAfterComma++;
        }
        break;
      case 'numberToPriceBrEditing':
        const valsa = (value + '').replace(/[^0-9]/gm, '')
        result = valsa.length == 2 ? '0, ' + valsa : (valsa.length == 1 ? '0, 0' + valsa : [...(Number(valsa.substr(0, valsa.length - 2)) + ''), ', ', ...valsa.substr(valsa.length - 2)].join(''))
        break;
      case 'cardNumber':
        const valCn = (value + '').replace(/[^0-9]/gm, '');
        result = this.chunkSubstr(valCn.substr(0, 16), 4).join(' ')
        break;
      case 'cardNumberHide':
        const valCnh = '********'+(value+'').substr((value+'').length - 4);
        result = this.chunkSubstr(valCnh, 4).join(' ')
        break;
      case 'numberToCpf':
        const valNtc = (value + '').replace(/[^0-9]/gm, '')
        // result = this.chunkSubstr(valNtc.substr(0, valNtc.length - 2), 3).join('.') + '-' + valNtc.substr(valNtc.length - 2)
        result = valNtc.length < 4 ? valNtc : valNtc.length < 10 ? this.chunkSubstr(valNtc.substr(0, valNtc.length), 3).join('.') : this.chunkSubstr(valNtc.substr(0, 9), 3).join('.') + '-' + valNtc.substr(9, 2)
        break;
      case 'cardExpDate':
        const valExpDate = (value+'').replace(/[^0-9]/gm, '')
        result = valExpDate.length > 2 ? valExpDate.substr(0, 2) + '/' + valExpDate.substr(2, 2) : valExpDate
        break;
      case 'cardCvv':
        const valCvv = (value+'').replace(/[^0-9]/gm, '')
        result = valCvv.substr(0, 3)
        break;
      case 'numberToCnpj':
        const valueNtcj = (value + '').replace(/[^0-9]/gm, '')
        result = valueNtcj.substr(0, 2) + (valueNtcj.length > 2 ? '.' + valueNtcj.substr(2, 3) + (valueNtcj.length > 5 ? '.' + valueNtcj.substr(5, 3) + (valueNtcj.length > 8 ? '/' + valueNtcj.substr(8, 4) + (valueNtcj.length > 12 ? '-' + valueNtcj.substr(12, 2): ''): ''): '') : '');
        break;
      case 'onlyNumber':
        const valueOn = (value + '').replace(/[^0-9]/gm, '');
        result = valueOn;
        break;
      case 'phoneNumber':
        const valuePn = (value + '').replace(/[^0-9]/gm, '');
        result = valuePn.length > 0 ? valuePn.length < 11 ? '(' + valuePn.substr(0, 2) + (valuePn.length > 2 ? ') ' + valuePn.substr(2, 4) + (valuePn.length > 6 ? '-' + valuePn.substr(6, 4) : '') : '') : '(' + valuePn.substr(0, 2) + (valuePn.length > 2 ? ') ' + valuePn.substr(2, 1) + (valuePn.length > 3 ? ' ' + valuePn.substr(3, 4) + (valuePn.length > 6 ? '-' + valuePn.substr(7, 4): '') : '') : '') : '';
        break;
      default:
        result = value;
        break;
    }
    return result
  }

  /**
   * Converte uma string ou número para um cpf formatado
   * @param value 
   */
  numberToCpf(value: string|number) {
    const phone = (value + '').replace(/[^0-9]/gm, '')
    const result = phone.length < 4 ?
      phone
      : phone.length < 10 ?
        this.chunkSubstr(phone.substr(0, phone.length), 3).join('.')
        : this.chunkSubstr(phone.substr(0, 9), 3).join('.') + '-' + phone.substr(9, 2)
    return result
  }

  /**
   * Converte uma string ou number para um número de telefone formatado (Sem o código do país)
   * @param value 
   */
  phoneNumber(value: string|number) {
    const valuePn = (value + '').replace(/[^0-9]/gm, '');
    const result = valuePn.length > 0 ?
      valuePn.length < 11 ?
        '(' + valuePn.substr(0, 2) + (valuePn.length > 2 ?
          ') ' + valuePn.substr(2, 4) + (valuePn.length > 6 ?
            '-' + valuePn.substr(6, 4)
            : '')
          : '')
        : '(' + valuePn.substr(0, 2) + (valuePn.length > 2 ?
          ') ' + valuePn.substr(2, 1) + (valuePn.length > 3 ?
            ' ' + valuePn.substr(3, 4) + (valuePn.length > 6 ?
              '-' + valuePn.substr(7, 4)
              : '')
            : '')
          : '')
        : '';
    return result
  }

  /**
   * Converte uma string ou number pra um cep formatado
   * @param cep 
   */
  numberToCep(cep: string|number): string {
    const value = (cep + '').replace(/[^0-9]/gm, '')
    const result = value.length < 6 ? value : value.substr(0, 5) + '-' + value.substr(5, 3)
    return result
  }

  /**
   * Converte uma string ou number pra um preço em moeda brasileira. Não volta acompanhado de `R$`
   * @param value 
   */
  numberToPriceBr(value: string|number): string {
    const price = value + ''
    let result = (price+(price.includes('.') ? '' : ',')).replace('.', ',')
    let lengthAfterComma: number = result.substr(result.indexOf(',')).length
    while(lengthAfterComma < 3) {
      result += '0'
      lengthAfterComma++
    }
    return result
  }

  /**
   * Instancia um toast simples
   * @param options 
   */
  showToast(options: { message?: string, duration?: number, color?: string, position?: 'bottom'|'middle'|'top' } | string) {
    const toast = this.toastCtrl.create({
      message: typeof options == 'string' ? options : options.message,
      duration: (options as any).duration || 2000,
      color: (options as any).color || 'primary',
      position: (options as any).position || 'bottom'
    })
    toast.then(t => t.present())
    return toast
  }

  /**
   * Instancia um alert simples
   * @param options 
   */
  showAlert(options: { message?: string, header?: string, subheader?: string, buttons?: { text: string, handler?: any }[] }) {
    const alert = this.alertCtrl.create({
      message: options.message,
      header: options.header,
      subHeader: options.subheader,
      buttons: options.buttons
    })
    alert.then(a => a.present())
    return alert
  }

  maskInput(element: IonInput, mode) {
    if(mode) {
      if(mode !='waiterPassword') {
        element.value = this.convert(element.value, mode);
      } else {
        element.value = this.convert(element.value, 'onlyNumber').substr(0, 4);
      }
    }
  }

  /**
   * Dependendo do boolean passado, mostra ou esconde o spinner na tela
   */
  setStateLoading(load: boolean) {
    if(load) {
      this.loadingCtrl.create({
        translucent: true,
        keyboardClose: true,
        spinner: null,
        message: '<img src="./assets/v2/zip.png" class="spinner spinning">',
      }).then(spinner => {
        this.spinner = spinner
        this.spinner.present()
      })
    } else setTimeout(() => this.spinner.dismiss(), 1000)
  }

  /**
   * Retorna se a string ou number de cpf passado é válido
   * @param cpfValue 
   */
  cpfIsValid(cpfValue: string|number): boolean {
    const cpf: string = (cpfValue + '').replace(/[^0-9]/gm, '')
    if(cpf.length != 11) return false;

    const cpfNumbersString: string[] = cpf.split('')
    const cpfNumbers: number[] = cpfNumbersString.map(number => Number(number))

    // dígito X
    const x = cpfNumbers[9]
    const y = cpfNumbers[10]

    const a = cpfNumbers[0] * 10
    const b = cpfNumbers[1] * 9
    const c = cpfNumbers[2] * 8
    const d = cpfNumbers[3] * 7
    const e = cpfNumbers[4] * 6
    const f = cpfNumbers[5] * 5
    const g = cpfNumbers[6] * 4
    const h = cpfNumbers[7] * 3
    const i = cpfNumbers[8] * 2

    const somaX = a + b + c + d + e + f + g + h + i
    const rX = somaX % 11

    // dígito Y
    const x2 = cpfNumbers[9] * 2
    const y2 = cpfNumbers[10]

    const a2 = cpfNumbers[0] * 11
    const b2 = cpfNumbers[1] * 10
    const c2 = cpfNumbers[2] * 9
    const d2 = cpfNumbers[3] * 8
    const e2 = cpfNumbers[4] * 7
    const f2 = cpfNumbers[5] * 6
    const g2 = cpfNumbers[6] * 5
    const h2 = cpfNumbers[7] * 4
    const i2 = cpfNumbers[8] * 3

    const somaY = x2 + a2 + b2 + c2 + d2 + e2 + f2 + g2 + h2 + i2
    const rY = somaY % 11

    // cálculo de verificação dos dígitos
    let xCorreto: number
    let yCorreto: number

    if(rX == 0 || rX == 1) xCorreto = 0
    else xCorreto = 11 - rX;

    if(rY == 0 || rY == 1) yCorreto = 0
    else yCorreto = 11 - rY;

    const cpfCorreto = [(a2/11), (b2/10), (c2/9), (d2/8), (e2/7), (f2/6), (g2/5), (h2/4), (i2/3), xCorreto, yCorreto].join('')

    return cpf == cpfCorreto
  }

  /**
   * Checa se o elemento passado está visível na tela
   */
  isOnScreen(element: HTMLElement, correction?: { x: number, y: number }): boolean {
    const rect = element.getBoundingClientRect()

    return (
      rect.top >= -(((rect.height - (correction?.y || 0))/100)*80) &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  // isOnElement(element: HTMLElement) {
  //   return (
  //     element.offsetTop > element.parentElement.offsetHeight
  //   )
  // }

  /**
   * Retorna a string passada com somente a primeira letra maiúscula
   * @param value 
   */
  firstCharUpperOnly(value: string): string {
    const str = value.trim()
    return str.trim().length > 0 ? str[0].toUpperCase() + str.substr(1).toLowerCase() : str.toUpperCase()
  }

  /**
   * Pesquisa o endereço do cep na api
   */
  askCep(value: string|number): Observable<Localizacao> {
    const cep = (value + '').replace(/[^0-9]/gm, '')
    return this.http.get<Localizacao>(`${environment.SERVERHOST}/localizacao/viacep/${cep}`)
  }
}