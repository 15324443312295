import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, forkJoin, Observable, of, Subject } from "rxjs";

import { Platform } from "@ionic/angular";

import { StorageService } from "./storage.service";
import { ZimmerStorage } from "../models/zimmerStorage.model";
import { QrcodeService } from "./qrcode.service";
import { environment } from "src/environments/environment";
import { AuthService } from "../shared/auth.service";
import { map, mergeMap, take, tap } from "rxjs/operators";
import { RestaurantService } from "./restaurant.service";
import { CartService } from "./cart.service";
import { config } from "process";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  private web: boolean;
  public modoApp: string = "mode";
  public isNroTable: boolean = false;
  public imagemRest: any;
  public isTablet: boolean = false;
  public isSmall: boolean = false;
  public isConsumo: boolean = false;
  public restauranteId: any;
  public restaurante: any;
  //public isNroMesa: boolean = false
  private configs$: BehaviorSubject<AppConfig> = new BehaviorSubject({
    mode: "menu",
    cnpj: "32759021000181",
  });

  constructor(
    private auth: AuthService,
    private cart: CartService,
    private qrcodeScanner: QrcodeService,
    private restService: RestaurantService,
    private router: Router,
    private storage: StorageService,
    platform: Platform
  ) {
    this.web = platform.is("mobileweb") || platform.is("desktop");
    this.storage
      .getMany<AppConfig>([
        environment.storage.v2.mode,
        environment.storage.v2.cnpj,
        environment.storage.v2.tableNumber,
        environment.storage.v2.preferences,
      ])
      .subscribe({
        next: (configs) => this.configs$.next(configs),
      });
    this.configs$.subscribe({
      next: (configs) => {
        if (configs)
          forkJoin([
            this.storage.saveV2(
              environment.storage.v2.mode,
              configs?.mode || "menu"
            ),
            this.storage.saveV2(
              environment.storage.v2.cnpj,
              configs?.cnpj || "32759021000181"
            ),
            this.storage.saveV2(
              environment.storage.v2.tableNumber,
              configs?.reference
            ),
            this.storage.saveV2(
              environment.storage.v2.preferences,
              configs?.preferences
            ),
          ]).subscribe();
      },
    });
    console.log(this.configs$, "confgi$");
  }

  /**
   * Seta todas as configurações necessárias para o funcionamento do app
   * @param configs
   */
  config(configs?: {
    mode?: "zap" | "pedido" | "qrcode" | "menu" | "ficha" | "gestor";
    cnpj?: string;
    phoneNumber?: number;
    prodCardStyle?: number;
    tableNumber?: number;
    tablePassword?: number;
  }): Observable<void> {
    const success$ = new BehaviorSubject<void>(null);
    if (configs?.prodCardStyle == 1) configs.prodCardStyle = 2; // TODO apaga essa gambs do card

    const zimmer = this.storage.get<ZimmerStorage>() || new ZimmerStorage();
    if (configs) {
      zimmer.mode = configs.mode;
      zimmer.cnpj = configs.cnpj;
      zimmer.phoneNumber = configs.phoneNumber;
      zimmer.preferences.categoryProdCardStyle = configs.prodCardStyle || 2;
      // zimmer.tab = configs.prodCardStyle || 1
      zimmer.tableNumber = configs.tableNumber;
    }
    if (zimmer.mode && zimmer.cnpj) {
      console.log("zimmer.mode", zimmer.mode);
      console.log("zimmer.cnpj", zimmer.cnpj);

      this.storage.prepare(zimmer);
      // forkJoin([
      //   this.storage.saveV2(environment.storage.v2.mode, zimmer.mode),
      //   this.storage.saveV2(environment.storage.v2.cnpj, zimmer.cnpj),
      //   this.storage.saveV2(environment.storage.v2.tableNumber, zimmer.tableNumber),
      //   this.storage.saveV2(environment.storage.v2.preferences, zimmer.preferences),
      //   new Observable(subscriber => subscriber.next(this.auth.changeTable(zimmer.tableNumber)))
      // ]).subscribe({
      //   next: () => success$.next()
      // })
    } else {
      this.qrcodeScanner
        .scan({
          useNativePlugin: !this.web,
          side: "back",
          validation: (text: string) => {
            return (
              text.substr(0, environment.qrcodeConfigValidationLink.length) ==
                environment.qrcodeConfigValidationLink ||
              text.includes(environment.storage.appMode + ": ")
            );
          },
        })
        .subscribe({
          next: (text) => {
            if (text.substr(0, 4).includes("http")) {
              this.router.navigateByUrl(text);
              success$.next();
              // success$.complete()
            } else if (text.includes(environment.storage.appMode + ": ")) {
              const jsonConfig: ZimmerStorage = JSON.parse(text);
              this.storage.prepare(jsonConfig);
              success$.next();
              // success$.complete()
            }
          },
        });
    }

    return success$.asObservable();
  }

  getConfig(): ZimmerStorage {
    return this.storage.get();
  }

  getConfigV2() {
    return this.configs$.asObservable();
  }

  configV2(configs?: AppConfig): Observable<void[]> {
    this.cart.clear();

    //this.storage.clearV2();

    /*
    const storageQuerys = [
      environment.storage.v2.cnpj,
      environment.storage.v2.mode,
      environment.storage.v2.tableNumber,
      environment.storage.v2.preferences,
    ].map(key => this.storage.deleteV2(key).pipe(take(1)))
    return forkJoin(storageQuerys).pipe(
      take(1),
      mergeMap(saveQuerys => {
        this.storage.saveV2(environment.storage.v2.cnpj, configs.cnpj)
        this.storage.saveV2(environment.storage.v2.mode, configs.mode)
        this.storage.saveV2(environment.storage.v2.tableNumber,configs.reference)
        this.storage.saveV2(environment.storage.v2.preferences,configs.preferences)

        this.configs$.next(configs)
        return forkJoin(saveQuerys).pipe(take(1))
      }),
    );
    */
    // const storageQuerys = Object.keys(environment.storage.v2).map(key => this.storage.deleteV2(key).pipe(take(1)))
    const storageQuerys = [
      environment.storage.v2.cnpj,
      environment.storage.v2.mode,
      environment.storage.v2.tableNumber,
      environment.storage.v2.preferences,
    ].map((key) => this.storage.deleteV2(key).pipe(take(1)));
    return forkJoin(storageQuerys).pipe(
      take(1),
      map(() =>
        [
          environment.storage.v2.cnpj,
          environment.storage.v2.mode,
          environment.storage.v2.tableNumber,
          environment.storage.v2.preferences,
        ].map((storageKey) =>
          this.storage.saveV2(
            environment.storage.v2[storageKey],
            configs?.[environment.storage.v2[storageKey]]
          )
        )
      ),
      mergeMap((saveQuerys) => {
        this.configs$.next(configs);
        return forkJoin(saveQuerys).pipe(take(1));
      })
    );
  }

  initServices(): Observable<void[]> {
    return forkJoin([
      this.restService.init() /*.pipe(tap(() => console.log('init do rest iniciado')))*/,
      this.auth.init() /*.pipe(tap(() => console.log('init do tableNumber iniciado')))*/,
    ]).pipe(take<void[]>(1) /*, tap(() => console.log('Services iniciados'))*/);
  }

  getMode(): Observable<string> {
    return this.storage.getV2<string>(environment.storage.v2.mode);
  }

  /**
   * Retorna qual modo (user | rest) que o app está sendo usado
   */
  getBreed(): Observable<"user" | "rest"> {
    return this.storage.getV2<"user" | "rest">(environment.storage.v2.breed);
  }
}

export interface AppConfig {
  mode: string;
  cnpj: string;
  preferences?: any;
  reference?: number;
}
