import { BaseModel } from '../base-class.model'
import { LocationResult } from './location-result.model'

export class Localizacao extends BaseModel<Localizacao> {
    bairro: string
    cep: string
    localidade: string
    logradouro: string
    numero?: string
    uf: string
    complemento?: string

    static isValid(address: Localizacao) {
        const errors = []
        if(address.cep && (address.cep || '').replace(/[^0-9]/gm, '').length != 8) errors.push('CEP inválido');
        if((address.bairro || '').length < 1) errors.push('Bairro vazio');
        if((address.logradouro || '').length < 1) errors.push('Logradouro vazio');
        if((address.localidade || '').length < 3) errors.push('Nome de cidade muito curto');
        if((address.numero || '').length < 1) errors.push('Número vazio');
        return { isValid: errors.length == 0, errors }
    }

    static assignLocation(location: LocationResult): Localizacao {
        const address = new Localizacao()
        address.bairro = location.subLocality
        address.cep = location.postalCode
        address.localidade = location.subAdministrativeArea
        address.logradouro = location.thoroughfare
        address.uf = Object.keys(Localizacao.uf).find(key => Localizacao.uf[key] == location.administrativeArea)
        return address
    }

    private static uf: {
        AC: 'Acre',
        AL: 'Alagoas',
        AM: 'Amazonas',
        AP: 'Amapá',
        BA: 'Bahia',
        CE: 'Ceará',
        DF: 'Distrito Federal',
        ES: 'Espírito Santo',
        GO: 'Goiás',
        MA: 'Maranhão',
        MG: 'Minas Gerais',
        MS: 'Mato Grosso do Sul',
        MT: 'Mato Grosso',
        PA: 'Pará',
        PB: 'Paraíba',
        PE: 'Pernambuco',
        PI: 'Piauí',
        PR: 'Pará',
        RJ: 'Rio de Janeiro',
        RN: 'Rio Grande do Norte',
        RO: 'Rondônia',
        RR: 'Roraima',
        RS: 'Rio Grande do Sul',
        SC: 'Santa Catarina',
        SE: 'Sergipe',
        SP: 'São Paulo',
        TO: 'Tocantins',
    } = {
        AC: 'Acre',
        AL: 'Alagoas',
        AM: 'Amazonas',
        AP: 'Amapá',
        BA: 'Bahia',
        CE: 'Ceará',
        DF: 'Distrito Federal',
        ES: 'Espírito Santo',
        GO: 'Goiás',
        MA: 'Maranhão',
        MG: 'Minas Gerais',
        MS: 'Mato Grosso do Sul',
        MT: 'Mato Grosso',
        PA: 'Pará',
        PB: 'Paraíba',
        PE: 'Pernambuco',
        PI: 'Piauí',
        PR: 'Pará',
        RJ: 'Rio de Janeiro',
        RN: 'Rio Grande do Norte',
        RO: 'Rondônia',
        RR: 'Roraima',
        RS: 'Rio Grande do Sul',
        SC: 'Santa Catarina',
        SE: 'Sergipe',
        SP: 'São Paulo',
        TO: 'Tocantins',
    }
}