import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Payment } from '../models/payment/payment.model';
import { RestaurantService } from './restaurant.service';
import { User } from '../models/user.model';
import { cardConsumo } from '../models/payment/consumo.model';
import { map } from 'rxjs/operators';
declare var PagSeguroDirectPayment

export interface PaymentResponse {
  errors: boolean
  message: string
  nsu: string
}

export interface ConsumoResponse {
  aproved: boolean
  mensage: string
  valor: number
  desconto: number
  nsu?: string
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private http: HttpClient,
    private restaurantService: RestaurantService
  ) { }

  /**
   * Realiza o pagamento especificado
   * @param { Payment } payment 
   */
  pay(payment: Payment): Observable<PaymentResponse> {
    return this.http.post<PaymentResponse>(`${environment.SERVERHOST}/payment/doPayment`, payment)
  }

  /**
   * @deprecated
   */
  initSession() {
    return this.http.post(`${environment.SERVERHOST}/payment/doSession/${this.restaurantService.getRestaurant().restauranteId}`, new Object())
      .subscribe((response: any) => {
        PagSeguroDirectPayment.setSessionId(response.message)
      })
  }

  /**
   * @deprecated
   */
  getCardInformation(data: { userName?: string, cardNumber?: number, cardCvv?: number, expiration?: string }) {
    const subject: Subject<any> = new Subject()
    subject.next(false)

    PagSeguroDirectPayment.getBrand({
      cardBin: data.cardNumber,
      success: response => {
        const cardBrand = response.brand.name
        const dateExpiration = data.expiration.split('/')
        const values = new Object() as any
        values.cardNumber = data.cardNumber,
        values.brand = cardBrand,
        values.cvv = data.cardCvv,
        values.monthExp = dateExpiration[0]
        values.yearExp = dateExpiration[1]
        const user = new User({ nome: data.userName })
        this.getCardToken(values).subscribe(result => subject.next(result))
      }
    })
    return subject
  }

  /**
   * @param values 
   * @param user 
   * @deprecated
   */
  private getCardToken(values: { userName?: string, cardNumber?: number, cvv?: number, brand: string, monthExp: string, yearExp: string }, user?: User) {
    const subject: Subject<any> = new Subject();
    subject.next(false)

    PagSeguroDirectPayment.createCardToken({
      cardNumber: values.cardNumber,
      brand: values.brand,
      cvv: values.cvv,
      expirationMonth: values.monthExp,
      expirationYear: values.yearExp,
      success: response => {
        const token = response.card.token
        // this.cart.doOrderWithOnlinePayment(response.card, user)
        subject.next({ card: response.card, user })
      }
    })
    return subject
  }

  getSaldoConsumo(restId: number, cardNro: number):Observable<cardConsumo>{
    return this.http.get<cardConsumo>(`${environment.SERVERHOST}/CartaoConsumo/obterByConsuNr/${restId}/${cardNro}`)
    .pipe(map(r => r))
  }

  payConsumo(payment: Payment): Observable<ConsumoResponse> {
    const consumo = {
      numeroCartao: payment.numeroCartao,
      valor: payment.saldo,
      tipoMov: payment.tipoMov,
      restauranteId:payment.restauranteId
    }
    return this.http.post<ConsumoResponse>(`${environment.SERVERHOST}/CartaoConsumo/adicionarMov`, consumo)
  }
  
}
