import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartNewPageRoutingModule } from './cart-new-routing.module';

import { CartNewPage } from './cart-new.page';
import { QrcodeScannerPageModule } from '../qrcode-scanner/qrcode-scanner.module';
import { UserInformationPageModule } from './user-information/user-information.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CartNewPageRoutingModule,
    QrcodeScannerPageModule,
    UserInformationPageModule
  ],
  declarations: [CartNewPage],
  exports: [CartNewPage]
})
export class CartNewPageModule {}
