import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AlertController, ModalController } from "@ionic/angular";

import { Card } from "../../../models/payment/card.model";
import {
  dayNamesBr,
  dayShortNamesBr,
  monthNamesBr,
  monthShortNamesBr,
} from "../../../shared/components/v2/input-datetime/input-datetime.component";
import { MaskService } from "../../../services/providers/mask.service";

@Component({
  selector: "app-user-info-drive",
  templateUrl: "./user-info-drive.page.html",
  styleUrls: ["./user-info-drive.page.scss"],
})
export class UserInfoDrivePage implements OnInit {
  public form: FormGroup;
  dateNow: Date = new Date();
  dayNames: string[] = dayNamesBr;
  dayShortNames: string[] = dayShortNamesBr;
  monthNames: string[] = monthNamesBr;
  monthShortNames: string[] = monthShortNamesBr;

  constructor(
    private alertCtrl: AlertController,
    private formBuilder: FormBuilder,
    public mask: MaskService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      userName: [null, [Validators.required, Validators.minLength(3)]],
      licensePlate: [null, [Validators.required, Validators.minLength(7)]],
      method: [1, [Validators.required]],
      cpf: [null, [Validators.required, Validators.minLength(14)]],
      phone: [null, []],
      card: this.formBuilder.group({
        cardNumber: [null, [Validators.required, Validators.minLength(16)]],
        cvv: [null, [Validators.required, Validators.minLength(3)]],
        dateExp: [null, [Validators.required, Validators.minLength(5)]],
      }),
    });
  }

  confirm() {
    const formattedData = {
      userName: this.form.value.userName,
      licensePlate: this.form.value.licensePlate,
      phone: (this.form.value.phone + "").replace(/[^0-9]/gm, ""),
      cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
      method: this.form.value.method,
      card: new Card({
        cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
        cvv: this.form.value.card?.cvv,
        monthExp: this.form.value.card?.dateExp?.substr(0, 2),
        yearExp: this.form.value.card?.dateExp?.substr(3, 2),
        titular: this.form.value.userName,
        num: this.form.value.card?.cardNumber?.replace(/[^0-9]/gm, ""),
      }),
    };
    this.modalCtrl.dismiss(formattedData, "confirm");
  }

  /**
   * Altera os validadores do form para que o botão de enviar pedido seja liberado
   */
  toggleValidators(method: number): void {
    // Pega os campos
    const cardNumberField = this.form.get("card.cardNumber");
    const cvvField = this.form.get("card.cvv");
    const dateExpField = this.form.get("card.dateExp");
    const cpfField = this.form.get("cpf");

    // Checa o método de pagamento
    if (method == 2) {
      // Limpa validadores
      cardNumberField.clearValidators();
      cvvField.clearValidators();
      dateExpField.clearValidators();
      cpfField.clearValidators();
    } else {
      // Adiciona os validadores
      cardNumberField.setValidators([
        Validators.required,
        Validators.minLength(16),
      ]);
      cvvField.setValidators([Validators.required, Validators.minLength(3)]);
      dateExpField.setValidators([
        Validators.required,
        Validators.minLength(5),
      ]);
      cpfField.setValidators([Validators.required, Validators.minLength(14)]);
    }

    // Atualiza os valores dos campos e faz a validação deles
    cardNumberField.updateValueAndValidity();
    cvvField.updateValueAndValidity();
    dateExpField.updateValueAndValidity();
    cpfField.updateValueAndValidity();
  }

  /**
   * Pergunta se o usuário verificou os dados digitados
   */
  next() {
    this.alertCtrl
      .create({
        message: "Não esqueça de revisar seus dados",
        buttons: [
          {
            text: "PROSSEGUIR",
            handler: () => this.confirmData(),
          },
          {
            text: "REVISAR INFORMAÇÕES",
          },
        ],
      })
      .then((alert) => alert.present());
  }

  confirmData() {
    const formattedData = {
      userName: this.form.value.userName,
      licensePlate: this.form.value.licensePlate,
      phone: (this.form.value.phone + "").replace(/[^0-9]/gm, ""),
      cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
      method: this.form.value.method,
      card: new Card({
        cpf: this.form.value.cpf?.replace(/[^0-9]/gm, ""),
        cvv: this.form.value.card?.cvv,
        monthExp: this.form.value.card?.dateExp?.substr(5, 2),
        yearExp: this.form.value.card?.dateExp?.substr(2, 2),
        titular: this.form.value.userName,
        num: this.form.value.card?.cardNumber?.replace(/[^0-9]/gm, ""),
      }),
    };
    this.modalCtrl.dismiss(formattedData, "confirm");
  }

  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }
}
