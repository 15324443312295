import { Injectable } from '@angular/core';
import { AlertController, iosTransitionAnimation, ToastController } from '@ionic/angular';
import { Utils } from '../shared/util';


@Injectable({
  providedIn: 'root'
})
export class ToastaddService {
  toast: HTMLIonToastElement;

  constructor(private toastCtrl: ToastController,
  private alertContrl: AlertController,
  private utils: Utils) { }

  async displayToastAdd(texto: string) {
    this.toast = await this.toastCtrl.create({
      position: 'bottom',
      duration: 3000,
      cssClass: "toast-add",
      animated: true,
      message:  texto,
      mode: "ios",
      buttons:[
        {
          side: 'end',
          icon: 'arrow-forward',
          role: 'cancel'
        }
      ]
    });
    this.toast.present();
    const dismiss =  await (await this.toast).onDidDismiss();
      if(dismiss.role === 'timeout') {
        this.utils.animate('shake-horizontal', 'confirm-label', 'class', 800)
      }
  }
 
  closeToast(){
    try {
      this.toast.dismiss();
    } catch(e) {}
  }

  alertaMsg(msg: string, msg2?: string){
    this.alertContrl.create({
      message: msg,
      header: msg2,
      mode: 'ios',
      cssClass:"alerta-css",
      buttons: [
        {
          text: 'OK',
          handler: () => {}
        }
      ]
    }).then(alert => alert.present())
  }

}
