import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, of } from "rxjs";
import { flatMap, map, tap } from "rxjs/operators";

import { Platform } from "@ionic/angular";

import { AppComponent } from "../../app.component";
import { CartService } from "../../services/cart.service";
import { CategoryService } from "../../services/category.service";
import { RestaurantService } from "../../services/restaurant.service";
import { Category } from "../../interfaces/category.interface";
import { ProductService } from "../../services/product.service";
import { StorageService } from "../../services/storage.service";
import { ConfigurationService } from "../../services/configuration.service";
import { environment } from "../../../environments/environment";
import { QrcodeService } from "../../services/qrcode.service";
import { ZimmerStorage } from "../../models/zimmerStorage.model";
import { Utils } from "src/app/shared/util";

@Component({
  selector: "app-home",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
  /**
   * Usado para dizer quando tudo foi carregado e renderizado para mostrar o ion-header
   */
  load: boolean;
  web: boolean;
  /**
   * Não é pra abrir o cardápio
   */
  notOpen: boolean;
  /**
   * Mensagem a ser mostrada caso a página fique na home
   */
  message: string;
  /**
   * Caminho do gif que será renderizado na home
   */
  cat: string;

  constructor(
    protected appComponent: AppComponent,
    public cart: CartService,
    private config: ConfigurationService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private qrcodeScanner: QrcodeService,
    private restaurantService: RestaurantService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageService,
    private utils: Utils,
    platform: Platform
  ) {
    this.web = platform.is("mobileweb") || platform.is("desktop");
  }

  /**
   * Configura o app conforme a url
   */
  ngOnInit() {
    this.route.params
      .pipe(
        flatMap((params) => {
          const config = {
            mode: params[environment.routeParams.appMode],
            cnpj: params[environment.routeParams.cnpj],
            phoneNumber: params[environment.routeParams.phoneNumber],
            prodCardStyle: params[environment.routeParams.prodCardStyle],
            tableNumber: params[environment.routeParams.tableNumber],
          };
          return this.config.config(config).pipe(map(() => params));
        }),
        flatMap((params) =>
          this.restaurantService
            .getByCnpj(params[environment.routeParams.cnpj])
            .pipe(tap((rest) => this.restaurantService.setRestaurant(rest)))
        ),
        flatMap((restaurant) => this.loadCategories(restaurant.restauranteId))
      )
      .subscribe({
        next: (categories) => {
          this.categoryService.setCategories(categories);
          this.load = true;
        },
      });
  }

  /**
   * Redireciona pra página de categorias
   */
  ionViewDidEnter() {
    console.log("Entrou no scan");

    const configs = this.config.getConfig();
    if (configs?.mode) {
      const rest = this.restaurantService.getRestaurant();
      /*if(rest.isOperation && rest.estaAtendendo)*/ this.router.navigate([
        "home",
        "categorias",
      ]);
    } else {
      this.qrcodeScanner
        .scan({
          useNativePlugin: !this.web,
          side: "back",
        })
        .subscribe({
          next: (text) => {
            if (text.substr(0, 4).includes("http")) {
              if (this.web) window.location.href = text;
              else {
                const parameters = text.split("/");
                const mode = parameters[3] as
                  | "zap"
                  | "qrcode"
                  | "pedido"
                  | "menu"
                  | "ficha"
                  | "gestor";
                const cnpj = parameters[4];
                const cardStyle =
                  mode == environment.appModes.table
                    ? parameters[5]
                    : parameters[6];
                const tableNumber =
                  mode == environment.appModes.table
                    ? Number(parameters[6] || parameters[5] || 0)
                    : undefined;
                const phoneNumber =
                  mode == environment.appModes.zap
                    ? Number(parameters[5])
                    : undefined;

                this.config
                  .config({
                    cnpj,
                    mode,
                    prodCardStyle: Number(cardStyle),
                    tableNumber,
                    phoneNumber,
                  })
                  .subscribe({
                    next: () => {
                      this.router.navigate(["home", "categorias"]);
                    },
                    error: (e) => alert(e),
                  });
              }
            } else if (text.includes(environment.storage.appMode + ": ")) {
              const jsonConfigs: ZimmerStorage = JSON.parse(text);
              this.config
                .config({
                  cnpj: jsonConfigs.cnpj,
                  mode: jsonConfigs.mode,
                  phoneNumber: jsonConfigs.phoneNumber,
                  prodCardStyle: jsonConfigs.preferences.categoryProdCardStyle,
                  tableNumber: jsonConfigs.tableNumber,
                })
                .subscribe({
                  next: () => this.router.navigate(["home", "categorias"]),
                  error: (e) => this.utils.showToast(e),
                });
            }
          },
          error: (e) => this.utils.showToast(e),
        });
    }
  }

  /**
   * Abre o um modal do carrinho
   */
  openCart() {
    const items = this.cart.getAllItems();
    this.cart.showCart(items);
  }

  /**
   * @returns Subscription de um observable das categorias com os produtos
   */
  loadCategories(restauranteId: number): Observable<any> {
    return this.productService.getAll().pipe(
      map((r) => {
        // retira os resultados da requisiçao
        const result: any[] = (r as any).results;

        // Mapeia os resultados e retorna as categorias com os campos organizados para o funcionamento do app
        const categories = result.map((v) => {
          let category: Category = new Object() as Category;
          const keys = Object.keys(v.categoria);
          keys.forEach((key) => {
            category[key] = v.categoria[key];
          });
          category.produtos = v.produtos;
          // category.complementos = v.complemento;
          return category;
        });
        return categories;
      })
    );
  }
}
