// export interface Complemento {
//     complementoId?: number;
//     referenciaId?: number;
//     restauranteId?: number;
//     categoriaId?: number;
//     descricao?: string;
//     valor?: number;
// }

import { BaseModel } from "../models/base-class.model";

export class ProductOptionType extends BaseModel<ProductOptionType> {
  produtosOpcaoTipoId: number;
  restauranteId: string;
  tipo: number;
  nome: string;
  quantidade: number;
  qtdeMax: number;
  sequencia: number;
  obrigatorio: boolean;
  checked: boolean;
  produtoOpcaos: ProductOption[];
}

export class ProductOption extends BaseModel<ProductOption> {
  produtosOpcaoId: string;
  produtosOpcaoTipoId: number;
  restauranteId?: string;
  sequencia?: number;
  valor: number;
  quantidade: number;
  nome: string;
}
