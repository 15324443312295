import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { ScannerGuard } from './shared/guards/scanner.guard';
import { environment } from '../environments/environment';
import { BreedGuard } from './shared/guards/breed.guard';
import { ScannerV2Guard } from './shared/guards/scanner-v2.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'v2'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [ScannerGuard]
  },
  {
    path: environment.routePaths.scanner,
    loadChildren: () => import('./pages/scanner/scanner.module').then(m => m.ScannerPageModule)
  },
  {
    path: 'closed',
    loadChildren: () => import('./pages/closed/closed.module').then( m => m.ClosedPageModule)
  },
  {
    path: 'v2',
    loadChildren: () => import('./v2/v2.module').then(m => m.V2Module),
    //canActivate: [BreedGuard]
  },
  {
    path: 'sorry',
    loadChildren: () => import('./pages/sorry/sorry.module').then( m => m.SorryPageModule)
  },
  {
    path: ':mode/:cnpj',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canActivate: [ScannerGuard]
  },
  {
    path: 'gallery',
    loadChildren: () => import('./v2/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./pages/scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'breed',
    loadChildren: () => import('./v2/breed/breed.module').then( m => m.BreedPageModule)
  },  {
    path: 'v2-item-complementosmeiomeio',
    loadChildren: () => import('./shared/components/v2/v2-item-complementosmeiomeio/v2-item-complementosmeiomeio.module').then( m => m.V2ItemComplementosmeiomeioPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
