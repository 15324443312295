import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap, map, flatMap } from "rxjs/operators";

import { BaseService, ResponseApi } from "../shared/base.service";
import { environment } from "../../environments/environment";
import { Product } from "../models/produto.model";
import { ProductOptionType } from "../interfaces/product-option.interface";
import { RestaurantService } from "./restaurant.service";
import { Half } from "../models/half.model";
import { Category } from "../interfaces/category.interface";
import { Group } from "../models/group.model";
import { Restaurant } from "../models/restaurant.model";

@Injectable({
  providedIn: "root",
})
export class ProductService extends BaseService<Product | CategoryResponseApi> {
  constructor(
    protected http: HttpClient,
    private restaurantService: RestaurantService
  ) {
    super(http, "produto");
  }

  /**
   * Retorna todos os produtos da API filtrados por restauranteId
   */
  getAll(restId?: number): Observable<ResponseApi<CategoryResponseApi>> {
    return this.http
      .get<ResponseApi<CategoryResponseApi>>(
        `${environment.SERVERHOST}/produto/produtos/${
          restId || this.restaurantService.getRestaurant().restauranteId
        }/999/1`
        // {
        // 	headers: { authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('auth'))?.token || '' },
        // 	reportProgress: false,
        // 	observe: 'body',
        // 	responseType: 'json',
        // }
      )
      .pipe(
        tap(
          (v) => {
            return v;
          },
          (e) => {
            // this.handleError(e, 'getAll');
            return [];
          }
        )
      );
  }

  /**
   * Atualiza um produto
   * @param prod
   */
  // update(prod: Product): Observable<{ errors: boolean, message: string }> {
  //     return super.update(prod)
  // }

  /**
   * Busca na API as opções do produto
   */
  getOptions(productId: number): Observable<ProductOptionType[]> {
    const restauranteId = this.restaurantService.getRestaurant()?.restauranteId;
    return this.http
      .get<ResponseApi<ProductOptionType>>(
        `${environment.SERVERHOST}/produtoOpcao/obterByProdutoId/${restauranteId}/${productId}`
      )
      .pipe(
        map((data) => {
          return data.results;
        })
      );
  }

  /**
   * Busca os meio a meios do produto pelo ID
   */
  getHalfs(productId: number): Observable<Half[]> {
    const restauranteId = this.restaurantService.getRestaurant()?.restauranteId;
    return this.http
      .get<ResponseApi<Half>>(
        `${environment.SERVERHOST}/produto/meiomeios/${restauranteId}/${productId}/999/1`
      )
      .pipe(map((response) => response.results));
  }

  /**
   * Busca uma lista de Grupos de Cateogorias
   * @param deepLevel Serve dizer à API até qual nível de escopo o JSON virá.
   * @Exemplo deepLevel == 0 (retornará apenas os grupos), deepLevel == 1 (retornará grupos com suas respectivas categorias)
   */
  getGroups(restId: number, deepLevel: number = 0): Observable<Group[]> {
    return this.http.get<Group[]>(
      `${environment.SERVERHOST}/grupoproduto/byRestId/${restId}`,
      {
        headers: {
          deepLevel: deepLevel + "",
        },
      }
    );
  }

  getGroupsByMode(restId: number, deepLevel: number = 0, mode:string): Observable<Group[]> {
    let rota:string = '';
    console.log(mode, rota, "ANTES DO IF");
    if(mode === 'gestor' || mode==='pedido' || mode ==='zap'){
      rota = 'rotaDelivery'
    } else if (mode === 'qrcode' || mode === 'menu'){
      rota = 'rotaMesa'
    }else if(mode === 'ficha'){
      rota = 'rotaFicha'
    }
    console.log(mode, rota, "DEPOIS DO IF");
    
    return this.http.get<Group[]>(
      `${environment.SERVERHOST}/grupoproduto/byRestIdMode/${restId}/${rota}`,
      {
        headers: {
          deepLevel: deepLevel + "",
        },
      }
    );
  }

  /**
   * Busca uma lista de Categorias de Produtos
   * @param deepLevel Serve dizer à API até qual nível de escopo o JSON virá.
   * @Exemplo deepLevel == 0 (retornará apenas os grupos), deepLevel == 1 (retornará grupos com suas respectivas categorias)
   * @manutenção
   */
  getCategories() {}

  /**
   * Procura os produtos pelo ID da categoria
   */
  getByCategoryId(catId: number) {
    return this.http.get<Product[]>(
      `${environment.SERVERHOST}/produto/byCategoryId/${catId}`
    );
  }

  /**
   * Pega os produtos aos poucos
   */
  getLazy(
    quantity: number,
    catId: number,
    lastProdName: string = "*"
  ): Observable<Product[]> {
    const rest$ = this.restaurantService.getRest();
    return rest$.pipe(
      flatMap((rest) =>
        this.http.get<Product[]>(
          `${environment.SERVERHOST}/produto/lazy/${rest.restauranteId}/${quantity}/${catId}/${lastProdName}`
        )
      )
    );
  }

  /**
   * Pega os complementos dos meios meios
   */
  getProductsHalfComplements(productId: number): Observable<any> {
    const rest$ = this.restaurantService.getRest();
    return rest$.pipe(
      flatMap((rest) =>
        this.http.get<any>(
          `${environment.SERVERHOST}/produtoopcao/obterByProdutoId/${rest.restauranteId}/${productId}`
        )
      )
    );
  }
}

export interface CategoryResponseApi {
  categoria: Category;
  complemento: [];
  produtos: Product[];
}
