import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalController, IonInput } from '@ionic/angular';

import { AuthService } from '../../auth.service';
import { environment } from '../../../../environments/environment';
import { RestaurantService } from '../../../services/restaurant.service';
import { StorageService } from '../../../services/storage.service';
import { Utils } from '../../util';
import { ZimmerStorage } from '../../../models/zimmerStorage.model';

/**
 * @deprecated
 */
@Component({
  selector: 'app-set-details',
  templateUrl: './set-details.component.html',
  styleUrls: ['./set-details.component.scss'],
})
export class SetDetailsComponent {

  constructor(
    private auth: AuthService,
    private modalController: ModalController,
    private restaurantService: RestaurantService,
    private router: Router,
    private storage: StorageService,
    public utils: Utils
  ) { }

  /**
   * Injeta todas as informações necessárias para o funcionamento do app por credenciais
   * @param mode Peidos serão feitos por 'zap'|'normal'
   * @param cnpj 
   * @param password 
   * @param table Mesa
   * @param waiterPassword Senha requisitada ao tentar trocar de mesa (somente caso o tipo escolhido seja 'credentials')
   */
  confirmDetails(
    mode: 'zap'|'qrcode',
    cnpj: string|number,
    password: string|number,
    table: string|number,
    waiterPassword: string|number,
    item: string|number
  ) {

    
    console.log("Entrou no scan");
        
    
    const zimmer: ZimmerStorage = new Object() as ZimmerStorage;
    const phoneNumber = (document.getElementById('phoneNumber') as unknown as IonInput)?.value;
    zimmer.mode = mode;
    // Validador
    let successCount: number  = 0;
    try {
      if((phoneNumber+'').replace(/[^0-9]/gm, '').length < 9 && mode == 'zap') throw'Número de celular inválido'; else {
        zimmer.phoneNumber = Number((phoneNumber+'').replace(/[^0-9]/gm, ''));
        successCount++;
      }

      environment.RES_CNPJ = (cnpj + '').replace(/[^0-9]/gm, '');
      zimmer.cnpj = environment.RES_CNPJ
      // Checa o tamanho do CNPJ e caso seja 14 aumenta o validador em 1
      if(environment.RES_CNPJ.length != 14) throw 'CNPJ inválido'; else successCount++;
  
      // Deixa somente números na mesa e checa se tem pelo menos um caractere, se passar adiciona +1 no validador
      const tableId = table + '';
      if(tableId.replace(/[^0-9]/gm, '').length == 0) {
        throw 'Número de mesa inválido'
      } else {
        zimmer.tableNumber = Number(tableId.replace(/[^0-9]/gm, ''));
        successCount++;
      }
  
      // Deixa somente números e checa se a senha do garçom tem 4 caracteres, se tiver validador +1
      const pwd = (waiterPassword + '').replace(/[^0-9]/gm, '');
      if(pwd.length != 4) {
        throw 'A senha do garçom deve ser 4 números'
      } else {
        successCount++;
      }

      // Checa se o CNPJ e a senhaMaster batem, se sim validador +1
      this.restaurantService.login(environment.RES_CNPJ, password.toString()).subscribe(data => {
        // this.toastController.create({
        //   message: JSON.stringify(data) + 'home module',
        //   duration: 3000,
        //   color: 'primary'
        // }).then(toast => toast.present());
        if(data.restaurant) {
          // this.auth.setUserRestaurante(data.restaurant, data.token)
          zimmer.cnpj = data.restaurant.cnpj;
          successCount++;
        } else {
          this.utils.showToast({ message: 'Login e/ou senha inválidos', duration: 1000 })
          // this.confirmDetails(mode, cnpj, password, table, waiterPassword, phoneNumber);
        }

        // Se o validador bater 5, inicia o app
        if(successCount == 5) {
          zimmer.preferences.categoryProdCardStyle = item as number
          this.storage.prepare(zimmer)
          this.auth.changeTable(this.storage.get('tableNumber'))
          // this.router.navigate([environment.RES_CNPJ])
          this.router.navigate([zimmer.cnpj])
          this.modalController.dismiss()
        }
      })
      // const mode = (document.getElementById('init-mode') as unknown as IonRadioGroup)?.value;
      // if(mode) {
      //   zimmer.mode = mode;
      //   successCount++;
      // } else {
      //   throw 'Escolha um modo'
      // }
    } catch(error) {
      console.log(error)
      this.utils.showToast({ message: error })
      return false
    }

  }

  // /**
  //  * Converte os valores para o tipo passado
  //  * @param element 
  //  * @param mode Tipo da conversão
  //  */
  // maskInput(element: IonInput, mode) {
  //   if(mode !='waiterPassword') {
  //     element.value = this.utils.convert(element.value, mode);
  //   } else {
  //     element.value = this.utils.convert(element.value, 'onlyNumber').substr(0, 4);
  //   }
  // }

  /**
   * Fecha este modal
   */
  closeModal() {
    this.modalController.dismiss({ incomplete: true })
  }
}
