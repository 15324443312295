import { ItemCart } from '../../interfaces/itemCart.interface';
import { SendOrderApi } from '../../interfaces/order.interface'
import { BaseModel } from '../base-class.model';

export class Cart extends BaseModel<Cart> {
    items: ItemCart[]
    discount: number
    date?
    orderObj?: SendOrderApi

    constructor(value?: Cart) {
        super()
        this.items = value?.items || []
        this.discount = value?.discount || 0
    }
}