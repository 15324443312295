import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { flatMap, map, take, tap } from "rxjs/operators";

import { BaseService } from "../shared/base.service";
import { Restaurant } from "../models/restaurant.model";
import { environment } from "../../environments/environment";
import { Flyer } from "../models/flyer.model";
import { RestauranteRating } from "../models/rate.model";
import { ConfigurationService } from "./configuration.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class RestaurantService extends BaseService<Restaurant> {
  private restaurant: Restaurant;
  private restaurant$: BehaviorSubject<Restaurant> =
    new BehaviorSubject<Restaurant>(null);

  constructor(protected http: HttpClient, private storage: StorageService) {
    super(http, "restaurante");
    // this.config.getConfigV2().pipe(flatMap(configs => this.getByCnpj(configs.cnpj))).subscribe({
    //   next: rest => this.restaurant$.next(rest)
    // })
    // this.restaurant$.subscribe({
    //   next: rest => console.log(rest, 'passando pelo rest')
    // })
    // this.config.getConfigV2().pipe(flatMap(configs => this.getByCnpj(configs.cnpj))).subscribe({
    //   next: rest => this.restaurant$.next(rest)
    // })
  }

  init(): Observable<void> {
    return this.storage.getV2(environment.storage.v2.cnpj).pipe(
      flatMap((cnpj) => {
        console.log("cnpj", cnpj);
        return this.getByCnpj(cnpj);
      }),
      flatMap((rest) => {
        console.log("restaurante", rest);
        this.restaurant$.next(rest);
        return this.storage.saveV2(environment.storage.v2.cnpj, rest.cnpj);
      }),
      take(1)
    );
  }

  /**
   * Busca um restaurante pelo CNPJ
   * @param cnpj
   * @returns Restaurante
   */
  getByCnpj(cnpj: number | string): Observable<Restaurant> {
    return (
      this.http
        .get<Restaurant>(
          `${environment.SERVERHOST}/restaurante/restauranteByCnpj/${cnpj}`
        )
        // return this.http.post<Restaurant>(`http://192.168.1.51:7779/zimmer/restaurante/getone`, { field: 'cnpj', value: cnpj })
        .pipe(
          map(
            (r) => r,
            (e) => {
              this.handleError(e, "getByCnpj");
              return null;
            }
          )
        )
    );
  }

  /**
   * Login do estabelecimento
   * @param login
   * @param password
   */
  login(
    login: string,
    password: string
  ): Observable<{ restaurant: Restaurant; token?: string }> {
    const subject = new Subject<{ restaurant: Restaurant; token?: string }>();
    this.getByCnpj(login).subscribe(
      (data) => {
        this.restaurant = data;
        if (data?.senhaMaster == password) {
          subject.next({
            restaurant: data,
            token: data.token,
          });
        } else {
          subject.next({
            restaurant: null,
          });
        }
      },
      (error) => {
        this.toastController
          .create({
            message: error + "-" + JSON.stringify(error),
            duration: 3000,
            color: "primary",
          })
          .then((toast) => toast.present());
      }
    );
    return subject.asObservable();
  }

  /**
   * Atualiza os dados de um restaurante
   * @param restaurant
   */
  updateRestaurant(restaurant: Restaurant): Observable<boolean> {
    return (
      this.http
        .put(
          `${environment.SERVERHOST}/restaurante/alterarrestaurante`,
          restaurant
        )
        // return this.http.put('http://192.168.1.51:7779/zimmer/restaurante/restauranteId', restaurant)
        .pipe(
          map(
            (data) => {
              if (data) {
                this.setRestaurant(restaurant);
                return true;
              } else {
                this.handleError(null, "Não foi possível atualizar");
                return false;
              }
            },
            (error) => {
              this.handleError(error, "Não foi possível atualizar");
              return false;
            }
          )
        )
    );
  }

  /**
   * Atulaiza as preferências do estabelecimento
   * @param preferences
   * @deprecated
   */
  updatePreferences(preferences: {
    theme: string;
    zimmerimage: any;
    zimmer: string;
  }): Observable<boolean> {
    const res = this.getRestaurant();
    res.themecolor = preferences.theme;
    // res.zimmerimage = hast to convert image;
    res.zimmer = preferences.zimmer;
    return this.updateRestaurant(res);
  }

  /**
   * Injeta um estabelecimento no service
   * @param rest
   */
  setRestaurant(rest: Restaurant): void {
    this.restaurant = rest;
    this.restaurant$.next(rest);
  }

  /**
   * @deprecated
   * @returns Estabelecimento injetado
   */
  getRestaurant(): Restaurant {
    return this.restaurant;
  }

  /**
   * getRestauran da V2
   */
  getRest(): Observable<Restaurant> {
    return this.restaurant$.asObservable();
  }

  /**
   * Retorna se o restaurante está atendendo no momento
   */
  isOpen(rest?: Restaurant): Observable<boolean> {
    return this.getByCnpj(rest?.cnpj || this.restaurant.cnpj).pipe(
      map((rest) => rest.isOperation)
    );
  }

  /**
   * Verifica se o restaurante está permitido a utilizar o Zimmer
   */
  isActive(rest?: Restaurant): Observable<boolean> {
    return this.getByCnpj(rest?.cnpj || this.restaurant.cnpj).pipe(
      map((rest) => rest.isOperation)
    );
  }

  /**
   * Recupera os flyers do Restaurante
   */
  getFlyers(restId?: number): Observable<Flyer[]> {
    if (restId)
      return this.http.get<Flyer[]>(
        `${environment.SERVERHOST}/flyers/byRestId/${restId}`
      );
    else
      return this.restaurant$.pipe(
        flatMap((rest) =>
          this.http.get<Flyer[]>(
            `${environment.SERVERHOST}/flyer/byRestId/${rest.restauranteId}`
          )
        )
      );
  }

  /**
   * Avalia o restaurante
   */
  rate(rate: RestauranteRating): Observable<RestauranteRating> {
    return this.http.post<RestauranteRating>(
      `${environment.SERVERHOST}/restauranteRating`,
      rate
    );
  }

  /**
   * Busca um restaurante pelo Token
   * @param cep
   * @param token
   * @returns Restaurante
   */
  getByToken(cep: string, token: string): Observable<Restaurant> {
    return this.http
      .get<Restaurant>(
        `${environment.SERVERHOST}/restaurante/restaurantbytoken/${cep}/${token}`
      )
      .pipe(
        map(
          (r) => r,
          (e) => {
            this.handleError(e, "getByToken");
            return null;
          }
        )
      );
  }
}
