import { UsergestorService } from "./../../../../services/usergestor.service";
import { Component, EventEmitter, Injectable, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { AlertController, IonInput, IonMenu, ModalController } from "@ionic/angular";
import { Restaurant } from "../../../../models/restaurant.model";
import { CameraService } from "../../../../services/camera.service";
import { RestaurantService } from "../../../../services/restaurant.service";
import { GalleryService } from "../../../../services/providers/gallery.service";
import { AuthService } from "../../../../shared/auth.service";
import { environment } from "../../../../../environments/environment";
import { Utils } from "../../../../shared/util";
import { ConfigurationService } from "../../../../services/configuration.service";
import { CartService } from "../../../../services/cart.service";
import { flatMap } from "rxjs/operators";
import { SideCartComponent } from "../side-cart/side-cart.component";
import { ToastaddService } from "src/app/services/toastadd.service";
import { SigninPage } from "src/app/v2/account/signin/signin.page";
import { Location } from "@angular/common";

@Component({
  selector: "app-v2-top-bar",
  templateUrl: "./v2-top-bar.component.html",
  styleUrls: ["./v2-top-bar.component.scss"],
})
@Injectable()
export class V2TopBarComponent implements OnInit {
  @Input("options") public options: V2TopBarOptions;
  @Input("mode") mode: string = "home";
  @Output("changeTable") changeTable: EventEmitter<void> = new EventEmitter();
  rest$: Observable<Restaurant>;
  tableNumber$: Observable<string>;
  appMode$: Observable<string>;
  account$: Observable<string>;
  ficha$: Observable<string | number>;

  constructor(private alertCtrl: AlertController, private auth: AuthService, private cameraService: CameraService, private cart: CartService, public config: ConfigurationService, private gallery: GalleryService, private restService: RestaurantService, private router: Router, private utils: Utils, private toastAdd: ToastaddService, private modalController: ModalController, private userGestorController: UsergestorService, private location: Location) {}

  /* Remover o comentário do bloco abaixo quando for gerar APK 
  ngOnInit() {
    this.config
      .configV2({
        cnpj: "32759021000181", //CPNJ DO CLIENTE, PEGAR NO 113;
        mode: "qrcode",
        reference: 1,
      })
      .subscribe((result) => {
        this.config.initServices().subscribe((res) => {
          this.rest$ = this.restService.getRest();
          this.tableNumber$ = this.auth.getTableV2();
          this.appMode$ = this.config.getMode();
          this.ficha$ = this.cart.getFichaV2();
        });
      });
  */

  /* comentar as 5 linhas abaixo quando for gerar o APK  */
  ngOnInit() {
    this.rest$ = this.restService.getRest();
    this.tableNumber$ = this.auth.getTableV2();
    this.appMode$ = this.config.getMode();
    this.ficha$ = this.cart.getFichaV2();

    let mesaId = "";
    this.tableNumber$.subscribe((event) => (mesaId = event));
    if (!mesaId) {
      this.router.navigate([environment.routePaths.v2.version, environment.routePaths.v2.scanner]);
    }
  }

  callReturn() {
    this.location.back();
  }

  callOrder() {
    const ionMenu: IonMenu = document.getElementById("side-cart") as any;
    ionMenu?.open?.();

    if (this.config.isTablet) {
      if (this.config.modoApp != "menu") {
        if (this.cart.getTotalItems() > 0) {
          this.toastAdd.displayToastAdd("Existem pedidos não enviados. Enviar agora?");
        }
      }
    }
  }

  authenticationHandler() {
    const userGestor = this.userGestorController.getUser();

    if (userGestor === null) {
      this.router.navigate(["/v2/conta/login"]);
    } else {
      this.router.navigate(["/v2/conta/detalhes"]);
    }
  }

  authenticationState(): string {
    let LABEL = "";
    const userGestor = this.userGestorController.getUser();

    if (userGestor === null) {
      LABEL = "Login";
    } else {
      LABEL = userGestor.nome;
    }
    return LABEL;
  }

  goToOrders() {
    this.router.navigate(["/v2/historico"]);
  }

  takePicture() {
    this.cameraService.takePicture().subscribe({
      next: (img64) => {
        if (img64) this.gallery.include(img64);
      },
      error: (err) =>
        this.alertCtrl
          .create({
            message: err,
          })
          .then((alert) => alert.present()),
    });
  }

  confirmTable() {
    const restPwdInput: IonInput = document.getElementById("rest-pwd-table-change-input") as any;
    const tableNumberInput: IonInput = document.getElementById("table-number-table-change-input") as any;

    const rest$ = this.restService.getRest();
    rest$.subscribe({
      next: (rest) => {
        if (rest.senhaMaster === restPwdInput.value) {
          this.auth.changeTable(tableNumberInput.value as number);
          this.router.navigate([environment.routePaths.v2.version, environment.routePaths.v2.home]);
        } else
          this.utils.showAlert({
            message: "Senha incorreta",
          });
      },
    });
  }

  totalItensFromCart() {
    return this.cart.getTotalItems();
  }
}
export interface V2TopBarOptions {
  backButtonUrl?: string;
  showOrderButton?: boolean;
  showChangeTableButton?: boolean;
  imageUrl?: string;
  color?: string;
}
