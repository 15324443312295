import { Component, Input, OnInit } from '@angular/core';

import { AnimationBuilder } from '@ionic/core';

@Component({
  selector: 'app-input-datetime',
  templateUrl: './input-datetime.component.html',
  styleUrls: ['./input-datetime.component.scss'],
})
export class InputDatetimeComponent implements OnInit {

  // TODO Tem um problema relacionado ao uso em formulários com esse componente
  // Por esse motivo não está em uso. O componente não passa o valor atual para o form, se corrigir isso, tu tem um  calendário traduzido
  dateNow: Date = new Date()
  @Input('cancelText') cancelText: string = 'Fechar'
  @Input('dayNames') dayNames: string[] = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
  @Input('dayShortNames') dayShortNames: string [] = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
  @Input('disabled') disabled: boolean = false
  @Input('displayFormat') displayFormat: string = 'MMM D, YYYY'
  @Input('displayTimezone') displayTimezone: string
  @Input('doneText') doneText: string = 'Feito'
  @Input('min') min: string = (this.dateNow.getFullYear() - 100) + ''
  @Input('max') max: string = this.dateNow.getFullYear() + ''
  @Input('platform') mode: 'ios'|'md'
  @Input('monthNames') monthNames: string [] = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ]
  @Input('monthShortNames') monthShortNames: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Out', 'Nov', 'Dez']
  @Input('pickerFormat') pickerFormat: string
  @Input('pickerOptions') pickerOptions: {
    columns?: PickerColumn[] | undefined;
    buttons?: PickerButton[] | undefined;
    cssClass?: string | string[] | undefined;
    showBackdrop?: boolean | undefined;
    backdropDismiss?: boolean | undefined;
    animated?: boolean | undefined;
    mode?: "ios" | "md" | undefined;
    keyboardClose?: boolean | undefined;
    id?: string | undefined;
    enterAnimation?: AnimationBuilder | undefined;
    leaveAnimation?: AnimationBuilder | undefined;
  }
  @Input('placeholder') placeholder: string
  @Input('readonly') readonly: boolean = false
  @Input('value') value: string

  //
  @Input('class') class: string
  @Input('datetimeData') datetimeData: string
  @Input('formControlName') formControlName: string

  constructor() { }

  ngOnInit() {}

}

interface PickerColumn {
  name: string,
  prefix: string,
  suffix: string,
  options: [
    {
      text: string,
      value: any
    },
    {
      text: string,
      value: any
    }
  ]
}

interface PickerButton {
  text: string,
  value: any
}

export const dayNamesBr: string[] = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']
export const dayShortNamesBr: string[] = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']
export const monthNamesBr: string [] = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
export const monthShortNamesBr: string[] = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Out', 'Nov', 'Dez']
