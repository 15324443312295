import { take } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { AlertController, ModalController } from "@ionic/angular";
import { Localizacao } from "../../../models/address/localizacao.model";
import { AddAddressPage } from "../../../shared/components/v2/add-address/add-address.page";
import { environment } from "../../../../environments/environment";
import { MaskService } from "../../../services/providers/mask.service";
import { AuthService } from "../../../shared/auth.service";
import { CartService } from "../../../services/cart.service";
import { RestaurantService } from "../../../services/restaurant.service";
import { Restaurant } from "../../../models/restaurant.model";
import { ConfigurationService } from "src/app/services/configuration.service";
import { ToastaddService } from "src/app/services/toastadd.service";
import { Cart } from "src/app/models/cart/cart.model";
import { ItemCart } from "src/app/interfaces/itemCart.interface";
import { V2ItemPage } from "src/app/shared/components/v2/v2-item/v2-item.page";
import { UsergestorService } from "src/app/services/usergestor.service";

@Component({
  selector: "app-user-info-request-gestor",
  templateUrl: "./user-info-request-gestor.page.html",
  styleUrls: ["./user-info-request-gestor.page.scss"],
})
export class UserInfoRequestGestorPage implements OnInit {
  form: FormGroup;
  tableNumber: string;
  rest$: Observable<Restaurant>;
  isTablet: boolean;
  restImagem: any;
  logoZimmer: any;
  checked: boolean;
  isDisabled: boolean = true;
  phoneNumberData: any;
  confirmado: boolean = false;
  cartProds: Cart;
  totalFinal: number = 0;
  totalFrete: number = 0;
  final: number = 0;
  bFrete: number = 0;
  btn_mensagem: string = "PREENCHA OS CAMPOS";
  fechado: boolean = false;
  retirada: boolean = false;
  tempoEntrega: string = "0 - 0 min";
  tempoRetira: string = "0 - 0 min";

  constructor(private auth: AuthService, private alertCtrl: AlertController, private alerta: ToastaddService, private cart: CartService, private formBuilder: FormBuilder, public mask: MaskService, private modalCtrl: ModalController, private restService: RestaurantService, public config: ConfigurationService, private cartService: CartService, private userGestorController: UsergestorService) {
    this.isTablet = this.config.isTablet;
    this.logoZimmer = "assets/v2/logo_zimmer.png";
    this.restImagem = this.config.imagemRest.imagem != null ? this.config.imagemRest.imagem : this.logoZimmer;
  }

  ngOnInit() {
    this.cartProds = this.cartService.getCart();
    this.rest$ = this.restService.getRest();
    this.retirada = this.restService.getRestaurant()["aceitaRetira"];
    const sub = this.auth.getTableV2().subscribe({
      next: (tableNumber) => {
        this.tableNumber = tableNumber;
        this.buildForm();
        // sub.unsubscribe()
      },
    });

    /*this.cartProds.items.forEach((item) => {
      if (item.meiomeios.length === 0) {
        this.totalFinal +=
          (item.produto.valorPromocao ||
            item.produto.valorRegular ||
            item.produto.valorVenda) * item.number;

        item.cartItemOpcoes.forEach((complementos) => {
          this.totalFinal +=
            complementos.valor * complementos.quantidade * item.number;
        });
      } else if (item.meiomeios.length >= 1) {
        const maxHalf = item.meiomeios.reduce(function (prev, current) {
          return prev.valorVenda > current.valorVenda ? prev : current;
        });

        this.totalFinal += maxHalf.valorVendaComplemento * item.number;
      }
    });*/

    this.rest$.subscribe((restaurante: any) => {
      this.tempoEntrega = restaurante.tempoEntrega;
      this.tempoRetira = restaurante.tempoRetira;
    })

    this.cartProds.items.forEach((option) => (this.totalFinal += this.cartService.calcTotalItemValue(option)));

    this.form.controls["valorFinal"].setValue(
      "Total: " +
        this.totalFinal.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })
    );
    this.final = this.totalFinal;
  }

  /**
   * Monta o form
   */
  private buildForm() {
    this.form = this.formBuilder.group({
      userName: [this.userGestorController.getUser()?.nome, [Validators.required]],
      userPhone: [this.userGestorController.getUser()?.fone, [Validators.required]],
      aquisition: [this.retirada || this.userGestorController.getUser()?.cep ? 1 : 2, [Validators.required]],
      address: this.formBuilder.group({
        cep: [this.userGestorController.getUser()?.cep],
        bairro: [this.userGestorController.getUser()?.bairro],
        localidade: [this.userGestorController.getUser()?.localidade],
        logradouro: [this.userGestorController.getUser()?.logradouro],
        numero: [this.userGestorController.getUser()?.numero],
        uf: [this.userGestorController.getUser()?.uf],
        complemento: [this.userGestorController.getUser()?.complemento],
      }),
      payment: [2, [Validators.required]],
      change: [null],
      observation: [null],
      checked: [false],
      frete: [
        "Entrega: " +
          this.totalFrete.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      ],
      valorFinal: [
        "Total: " +
          this.totalFinal.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          }),
      ],
    });
  }

  /**
   * Altera os validadoes do campo de logradouro no form baseado no método de aquisição (ENTREGA ou RETIRADA)
   * @param aquisitionMethod
   */
  changeRequiredOfLogradouro(aquisitionMethod: number) {
    const field = this.form.get("address.logradouro");
    if (aquisitionMethod == 1) field.clearValidators();
    else field.setValidators([Validators.required]);
    field.updateValueAndValidity();
  }

  /**
   * Chama o componente de busca de endereço
   */
  callAddAddressPage() {
    this.modalCtrl
      .create({
        component: AddAddressPage,
        id: environment.addAddressModalId,
        componentProps: {
          address: this.form.value.address.logradouro ? this.form.value.address : undefined,
        },
        cssClass: "modal-fullscreen",
      })
      .then((modal) => {
        modal.onDidDismiss().then((dismiss) => {
          // Se retornar success == true na data do dismiss do modal
          if (dismiss.data.success) {
            // Seta a localização no formulário
            const address: Localizacao = dismiss.data.address;
            this.form.patchValue({
              address,
            });
          }
        });
        modal.present();
      });
  }

  /**
   * Fecha o modal com a role 'cancel' sem enviar nenhum dado
   */
  cancelAction() {
    this.modalCtrl.dismiss(undefined, "cancel");
  }

  updateCheck() {
    //this.isDisabled = this.form.value.checked ? false : true
    this.form.value.change = 0;
    console.log(this.form, "form");
  }

  update() {
    this.form.value.change = 0;
    console.log(this.form, "form");
  }

  /**
   * Envia o pedido para o gestor
   * @param rest
   */
  confirmInfo(rest: Restaurant) {
    console.log(rest, "rest");
    console.log(this.form.value, "FORM");

    const success = this.cart.sendOrderGestor(
      {
        aquisition: this.form.value.aquisition,
        endereco: this.form.value.address,
        number: rest.foneCelular,
        observation: this.form.value.observation,
        pagamento: this.form.value.payment,
        troco: this.form.value.change ? this.form.value.change : 0,
        userFone: this.form.value.userPhone,
        userName: this.form.value.userName,
        tableNumber: this.tableNumber,
        frete: this.totalFrete,
      },
      this.form.value.aquisition == 2 ? true : false,
      { phone: this.form.value.userPhone }
    );

    success.subscribe(
      (res: any) => {
        if (res === false) {
          this.confirmado = false;
          return this.alerta.alertaMsg("Ocorreu um erro ao realizar o pedido, tente novamente.", "ATENÇÃO");
        }
        if (res !== false) {
          return this.modalCtrl.dismiss(this.form.value, "confirm");
        }
      },
      (err: any) => {
        this.confirmado = false;
        return this.alerta.alertaMsg("Ocorreu um erro ao realizar o pedido, tente novamente.", "ATENÇÃO");
      }
    );
  }

  /**
   * Pergunta se o usuário verificou os dados digitados
   */
  next(rest: Restaurant) {
    const restaurant: any = rest;
    this.confirmado = true;

    console.log(restaurant.pedidoMinimo, this.totalFinal, this.totalFrete, "IWJDUIWuadi");

    if (restaurant.pedidoMinimo > this.totalFinal - this.totalFrete) {
      this.confirmado = false;
      return this.alerta.alertaMsg("O seu pedido não atende ao valor minimo do restaurante.", "ATENÇÃO");
    }

    this.alertCtrl
      .create({
        message: "Clique em OK se estiver tudo certo, ou clique em cancelar e revise os dados.",
        animated: true,
        header: "Atenção!",
        mode: "ios",
        buttons: [
          {
            text: "OK",
            handler: () => this.validation(rest),
            //this.confirmInfo(rest)
          },
          {
            text: "Cancelar",
            cssClass: "alert_font_cancel",
          },
        ],
      })
      .then((alert) => alert.present());
  }

  validation(rest: Restaurant) {
    console.log(this.form, "form");

    if (this.form.value.payment == 1 && this.form.value.checked) {
      if (this.form.value.change == 0) {
        this.alerta.alertaMsg("Preencha o valor do troco.", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
    }

    if (this.form.value.aquisition == 0) {
      this.alerta.alertaMsg("Escolha uma forma de entrega!", "ATENÇÃO");
      this.confirmado = false;
      return;
    }

    if (this.form.value.aquisition == 2) {
      if (this.form.value.address.logradouro == null || this.form.value.address.logradouro == "") {
        this.alerta.alertaMsg("Preencha o nome da rua!", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
      if (this.form.value.address.numero == null || this.form.value.address.numero == "") {
        this.alerta.alertaMsg("Preencha o numero!", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
      if (this.form.value.address.bairro == null || this.form.value.address.bairro == "") {
        this.alerta.alertaMsg("Preencha o bairro!", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
      if (this.form.value.address.localidade == null || this.form.value.address.localidade == "") {
        this.alerta.alertaMsg("Preencha a cidade!", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
      if (this.form.value.address.cep == null || this.form.value.address.cep == "") {
        this.alerta.alertaMsg("Preencha o CEP!", "ATENÇÃO");
        this.confirmado = false;
        return;
      }
    }
    this.confirmInfo(rest);
  }

  freteCalcv(cep: string | number | any = this.form.value.address.cep && this.form.value.aquisition === 2 ? this.form.value.address.cep : this.restService.getRestaurant().cep) {
    let x = [cep.slice(0, 5), "-", cep.slice(5)].join("");

    cep = x;

    if (cep && cep.length == 9 ? cep : this.form.value.address.cep && this.form.value.address.cep.length == 9 ? this.form.value.address.cep : "00000-000") {
      this.rest$.subscribe((restaurante) => {
        this.restService.getByToken(cep && cep.length == 9 ? cep : this.form.value.address.cep && this.form.value.address.cep.length == 9 ? this.form.value.address.cep : "00000-000", restaurante.token).subscribe((result: any) => {
          if (result.results[0].valorEntrega && !isNaN(result.results[0].valorEntrega) && result.results[0].valorEntrega !== 0) {
            this.totalFrete = result.results[0].valorEntrega;

            this.form.controls["frete"].setValue(
              "Entrega: " +
                this.totalFrete.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
            );

            this.totalFinal = this.final + result.results[0].valorEntrega;

            this.form.controls["valorFinal"].setValue(
              "Total: " +
                this.totalFinal.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
            );

            this.bFrete = result.results[0].valorEntrega;

            setTimeout(() => {
              if (this.form.value.aquisition === 1) {
                this.totalFinal = this.totalFinal - this.totalFrete;
                this.totalFrete = 0;

                this.form.controls["frete"].setValue(
                  "Entrega: " +
                    this.totalFrete.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                );

                this.form.controls["valorFinal"].setValue(
                  "Total: " +
                    this.totalFinal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                );
              }
            });
          }

          setTimeout(() => {
            this.fechado = false;
            if (!this.form.value.userName) {
              return (this.btn_mensagem = "PREENCHA SEU NOME");
            } else if (!this.form.value.userPhone || (this.form.value.userPhone.length !== 14 && this.form.value.userPhone.length !== 13)) {
              return (this.btn_mensagem = "PREENCHA SEU TELEFONE");
            } else if (result.results[0].status === "AREA SEM COBERTURA") {
              this.btn_mensagem = "ÁREA SEM COBERTURA";
            } else if (result.results[0].status === "FECHADO") {
              this.btn_mensagem = "RESTAURANTE FECHADO";
            } else if (result.results[0].status === "SEJA BEM VINDO.") {
              this.btn_mensagem = "FINALIZAR PEDIDO";
            } else if (this.form.value.aquisition === 2 && (!this.form.value.address.cep || !this.form.value.address.bairro || !this.form.value.address.localidade || !this.form.value.address.logradouro || !this.form.value.address.numero || !this.form.value.address.uf)) {
              return (this.btn_mensagem = "PREENCHA A ENTREGA");
            } else if (result.results[0].status.includes("FECHADO") && result.results[0].status.includes("abre")) {
              this.fechado = true;
              this.btn_mensagem = "RESTAURANTE FECHADO (ABRE AS " + new Date(result.results[0].abreAs).toLocaleTimeString().slice(0, 5) + ")";
            }
          });
        });
      });
    }
  }

  viacep() {
    setTimeout(() => {
      let cep = this.form.value.address.cep;

      console.log(cep);

      if (cep && cep.length === 9) {
        this.cartService
          .getAddressDataUsingCEP(cep)
          .pipe(take(1))
          .subscribe((res: any) => {
            this.form.controls["address"].setValue({
              cep: res.cep,
              bairro: res.bairro,
              localidade: res.localidade,
              logradouro: res.logradouro,
              numero: null,
              uf: res.uf,
              complemento: res.complemento,
            });
          });
      }
    });
  }

  persistOnlyNumbers(event: any) {
    let characterCode = event.which ? event.which : event.keyCode;

    if (characterCode > 31 && (characterCode < 48 || characterCode > 57)) return false;
    return true;
  }

  getPhoneData(phone: string) {
    this.cart.getPhoneDataGestor(phone).subscribe(
      (res: any) => {
        if (res && res.logradouro && res.numero && res.bairro && res.cep && res.localidade && res.uf) {
          this.form.controls["aquisition"].setValue(2);
          this.form.controls["address"].setValue({
            cep: res.cep,
            bairro: res.bairro,
            localidade: res.localidade,
            logradouro: res.logradouro,
            numero: res.numero,
            uf: res.uf,
            complemento: res.complemento,
          });
          this.freteCalcv(res.cep);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  clearFormAddress() {
    this.form.controls["address"].setValue({
      cep: null,
      bairro: null,
      localidade: null,
      logradouro: null,
      numero: null,
      uf: null,
      complemento: null,
    });
  }

  showDetails(item: ItemCart) {
    this.modalCtrl
      .create({
        component: V2ItemPage,
        componentProps: {
          itemCart: item,
        },
        cssClass: "modal-fullscreen",
      })
      .then((modal) => modal.present());
    console.log("Carrinho:", this.cart);
  }

  verifyPhone() {
    if ((this.form.value.userPhone?.length === 13 || this.form.value.userPhone?.length === 14) && !this.form.value.address.cep) {
      this.getPhoneData(this.form.value.userPhone);
    }
  }
}
